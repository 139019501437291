export const ProgressTableModule = {
    namespaced: true,

    state: {
        hoverCellObjective: null,
        hoverCellUnit: null,
        hoverCellTopic: null,
        hoverCellStudentId: null,
        selectCellStartTopic: null,
        selectCellStartUnit: null,
        selectCellStartObjective: null,
        selectCellStartStudentId: null,
        selectCellStartStudentIndex: null,
        selectCellEndTopic: null,
        selectCellEndUnit: null,
        selectCellEndObjective: null,
        selectCellEndStudentId: null,
        selectCellEndStudentIndex: null,
        selecting: false,
        target_type: null,
        dragOpen: false,
        toBePersisted: []

    },
    getters: {
        selectObjectiveIndex1: state => {
            return Math.min(state.selectCellStartObjective, state.selectCellEndObjective)
        }, selectObjectiveIndex2: state => {
            return Math.max(state.selectCellStartObjective, state.selectCellEndObjective)
        }, selectStudentIndex1: state => {
            return Math.min(state.selectCellStartStudentIndex, state.selectCellEndStudentIndex)
        }, selectStudentIndex2: state => {
            return Math.max(state.selectCellStartStudentIndex, state.selectCellEndStudentIndex)
        },
    },
    actions: {

        resetHover({
                       state
                   }) {
            state.hoverCellObjective = null;
            state.hoverCellTopic = null;
            state.hoverCellUnit = null;
            state.hoverCellStudentId = null;
        },
        resetSelect({
                        state
                    }) {
            state.selectCellStartObjective = null;
            state.selectCellStartTopic = null;
            state.selectCellStartUnit = null;
            state.selectCellStartStudentId = null;
            state.selectCellEndObjective = null;
            state.selectCellEndTopic = null;
            state.selectCellEndUnit = null;
            state.selectCellEndStudentId = null;
            state.selecting = false;
            state.dragOpen = false
        }
    },
};
