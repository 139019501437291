import {TransformModel} from "@/models/TransformModel";
import Permission from "@/models/Permission";
import Document from "@/models/Document";

/**
 * A folder
 * @property {number} id
 * @property {string} name - The name of the folder
 * @property {number} parent_id - The id of folder this folder belongs to if any
 * @property {number} ordinality - The order of this folder with its siblings
 * @property {Object} parent - The parent folder
 * @property {Array<Object>} children - The child folders
 * @property {Array} permissions - The permissions attached to this folder
 */
export default class Folder extends TransformModel {
    static entity = 'folders'

    static fields() {
        return {
            type: this.attr('folders'),
            id: this.attr(null),
            name: this.attr(''),
            parent_id: this.attr(null),
            parent: this.belongsTo(Folder, 'parent_id'),
            children: this.hasMany(Folder, 'parent_id'),
            permissions_ids: this.attr(() => []),
            permissions: this.hasManyBy(Permission, 'permissions_ids', 'id'),
            documents_ids: this.attr(() => []),
            documents: this.hasManyBy(Document, 'documents_ids'),
            ordinality: this.attr(0),
            component: this.attr('FolderMenuItem'),

        }
    }
    /**
     * Returns all folders
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.parent_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {string} mode - how to persist the response
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], mode = 'insertOrUpdate') {
        return this.api().get(`/folders`, {
                persistBy: mode,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(folder => {
                        if (relationships.includes('documents')) {
                            if (Object.prototype.hasOwnProperty.call(folder.attributes, 'documents')) {
                                folder.attributes.documents.map(item => {
                                    Object.assign(item, item.attributes)
                                })
                            }

                        }
                        if (relationships.includes('children')) {
                            if (Object.prototype.hasOwnProperty.call(folder.attributes, 'children')) {
                                folder.attributes.children.map(item => {
                                    Object.assign(item, item.attributes)
                                })
                            }

                        }
                        if (relationships.includes('permissions')) {
                            if (Object.prototype.hasOwnProperty.call(folder.attributes, 'permissions')) {
                                folder.attributes.permissions.map(item => {
                                    Object.assign(item, item.attributes)
                                })
                            }

                        }
                        return {...folder, ...folder.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a folder by its id
     * @function
     * @param {number} id The id of the folder
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/folders/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new folder
     * @function
     * @param {Object} folder - The folder object
     * @param {string} folder.name - The name of the folder
     * @param {number} [folder.parent_id] - The id of the folders parent if any
     * @param {Array<number>} folder.permissions - The id of the permissions associated with the folder
     * @returns {Promise<Response>} - The newly created folder
     */

    static Store(folder) {
        return this.api().post(`/folders`, folder, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing folder
     * @function
     * @param {Object} folder - The folder object
     * @param {number} folder.id - The id of the folder
     * @param {string} folder.name - The name of the folder
     * @param {number} [folder.parent_id] - The id of the folders parent if any
     * @param {Array<number>} folder.permissions - The id of the permissions associated with the folder
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly updated folder
     */

    static Update(folder, saved) {
        return this.api().patch(`/folders/${folder.id}`, folder, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing folder
     * @function
     * @param {number} folder_id - The id of the folder
     */

    static Delete(folder_id) {
        return this.api().delete(`/folders/${folder_id}`, {
                delete: folder_id
            }
        )
    }


}
