import {TransformModel} from "@/models/TransformModel";
import Campus from "@/models/Campus";


/**
 * A term
 * @property {number} id
 * @property {string} name - The name of the term
 * @property {number} campus_id - The id of the campus
 * @property {number} parent_id - The id of the parent term if there is one
 * @property {string} start - The start date of the term
 * @property {string} end - The end date of the term
 * @property {array<Object>} terms - the children terms


 */
export default class Term extends TransformModel {
    static entity = 'terms'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            campus_id: this.attr(null),
            campus: this.belongsTo(Campus, 'campus_id'),
            parent_id: this.attr(null),
            parent: this.belongsTo(Term, 'parent_id'),
            start: this.attr(null),
            end: this.attr(null),
            children: this.hasMany(Term, 'parent_id')

        }
    }

    /**
     * Returns all Terms
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.campus_id] - search by campus_id
     * @param {number} [query.parent_id] - search by parent_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the CourseScopeTopics
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/terms`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(term => {
                        if (relationships.includes('children')) {
                            term.attributes.children.map(child => {
                                Object.assign(child, child.attributes)
                            })
                        }
                        return {...term, ...term.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a Term
     * @function
     * @param {number} term_id - The id of the Term
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(term_id, relationships = []) {
        return this.api().get(`/terms/${term_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('children')) {
                        data.attributes.children.map(child => {
                            Object.assign(child, child.attributes)
                        })
                    }

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new term
     * @function
     * @param {Object} term - The term object
     * @returns {Promise<Response>} - The newly created assessment
     */

    static Store(term) {
        return this.api().post(`/terms`, term, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a term
     * @function
     * @param {Object} term - The term object
     * @returns {Promise<Response>} - The updated term
     */

    static Update(term) {
        return this.api().patch(`/terms/${term.id}`, term, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a term
     * @function
     * @param {number} id - The id of the term
     */

    static Delete(id) {
        return this.api().delete(`/terms/${id}`, {
                delete: id
            }
        )
    }


}
