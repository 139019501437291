import {format, isValid} from 'date-fns'

export const DateSelectModule = {
    namespaced: true,

    state: {
        dateMin: null,
        dateMax: null,
        semester_id: null,
        year: new Date().getFullYear(),
    },
    getters: {
        years: () => {
            let years = []

            for (let i = 0; i < 6; i++) {
                years.push(new Date().getFullYear() - 1 + i)
            }
            return years
        }, application_years: () => {
            let years = []

            for (let i = 0; i < 5; i++) {
                years.push(new Date().getFullYear() + i)
            }
            return years
        },
        formattedDateMin: state => {
            if (isValid(new Date(state.dateMin))) {
                return format(state.dateMin, 'yyyy-MM-dd')

            }
            return null
        },
        formattedDateMax: state => {
            if (isValid(new Date(state.dateMax))) {
                return format(state.dateMax, 'yyyy-MM-dd')

            }
            return null
        },
    }
};
