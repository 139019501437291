import {
    ApiClientFactory
} from '@/clients/ApiClientFactory';

let factory = new ApiClientFactory();

/**
 *
 * @namespace ApplyClient
 */
export const ApplyClient = {


    /**
     * Checks if the email address exists
     * @param {string} email
     * @returns {Promise<AxiosResponse<Object>>} The user object
     */
    checkIfExists(email) {
        /**
         * @property {Object} response.data The user object
         */
        return factory.post('api/1/public/check-user-exists', {email: email});
    },
    /**
     * Registers a new Guardian
     * @param {Object} user
     * @param {string} user.first_name
     * @param {string} user.last_name
     * @param {string} user.email
     * @param {string} user.password
     * @returns {Promise<AxiosResponse<Object>>} The user object
     */
    register(user) {
        /**
         * @property {Object} response.data The user object
         */
        return factory.post('api/1/public/register', user);
    }, /**
     * Public campuses
     * @returns {Promise<AxiosResponse<Array>>} The campuses
     */
    viewCampuses() {

        return factory.get('api/1/public/campuses');
    },


};
