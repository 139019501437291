export const StaffProfileModule = {
    namespaced: true,

    state: {
        employment_types: [
            {
                id: 1,
                name: 'Full-Time'
            },
            {
                id: 2,
                name: 'Part-Time'
            },
            {
                id: 3,
                name: 'Fixed-Term'
            },

        ],

    },

};
