import {format, addDays, subDays, isSameDay} from 'date-fns'

export const AttendanceModule = {
    namespaced: true,

    state: {
        attendance_date_truth: new Date(),
    },
    getters: {
        attendance_date_filter: state => {
            return format(state.attendance_date_truth, 'yyyy-MM-dd')
        },
        is_today: state => {
            return isSameDay(new Date(), state.attendance_date_truth)
        }
    }, actions: {
        back_days({commit, state}, num = 1) {
            commit('SET_DATE', subDays(state.attendance_date_truth, num))
        }, forward_days({commit, state}, num = 1) {
            commit('SET_DATE', addDays(state.attendance_date_truth, num))
        },
        set_attendance_date({commit}, date) {
            commit('SET_DATE', date)
        },

    },

    mutations: {
        SET_DATE(state, date) {
            state.attendance_date_truth = date
        },

    }
};
