import {TransformModel} from "@/models/TransformModel";
import Subject from "@/models/Subject";
import Extramural from "@/models/Extramural";

/**
 * A stage
 * @property {number} id
 * @property {string} name - The name of the stage
 * @property {string} long_name - The name of the stage for display on applications
 * @property {Boolean} is_open_for_enrolment - if the stage can be enrolled
 * @property {number} campus_id - The id of the campus this stage belongs to
 * @property {number} stage_id - The id of the stage this stage belongs to
 * @property {number} ordinality - The order to display this stage
 */
export default class Stage extends TransformModel {
    static entity = 'stages'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            campus_id: this.attr(null),
            phase_id: this.attr(null),
            long_name: this.attr(null),
            year:this.attr(null),
            is_open_for_enrolment: this.attr(null),
            subjects: this.hasMany(Subject, 'stage_id'),
            extramurals: this.hasMany(Extramural, 'stage_id'),
            ordinality: this.attr(0)
        }
    }

    /**
     * Returns all stages
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.phase_id]
     * @param {string} [query.search]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/stages`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: (response) => {
                    return this.transformArray(response)
                },
            }
        )
    }

    /**
     * Returns a stage by its id
     * @function
     * @param {number} id The id of the stage
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/stages/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {


                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new stage
     * @function
     * @param {Object} stage - The stage object
     * @param {string} stage.name - The name of the stage
     * @param {string} stage.long_name - The long name of the stage
     * @param {Boolean} stage.is_open_for_enrolment - If the stage is open for enrolment
     * @param {number} stage.campus_id - The id of the campus the stage is associated with
     * @param {number} stage.phase_id - The id of the phase the stage is associated with
     * @returns {Promise<Response>} - The newly created stage
     */

    static Store(stage) {
        return this.api().post(`/stages`, stage, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Update an existing stage
     * @function
     * @param {Object} stage - The stage object
     * @param {string} stage.name - The name of the stage
     * @param {string} stage.long_name - The long name of the stage
     * @param {Boolean} stage.is_open_for_enrolment - If the stage is open for enrolment
     * @param {number} stage.campus_id - The id of the campus the stage is associated with
     * @param {number} stage.phase_id - The id of the phase the stage is associated with
     * @param {string} stage.id - The id of the stage
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created stage
     */

    static Update(stage, saved) {
        return this.api().patch(`/stages/${stage.id}`, stage, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing stage
     * @function
     * @param {number} stage_id - The id of the stage
     * @returns {Promise<Response>} - The newly created stage
     */

    static Delete(stage_id) {
        return this.api().delete(`/stages/${stage_id}`, {
                delete: stage_id
            }
        )
    }


}
