import {TransformModel} from "@/models/TransformModel";


/**
 * A enrolment
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class EnrolmentStatus extends TransformModel {
    static entity = 'enrolment-statuses'

    static fields() {
        return {
            type: this.attr(null),
            id: this.attr(null),
            name: this.attr(null)

        }
    }

    /**
     * Returns all enrolment statuses
     * @function
     * @returns {Promise<Response>}
     */

    static FetchAll() {
        return this.api().get(`/enrolment-statuses`, {
                dataTransformer: ({data: {data}}) => {
                    return data.map(status => {
                        return {...status, ...status.attributes}
                    })
                },
            }
        )
    }


}
