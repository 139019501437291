<template>
  <aside
      v-show="isAsideVisible"
      :class="{'is-expanded':isAsideExpanded}"
      class="aside is-placed-left"
      @mouseleave="$store.dispatch('popout/setDisplay',false)"
  >
    <div ref="container" class="aside-container is-flex is-flex-direction-column" @mouseenter="psUpdate">
      <router-link :class="{'menu-image-collapsed':collapsedMenuImage}" :to="{name:'home'}" class="menu-image">
        <div
            class="image">
          <img
              :src="collapsedMenuImage ? 'https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-04.svg' : 'https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-03.svg' "
              alt="GENEX: Generational Education Exchange"/>
        </div>
      </router-link>
      <div
          :class="{'has-power-container':showMenuExpand}"
          class="menu is-menu-main fast"
          @animationend="hasFadeIn = false;hasFadeOut=false"
      >
        <aside-menu-list :has-fade-in="hasFadeIn"
                         :has-fade-out="hasFadeOut" :menu="menu" @menu-click="menuClick"/>
      </div>
      <div v-if="showMenuExpand" class="level is-mobile  power-container">
        <div class="level-left is-clickable" @click.prevent="menuToggle">
          <div :class="{'is-expanded':isAsideExpanded}" class="level-item has-text-grey pl-5 power-icon-container">
            <b-icon v-if="!computeExpanded" :icon="$tc('icons.menu-expand')"/>
            <b-icon v-if="computeExpanded" :icon="$tc('icons.menu-minimize')"/>
          </div>
          <div
              :class="{'is-hidden-desktop':!isAsideExpanded,'is-hidden-touch':!isAsideMobileExpanded,'fadeOut animated':hasFadeOut,'fadeIn animated':hasFadeOut}"
              class="level-item ">
            <p style="font-size: 0.7rem">Powered by <span class="has-text-dark">GENEX</span></p>
          </div>
        </div>
      </div>
    </div>
    <!--    <aside-menu-popout v-if="$store.state.popout.display"/>-->

  </aside>
</template>

<script>
import {mapState} from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import AsideMenuList from "@/components/layout/AsideMenuList";
// import AsideMenuPopout from "@/components/layout/AsideMenuPopout";

export default {
  name: "AsideMenu",
  components: {
    AsideMenuList,
    // AsideMenuPopout
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }, showMenuExpand: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      hasFadeOut: false,
      hasFadeIn: false
    };
  },
  computed: {
    collapsedMenuImage() {
      if (this.$store.getters["size/viewType"] === 'mobile') {
        return false
      }
      if (this.$store.getters["size/viewType"] === 'tablet') {
        return !this.isAsideMobileExpanded

      }
      return !this.isAsideExpanded


    },

    computeExpanded() {
      if (this.$store.getters["size/viewType"] === 'mobile' || this.$store.getters["size/viewType"] === 'tablet') {
        return this.isAsideMobileExpanded
      } else {
        return this.isAsideExpanded

      }
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? this.$tc("icons.backburger") : this.$tc("icons.forwardburger");
    },
    hasAnimated() {
      return this.hasFadeOut || this.hasFadeIn;
    },
    ...mapState('menu', ["isAsideVisible", "isAsideExpanded", "isAsideMobileExpanded"])
  },
  created() {
    if (this.isAsideMobileExpanded) {
      window.addEventListener("click", this.forceClose);
    }
  },
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.container);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.forceClose);
  },
  methods: {
    forceClose(e) {
      if (this.computeExpanded && (this.$store.getters["size/viewType"] === 'mobile' || this.$store.getters["size/viewType"] === 'tablet')) {
        if (!this.$el.contains(e.target)) {
          this.menuToggle();
        }
      }

    },
    mobileToggle() {
      this.$store.dispatch("menu/asideMobileStateToggle").then(() => {
        this.psUpdate()
      });
    },
    menuToggle() {
      if (this.$store.getters["size/viewType"] === 'mobile' || this.$store.getters["size/viewType"] === 'tablet') {
        this.mobileToggle()
      } else {
        this.$store.dispatch("menu/asideStateToggle").then(() => {
          this.psUpdate()
        });
      }


    },
    menuClick(item) {
      if (this.ps && item && item.menu) {
        this.ps.update();
      }
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    }
  },
  watch: {
    isAsideExpanded(newVal) {
      if (newVal) {
        this.hasFadeIn = true;

      } else {
        this.hasFadeOut = true;

      }
    },
    isAsideMobileExpanded(newVal) {
      if (newVal === true) {
        setTimeout(() => {
          window.addEventListener("click", this.forceClose);
        }, 50)
      } else {
        window.removeEventListener("click", this.forceClose);

      }
    }
  }
};
</script>
