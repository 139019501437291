import {TransformModel} from "@/models/TransformModel";
import Unit from "@/models/Unit";


/**
 * A topic
 * @property {number} id
 * @property {string} name - The name of the phase
 * @property {number} min_percent - The minimum amount of the course this topic covers
 * @property {number} max_percent - The maximum amount of the course this topic covers
 * @property {number} min_hours - The minimum amount of time this topic covers
 * @property {number} max_hours - The maximum amount of time this topic covers
 * @property {number} order - The order the topics appear
 * @property {string} reference - The reference code of this topic
 * @property {Array.<Object>} units - The units in this topic

 */
export default class Topic extends TransformModel {
    static entity = 'topics'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            unit_ids: this.attr(() => []),
            units: this.hasManyBy(Unit, 'unit_ids'),
            min_percent: this.attr(null),
            max_percent: this.attr(null),
            min_hours: this.attr(null),
            max_hours: this.attr(null),
            order: this.attr(null),
            reference: this.attr(null),
            course_scope_id: this.attr(null)

        }
    }

    /**
     * Returns all CourseScopeTopics
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.campus_id] - search by campus_id
     * @param {number} [query.stage_id] - search by stage_id
     * @param {number} [query.phase_id] - search by phase_id
     * @param {number} [query.course_scope_id] - search by course_scope_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the CourseScopeTopics
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/course-scope-topics`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(topic => {
                        return {...topic, ...topic.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a Topic
     * @function
     * @param {number} id - The id of the Topic
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/course-scope-topics/${id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('units')) {
                        data.attributes.units.map(unit => {
                            Object.assign(unit, unit.attributes)
                        })
                    }
                    if (relationships.includes('units.objectives')) {
                        data.attributes.units.map(unit => {
                            unit.objectives.map(objective => {
                                Object.assign(objective, objective.attributes)
                            })
                        })
                    }

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new courseScopeTopic
     * @function
     * @param {Object} courseScopeTopic - The courseScopeTopic object
     * @returns {Promise<Response>} - The newly created courseScopeTopic
     */

    static Store(courseScopeTopic) {
        return this.api().post(`/course-scope-topics`, courseScopeTopic, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a courseScopeTopic
     * @function
     * @param {Object} courseScopeTopic - The courseScopeTopic object
     * @returns {Promise<Response>} - The updated courseScopeTopic
     */

    static Update(courseScopeTopic) {
        return this.api().patch(`/course-scope-topics/${courseScopeTopic.id}`, courseScopeTopic, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a courseScopeTopic
     * @function
     * @param {number} id - The id of the courseScopeTopic
     */

    static Delete(id) {
        return this.api().delete(`/course-scope-topics/${id}`, {
                delete: id
            }
        )
    }


}
