import VueCookies from 'vue-cookies'


export const CampusModule = {
    namespaced: true,

    state: {
        all_campuses: false,
        selected_stage: null,
        selected_stage_id: null,
        selected_phase: null,
        selected_user: null,
        selected_enrolment: null,
        enrolment_filter_status_id: null,
        application_filter_status_id: null,
        enrolment_filter_year: new Date().getFullYear(),
        enrolment_type: 1,
        application_filter_year: new Date().getFullYear(),
        filter_phase: null,
        filter_stage: null,
        filter_stage_term: null,
        filter_subject: null,
        filter_subject_term: null,
        filter_extramural_term: null,
        filter_enrolment_term: null,
        selected_campus_id: VueCookies.get('selected_campus_id') || null,
        selected_class_group_id: VueCookies.get('selected_class_group_id') || null,
        selected_home_class_id: VueCookies.get('selected_home_class_id') || null,
        edit_stage: null,
        subject_select_boolean: false,
        loadedClassGroups: false,
        loadedHomeClasses: false,
        loadedWards: false,
        selected_ward_id:VueCookies.get('selected_ward_id') || null
    },

    actions: {
        setAll({commit}, bool) {
            commit('SET_ALL', bool)
        },
        setEditStage({commit}, stage) {
            commit('SET_EDIT_STAGE', stage)
        },
        toggle_subject_select({state}) {
            state.subject_select_boolean = !state.subject_select_boolean
        },
        selectStage({commit}, stage) {
            commit('SET_SELECTED_STAGE', stage)
            commit('SET_SELECTED_STAGE_ID', stage.id)
        }, clearSelectStage({commit}) {
            commit('SET_SELECTED_STAGE', null)
            commit('SET_SELECTED_STAGE_ID', null)
        },
        selectEnrolmentFilterStatusId({commit}, status_id) {
            commit('SET_ENROLMENT_STATUS', status_id)
        }, selectApplicationFilterStatusId({commit}, status_id) {
            commit('SET_APPLICATION_STATUS', status_id)
        }, selectEnrolmentFilterYear({commit}, year) {
            commit('SET_ENROLMENT_YEAR', year)
        }, selectApplicationFilterYear({commit}, year) {
            commit('SET_APPLICATION_YEAR', year)
        },
        selectPhase({commit}, phase) {
            commit('SET_SELECTED_PHASE', phase)
        }, selectEnrolment({commit}, enrolment) {
            commit('SET_SELECTED_ENROLMENT', enrolment)
        }, selectUser({commit}, user) {
            commit('SET_SELECTED_USER', user)
        }, setFilterPhase({commit}, phase) {
            commit('SET_FILTER_PHASE', phase)
        }, setFilterSubject({commit}, subject) {
            commit('SET_FILTER_SUBJECT', subject)
        }, setFilterStage({commit}, stage) {
            commit('SET_FILTER_STAGE', stage)
        }, setFilterStageTerm({commit}, term) {
            commit('SET_FILTER_STAGE_TERM', term)
        }, setFilterSubjectTerm({commit}, term) {
            commit('SET_FILTER_SUBJECT_TERM', term)
        }, setFilterExtramuralTerm({commit}, term) {
            commit('SET_FILTER_EXTRAMURAL_TERM', term)
        }, setFilterEnrolmentTerm({commit}, term) {
            commit('SET_FILTER_ENROLMENT_TERM', term)
        },
        selectCampus({commit}, id) {
            VueCookies
                .set('selected_campus_id', id)
            commit('SET_SELECTED_CAMPUS', id)
            commit('SET_SELECTED_STAGE', null)
            commit('SET_SELECTED_STAGE_ID', null)
            commit('SET_SELECTED_PHASE', null)
            commit('SET_SELECTED_ENROLMENT', null)
            commit('SET_SELECTED_USER', null)
            commit('SET_ALL', false)
        }, selectClassGroup({commit}, id) {
            VueCookies
                .set('selected_class_group_id', id)
            commit('SET_SELECTED_CLASS_GROUP', id)
        }, selectHomeClass({commit}, id) {
            VueCookies
                .set('selected_home_class_id', id)
            commit('SET_SELECTED_HOME_CLASS', id)
        }  ,
        selectWard({commit}, id) {
            VueCookies.set('selected_ward_id', id)
            commit('SET_SELECTED_WARD', id)
        }
    },

    mutations: {
        SET_ENROLMENT_STATUS(state, status_id) {
            state.enrolment_filter_status_id = status_id
        }, SET_APPLICATION_STATUS(state, status_id) {
            state.application_filter_status_id = status_id
        }, SET_ENROLMENT_YEAR(state, year) {
            state.enrolment_filter_year = year
        }, SET_APPLICATION_YEAR(state, year) {
            state.application_filter_year = year
        },

        SET_ALL(state, bool) {
            state.all_campuses = bool
        }, SET_EDIT_STAGE(state, stage) {
            state.edit_stage = stage
        },
        SET_SELECTED_STAGE(state, stage) {
            state.selected_stage = stage

        }, SET_SELECTED_PHASE(state, phase) {
            state.selected_phase = phase

        }, SET_SELECTED_ENROLMENT(state, enrolment) {
            state.selected_enrolment = enrolment

        }, SET_SELECTED_USER(state, user) {
            state.selected_user = user

        }, SET_FILTER_PHASE(state, phase) {
            state.filter_phase = phase

        }, SET_FILTER_SUBJECT(state, subject) {
            state.filter_subject = subject

        }, SET_FILTER_STAGE(state, stage) {
            state.filter_stage = stage

        }, SET_FILTER_STAGE_TERM(state, term) {
            state.filter_stage_term = term

        }, SET_FILTER_SUBJECT_TERM(state, term) {
            state.filter_subject_term = term

        }, SET_FILTER_EXTRAMURAL_TERM(state, term) {
            state.filter_extramural_term = term

        }, SET_FILTER_ENROLMENT_TERM(state, term) {
            state.filter_enrolment_term = term

        }, SET_SELECTED_STAGE_ID(state, id) {
            state.selected_stage_id = id

        }, SET_SELECTED_CAMPUS(state, id) {
            state.selected_campus_id = id
        }, SET_SELECTED_CLASS_GROUP(state, id) {
            state.selected_class_group_id = id
        }, SET_SELECTED_HOME_CLASS(state, id) {
            state.selected_home_class_id = id
        }
        ,SET_SELECTED_WARD(state, id) {
            state.selected_ward_id = id
        }
    }
};
