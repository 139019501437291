import {
    ApiClientFactory
} from '@/clients/ApiClientFactory';

let factory = new ApiClientFactory();

/**
 *
 * @namespace AuthClient
 */
export const AuthClient = {

    /**
     * Logs a user in.
     * @param {Object} data
     * @param {string} data.username - The user's username, usually the email
     * @param {string} data.password - The user's password
     * @param {string} data.grant_type=password - The auth grant type
     * @param {number} data.client_id - The client id
     * @param {string} data.client_secret - The client secret
     * @returns {Promise<AxiosResponse<Object>>}  The auth request promise
     *
     */
    login(data) {
        /**
         * @property {string} response.data.access_token - the access token
         */
        return factory.post('oauth/token', data);
    },

    /**
     * Checks if the current access token is correct and returns the logged in user if it is.
     *
     * @returns {Promise<AxiosResponse<Object>>} The user object
     */
    check() {
        /**
         * @property {Object} response.data The user object
         */
        return factory.get('/api/1/user?with[]=permissions&with[]=roles');
    },
    /**
     * Checks if the current access token is correct and returns the logged in user if it is.
     *
     * @returns {Promise<AxiosResponse<Object>>} The user object
     */
    checkGuardianHomeAccess() {
        /**
         * @property {Boolean} response.data Whether the user can access the guardian home page
         */
        return factory.get('/api/1/user/has-guardian-home-access');
    },
    /**
     * Triggers a password reset
     * @function
     * @param {string} email
     * @returns {Promise<AxiosResponse<Object>>} The user object
     */
    reset(email) {
        return factory.post('/api/1/password/create', email)
    },

    /**
     *
     *
     * @param {Object} params
     * @param {string} params.password
     * @param {string} params.password_confirmation
     * @param {string} params.token
     * @returns {Promise<AxiosResponse<*>>}
     */
    setNew(params) {
        return factory.post('api/1/password/reset', params)
    }

};
