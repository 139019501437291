import Router from '@/router/index';
import {RouterError} from "@/router/errors";
import Store from '@/store/index';

let ACCESS_TOKEN = Store.state.auth.accessToken

let AUTH_ROUTES = [
    'login', 'forgot-password', 'new-password', 'register', 'apply', 'learner-reflection'
]
import {
    AuthClient
} from "@/clients/Auth";
import UserPermission from '../../models/UserPermission';


export default Router.beforeEach((to, from, next) => {
    if (Store.state.auth.authenticated) {
        next()
    } else {
        if (AUTH_ROUTES.includes(to.name)) {
            next()
        } else {
            if (ACCESS_TOKEN) {
                AuthClient.check()
                    .then(response => {

                        let permissionData = []
                        response.data.data.attributes.permissions.forEach(permission => {
                            permissionData.push({
                                id: permission.id,
                                name: permission.attributes.name
                            })
                        })
                        UserPermission.insert({
                            data: permissionData
                        }).then()

                        Store.dispatch("user/updateUser", {
                            ...response.data,
                            ...response.data.attributes
                        }).then();
                        if (response.data.data.attributes.roles.length === 1) {
                            if (response.data.data.attributes.roles.some(role => role.attributes.name === 'learner')) {
                                Store.dispatch("auth/updateType", 'learner').then();
                            }
                            if (response.data.data.attributes.roles.some(role => role.attributes.name.includes('guardian'))) {
                                Store.dispatch("auth/updateType", 'guardian').then();
                            }
                            if (response.data.data.attributes.roles.some(role => role.attributes.name === 'super admin')) {
                                Store.dispatch("auth/updateSwitch", true).then();
                            }

                        }
                        if (response.data.data.attributes.roles.length > 1) {
                            if (response.data.data.attributes.roles.some(role => role.attributes.name === 'guardian')) {
                                Store.dispatch("auth/updateSwitch", true).then();
                            }
                        }
                        ((Store.state.auth.type === 'guardian' || Store.state.auth.canSwitch) ?
                            AuthClient.checkGuardianHomeAccess().then(() => {
                                Store.state.auth.canAccessGuardianHome = true
                                // Store.dispatch('auth/updateGuardianHomeAccess', true).then()
                            }).catch(() => {
                                Store.state.auth.canAccessGuardianHome = false

                                // Store.dispatch('auth/updateGuardianHomeAccess', false).then()

                            })
                            : Promise.resolve()).finally(() => {
                            Store.dispatch("auth/updateAuthenticated", true).then(() => {
                                return next()
                            });

                        })

                    })
                    .catch(() => {
                        Store.dispatch("auth/updateAuthenticated", false).then();
                        next(new RouterError('Unauthenticated', 401))
                    });
            } else {
                next({
                    path: '/login',
                    query: {redirect: to.fullPath}
                })
                // next(new RouterError('Unauthenticated', 401))
            }
        }
    }
});
