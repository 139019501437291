import {TransformModel} from "@/models/TransformModel";
import ILPUnit from "@/models/ILPUnit";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPTopic extends TransformModel {
    static entity = 'ILPTopics'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(null),
            units: this.hasManyBy(ILPUnit, 'unit_ids'),
            unit_ids: this.attr(null)
        }
    }


}
