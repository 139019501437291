import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import Phase from "@/models/Phase";

/**
 * A level
 * @property {number} id
 * @property {string} name - The name of the phase
 * @property {number} campus_id - The id of the campus this phase belongs to
 * @property {number} ordinality - The order to display this phase

 */
export default class Level extends TransformModel {
    static entity = 'levels'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            long_name: this.attr(''),
            campus_id: this.attr(null),
            ordinality: this.attr(0),
            head_id: this.attr(null),
            head: this.belongsTo(User, 'head_id'),
            sub_head_id: this.attr(null),
            sub_head: this.belongsTo(User, 'sub_head_id'),
            phases: this.hasMany(Phase, 'level_id'),
            head_role_name: this.attr(null),
            sub_head_role_name: this.attr(null)

        }
    }

    /**
     * Returns all levels
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.campus_id]
     * @param {string} [query.search]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/levels`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(level => {
                        if (relationships.includes('phases')) {
                            level.attributes.stages.map(phase => {
                                Object.assign(phase, phase.attributes)
                            })
                        }
                        return {...level, ...level.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a level by its id
     * @function
     * @param {number} id The id of the level
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/levels/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {


                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new level
     * @function
     * @param {Object} level - The level object
     * @param {string} level.name - The name of the level
     * @param {string} level.long_name - The long name of the level
     * @param {number} level.campus_id - The id of the campus the level is associated with
     * @returns {Promise<Response>} - The newly created level
     */

    static Store(level) {
        return this.api().post(`/levels`, level, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing level
     * @function
     * @param {Object} level - The level object
     * @param {string} level.name - The name of the level
     * @param {number} level.campus_id - The id of the campus the level is associated with
     * @param {string} level.id - The id of the level
     * @param {string} level.long_name - The long name of the level
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created level
     */

    static Update(level, saved) {
        return this.api().patch(`/levels/${level.id}`, level, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing level
     * @function
     * @param {number} level_id - The id of the level
     * @returns {Promise<Response>} - level
     */

    static Delete(level_id) {
        return this.api().delete(`/levels/${level_id}`, {
                delete: level_id
            }
        )
    }


}
