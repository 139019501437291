import {
    Model
} from '@vuex-orm/core'

export default class UnitObjective extends Model {
    static entity = 'unitObjective'

    static primaryKey = ['objective_id', 'unit_id']

    static fields() {
        return {
            objective_id: this.attr(null),
            unit_id: this.attr(null)
        }
    }
}
