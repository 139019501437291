import {
    Model
} from '@vuex-orm/core'

export default class Permission extends Model {
    static entity = 'permissions'
    static primaryKey = 'name'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
        }
    }

    static FetchAll() {
        return this.api().get(`/permissions`, {

            dataTransformer: ({data: {data}}) => {
                return data.map(permissions => {

                    return {...permissions, ...permissions.attributes}
                })
            }
        })
    }

}
