import {TransformModel} from "@/models/TransformModel";
import Assessment from "@/models/Assessment";


/**
 * A report mark group
 * @property {number} id
 * @property {string} name - The name of the mark group
 * @property {number} report_id - The id of the parent report
 * @property {number} subject_id - the id of the related subject
 * @property {number} weighting - the weighting
 */
export default class ReportMarkGroup extends TransformModel {
    static entity = 'reportMarkGroups'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            report_id: this.attr(null),
            subject_id: this.attr(null),
            weighting:this.attr(null),
            class_group_id:this.attr(null),
            assessments_objects:this.hasManyBy(Assessment,'assessment_ids'),
            assessment_ids: this.attr([]),
            has_weight_assessments:this.attr(0)
        }
    }

    /**
     * Returns all mark groups for a given report
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.subject_id] - search by subject_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {number} report_id - The id of the parent report
     * @returns {Promise<Response>} All the Reports
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [],report_id) {
        return this.api().get(`/reports/${report_id}/mark-groups`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(markGroup => {
                        if (relationships.includes('assessments')) {
                            if (Object.prototype.hasOwnProperty.call(markGroup.attributes,'assessments')) {
                                markGroup.attributes.assessments.map(assessment => {
                                    Object.assign(assessment, assessment.attributes)
                                    assessment.markgroup_id = markGroup.id
                                })

                                markGroup.attributes.assessments_objects = markGroup.attributes.assessments
                                delete markGroup.attributes.assessments
                            }
                        }
                        return {...markGroup, ...markGroup.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a mark group
     * @function
     * @param {number} report_id - The id of the report
     * @param {number} id - The id of the markgroup
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(report_id,id, relationships = []) {
        return this.api().get(`/reports/${report_id}/mark-groups/${id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {


                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new mark group
     * @function
     * @param {Object} markGroup - The markGroup object
     * @returns {Promise<Response>} - The newly created report
     */

    static Store(markGroup) {
        return this.api().post(`/reports/${markGroup.report_id}/mark-groups`, markGroup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Attach an assessment to a markGroup
     * @function
     * @param {number} report_id - The report_id
     * @param {number} mark_group_id - The mark_group_id
     * @param {number} assessment_id - The assessment_id
     * @param {number} weighting - The weighting
     * @returns {Promise<Response>} - The newly updated mark_group
     */

    static Attach(report_id,mark_group_id,assessment_id,weighting) {
        return this.api().post(`/reports/${report_id}/mark-groups/${mark_group_id}/assessments/${assessment_id}`,{
            ...(weighting===null?{}:{weighting:weighting})
            }, {
            save: false
            }
        )
    }
    /**
     * Detach an assessment from a markGroup
     * @function
     * @param {number} report_id - The report_id
     * @param {number} mark_group_id - The mark_group_id
     * @param {number} assessment_id - The assessment_id
     * @returns {Promise<Response>} - The newly updated mark_group
     */

    static Detach(report_id,mark_group_id,assessment_id) {
        return this.api().delete(`/reports/${report_id}/mark-groups/${mark_group_id}/assessments/${assessment_id}`,{}, {
            save: false
            }
        )
    }

    /**
     * Update a mark-group
     * @function
     * @param {Object} markGroup - The markGroup object
     * @returns {Promise<Response>} - The updated report
     */

    static Update(markGroup) {
        return this.api().patch(`/reports/${markGroup.report_id}/mark-groups/${markGroup.id}`, markGroup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a mark group
     * @function
     * @param {Object} markGroup - The markGroup object
     */

    static Delete(markGroup) {
        return this.api().delete(`/reports/${markGroup.report_id}/mark-groups/${markGroup.id}`, {
                delete: markGroup.id
            }
        )
    }


}
