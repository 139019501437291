<template>
  <div class="card" style="overflow: visible" :class="{'un-card':unCard}">
    <header v-if="title" class="card-header" :class="{'has-background-white':!transparent,'has-background-transparent':transparent}">
      <p class="card-header-title is-capitalized has-text-primary">
        <b-icon v-if="icon" type="is-primary" :icon="icon" custom-size="default"/>
        {{ title }}
      </p>
      <a
          v-if="headerIcon"
          href="#"
          class="card-header-icon has-text-primary"
          aria-label="more options"
          @click.prevent="headerIconClick"
      >
        <b-icon :icon="headerIcon"/>
      </a>
      <a
          v-if="secondHeaderIcon"
          href="#"
          class="card-header-icon has-text-primary"
          aria-label="more options"
          @click.prevent="headerIconClick"
      >
        <b-icon :icon="secondHeaderIcon"/>
      </a>
    </header>
    <hr :class="{'mt-1 mb-1':compact}" class="card-divider">
    <div  class="card-content" :class="{'p-2':compact,'has-background-white':!transparent,'has-background-transparent':transparent}">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    unCard:{
      type:Boolean,
      default() {
        return false;
      }
    },
    transparent:{
      type:Boolean,
      default() {
        return false
      }
    },
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    }, secondHeaderIcon: {
      type: String,
      default: null
    },

    compact: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    headerIconClick() {
      this.$emit("header-icon-click");
    }
  }
};
</script>

<style scoped>
</style>
