import {
    Model
} from '@vuex-orm/core'

export class TransformModel extends Model {
    static transformArray(response) {

        let storageArray = []

        if (response.data.data.length > 1) {
            response.data.data.forEach(element => {
                let target = element
                if (element.attributes.roles !== undefined) {
                    if (element.attributes.roles.length > 0) {
                        element.attributes.role = element.attributes.roles[0].attributes.name
                    }
                }
                Object.assign(target, element.attributes)
                storageArray.push(target)
            });
        } else if (response.data.data.length === 1) {
            let target = response.data.data[0]
            if (target.attributes.roles !== undefined) {
                if (target.attributes.roles.length > 0) {
                    target.attributes.role = target.attributes.roles[0].attributes.name
                }
            }
            Object.assign(target, target.attributes)
            storageArray.push(target)

        } else {
            return
        }

        return storageArray
    }

    static transformArrayWithUser(response) {
        let storageArray = []


        response.data.data.forEach(element => {
            element.attributes.user = {
                ...element.attributes.user,
                ...element.attributes.user.attributes
            }

            element = {
                ...element,
                ...element.attributes
            }
            storageArray.push(element)

        });


        return storageArray
    }


}
