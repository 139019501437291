import {TransformModel} from "@/models/TransformModel";
import ILPStudentProgress from "@/models/ILPStudentProgress";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPObjective extends TransformModel {
    static entity = 'ILPObjectives'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(null),
            objective_id: this.attr(null),
            student_progress: this.hasMany(ILPStudentProgress, 'objective_parent_id'),
        }
    }


}
