import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";


/**
 * A reportUserComment
 * @property {number} id
 * @property {number} report_subject_id
 * @property {number} user_id
 * @property {string} comment
 */
export default class ReportUserComment extends TransformModel {
    static entity = 'reportUserComments'

    static fields() {
        return {
            id: this.attr(null),
            report_id:this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            comment:this.attr(null),
            commenter_name:this.attr(null),
            summary_comment:this.attr(null),
            summary_commenter_name:this.attr(null),


        }
    }

    /**
     * Returns all reportUserComments
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [report_id] - the parent report_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the comments
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], report_id) {
        return this.api().get(`/reports/${report_id}/user-comments`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(subject => {

                        if (relationships.includes('users')) {
                            subject.attributes.users.map(user => {
                                Object.assign(user, user.attributes)
                            })
                        }

                        return {...subject, ...subject.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a reportUserComment
     * @function
     * @param {number} report_id - The id of the report
     * @param {number} user_comment_id - The id of the userComment
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the comment
     */

    static FetchById(report_id, user_comment_id, relationships = []) {
        return this.api().get(`/reports/${report_id}/user-comments/${user_comment_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('user')) {
                        Object.assign(data.attributes.user, data.attributes.user.attributes)
                    }

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new reportUserComment
     * @function
     * @param {Object} reportUserComment - The reportUserComment object
     * @returns {Promise<Response>} - The newly created comment
     */

    static Store(reportUserComment) {
        return this.api().post(`/reports/${reportUserComment.report_id}/user-comments`, reportUserComment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an reportUserComment
     * @function
     * @param {Object} reportUserComment - The reportUserComment object
     * @returns {Promise<Response>} - The updated report
     */

    static Update(reportUserComment) {
        return this.api().patch(`/reports/${reportUserComment.report_id}/user-comments/${reportUserComment.id}`, reportUserComment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a reportUserComment
     * @function
     * @param {Object} reportUserComment - The reportUserComment
     */

    static Delete(reportUserComment) {
        return this.api().delete(`/reports/${reportUserComment.report_id}/user-comments/${reportUserComment.id}`, {
                delete: reportUserComment.id
            }
        )
    }


}
