import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import CourseScope from "@/models/CourseScope";
import ClassGroup from "@/models/ClassGroup";

/**
 * A subject
 * @property {number} id
 * @property {string} name - The name of the subject
 * @property {string} name - The name of the subject with other details
 * @property {string} code - The code of the subject
 * @property {number} campus_id - The id of the campus this subject belongs to
 * @property {number} stage_id - The id of the stage this subject belongs to
 * @property {number} ordinality - The order to display this subject
 */
export default class Subject extends TransformModel {
    static entity = 'subjects'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            long_name: this.attr(''),
            code: this.attr(null),
            class_group_id: this.attr(null),
            class_group: this.belongsTo(ClassGroup, 'class_group_id'),
            subject_id: this.attr(null),
            user_ids: this.attr(() => []),
            users: this.hasManyBy(User, 'user_ids'),
            campus_id: this.attr(null),
            course_scope_id: this.attr(null),
            course_scope: this.belongsTo(CourseScope, 'course_scope_id'),
            stage_id: this.attr(null),
            report_description: this.attr(null),
            ordinality: this.attr(0),
            has_attendances: this.attr(0),
            is_for_programmes: this.attr(null),
            provider_code: this.attr(null),
            is_selected: this.attr(0),
            class_groups: this.hasMany(ClassGroup, 'subject_id'),
            year: this.attr(null),
            teacher_id: this.attr(null),
            teacher: this.belongsTo(User, 'teacher_id')
        }
    }

    /**
     * Returns all subjects
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.stage_id]
     * @param {string} [query.search]
     * @param {string} mode - how to persist the response
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], mode = 'insertOrUpdate') {
        return this.api().get(`/subjects`, {
                persistBy: mode,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(subject => {

                        if (relationships.includes('teacher')) {
                            Object.assign(subject.attributes.teacher, subject.attributes.teacher.attributes)
                        }
                        if (relationships.includes('users')) {
                            subject.attributes.users.map(user => {
                                Object.assign(user, user.attributes)
                            })
                        }
                        if (relationships.includes('class_groups')) {
                            if (Object.prototype.hasOwnProperty.call(subject.attributes, 'class_groups')) {
                                subject.attributes.class_groups.map(group => {
                                    Object.assign(group, group.attributes)
                                })
                            }
                        }
                        if (relationships.includes('course_scope')) {
                            if (Object.prototype.hasOwnProperty.call(subject.attributes, 'course_scope'))
                                if (subject.attributes.course_scope !== null) {
                                    Object.assign(subject.attributes.course_scope, subject.attributes.course_scope.attributes)
                                }
                        }
                        return {...subject, ...subject.attributes}
                    })
                },
            }
        )
    }
    /**
     * Returns all subjects for a user
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.stage_id]
     * @param {string} [query.search]
     * @param {number} [user_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAllByUser({page = 1, limit = 15}, relationships = [],user_id) {
        return this.api().get(`/users/${user_id}/subjects`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (data) {
                        return data.map(subject => {

                            if (relationships.includes('teacher')) {
                                Object.assign(subject.attributes.teacher, subject.attributes.teacher.attributes)
                            }
                            if (relationships.includes('users')) {
                                subject.attributes.users.map(user => {
                                    Object.assign(user, user.attributes)
                                })
                            }
                            if (relationships.includes('class_groups')) {
                                if (Object.prototype.hasOwnProperty.call(subject.attributes, 'class_groups')) {
                                    subject.attributes.class_groups.map(group => {
                                        Object.assign(group, group.attributes)
                                    })
                                }
                            }
                            if (relationships.includes('course_scope')) {
                                if (Object.prototype.hasOwnProperty.call(subject.attributes, 'course_scope'))
                                    if (subject.attributes.course_scope !== null) {
                                        Object.assign(subject.attributes.course_scope, subject.attributes.course_scope.attributes)
                                    }
                            }
                            return {...subject, ...subject.attributes}
                        })
                    }
                    return []
                },
            }
        )
    }

    /**
     * Returns a subject by its id
     * @function
     * @param {number} id The id of the subject
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/subjects/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('teacher')) {
                        Object.assign(data.attributes.teacher, data.attributes.teacher.attributes)
                    }
                    if (relationships.includes('course_scope')) {
                        if (data.attributes.course_scope !== null) {
                            Object.assign(data.attributes.course_scope, data.attributes.course_scope.attributes)
                        }
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new subject
     * @function
     * @param {Object} subject - The subject object
     * @param {string} subject.name - The name of the subject
     * @param {string} subject.code - The code of the subject
     * @param {number} subject.campus_id - The id of the campus the subject is associated with
     * @param {number} subject.stage_id - The id of the stage the subject is associated with
     * @returns {Promise<Response>} - The newly created subject
     */

    static Store(subject) {
        return this.api().post(`/subjects`, subject, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing subject
     * @function
     * @param {Object} subject - The subject object
     * @param {string} subject.name - The name of the subject
     * @param {string} subject.code - The code of the subject
     * @param {number} subject.campus_id - The id of the campus the subject is associated with
     * @param {number} subject.stage_id - The id of the stage the subject is associated with
     * @param {string} subject.id - The id of the subject
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created subject
     */

    static Update(subject, saved) {
        return this.api().patch(`/subjects/${subject.id}`, subject, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing subject
     * @function
     * @param {number} subject_id - The id of the subject
     * @returns {Promise<Response>} - The newly created subject
     */

    static Delete(subject_id) {
        return this.api().delete(`/subjects/${subject_id}`, {
                delete: subject_id
            }
        )
    }


}
