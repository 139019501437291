export const ApplicationModule = {
    namespaced: true,

    state: {
        selected_stage: null,
        selected_stage_id: null,
        selected_phase: null,
        selected_user: null,
        selected_enrolment: null,
        filter_phase: null,
        filter_stage: null,
        filter_stage_term: null,
        filter_subject: null,
        filter_subject_term: null,
        filter_extramural_term: null,
        filter_enrolment_term: null,
        selected_campus_id: null,
        edit_stage: null,
        subject_select_boolean: false,
        application: localStorage.getItem('applications_data_06_07_2021') ? JSON.parse(localStorage.getItem('applications_data_06_07_2021')) : {
            general: {
                year: new Date().getFullYear(),
                month: null,
                phase_id: null,
                stage_id: null,
                campus_id: null,
                programme_id: null,
                programme_delivery_type: null,
                programme_payment_type: null,
                payment_responsibility: null,
                has_device: 0,
                purchase_device: 0,
                saved: false,
                message: null
            },
            student: {
                saved: false,
                id: null,
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    no_email: 0,
                    roles: ['learner'],

                },

                profile: {
                    date_of_birth: null,
                    identification_type_id: null,
                    id_number: null,
                    gender_id: null,
                    dominant_hand: null,
                    ethnicity_id: null,
                    language_id: null,
                    country_of_issue_id: null,
                    religion_id: null,
                    grade_id: null,
                    lunch_option: null,
                    day_length: null,
                    school_attended: {name: null},
                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null
                    },
                    consent: [],
                    siblings: [{
                        age: null, gender: null, current_school: null, name: null
                    }], medical_information: {
                        additional_information: null,
                        doctor_name: null,
                        doctor_contact: null,
                        medical_aid_number: null,
                        medical_aid_provider: null,
                        allergies: null,
                        has_medical_aid: true
                    },
                    emergency_contact: {
                        first_name: null, last_name: null, contact_number: null, relationship: null
                    },
                }
            },
            services:{
                package_id:null,
                optionalServices:[],
                package_services:[],
                noteString:null
            },
            guardian1: {
                saved: false,
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    roles: ['guardian'],
                    title: null,
                    primary_contact_number: "",
                    secondary_contact_number: "",
                    cellphone_number: "",
                },
                profile: {
                    identification_type_id: null,
                    id_number: null,
                    country_of_issue_id: null,
                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null

                    }, alternative_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        suburb: null
                        ,
                        country: null,
                    }, employment_information: {
                        type_of_work: null,
                        contact_number: null,
                        employer: null
                    },
                }
            },
            guardian2: {
                saved: false,
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    roles: ['guardian'],
                    title: null,
                    primary_contact_number: "",
                    secondary_contact_number: "",
                    cellphone_number: "",
                },
                profile: {
                    identification_type_id: null,
                    id_number: null,
                    country_of_issue_id: null,
                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null

                    }, alternative_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null

                    }, employment_information: {
                        type_of_work: null,
                        contact_number: null,
                        employer: null
                    },
                }
            },
            siblings: {
                saved: false
            },
            emermedical: {
                saved: false
            }, payment_responsibility: {
                saved: false,
                type: null
            }, other_info: {
                saved: false,
            },
        }
    },

    actions: {
        clearApplication({commit}) {
            localStorage.clear()
            commit('CLEAR_APPLICATION_DATA')

        }
        ,
        setEditStage({commit}, stage) {
            commit('SET_EDIT_STAGE', stage)
        },
        toggle_subject_select({state}) {
            state.subject_select_boolean = !state.subject_select_boolean
        },
        selectStage({commit}, stage) {
            commit('SET_SELECTED_STAGE', stage)
            commit('SET_SELECTED_STAGE_ID', stage.id)
        }, clearSelectStage({commit}) {
            commit('SET_SELECTED_STAGE', null)
            commit('SET_SELECTED_STAGE_ID', null)
        },
        selectPhase({commit}, phase) {
            commit('SET_SELECTED_PHASE', phase)
        }, selectEnrolment({commit}, enrolment) {
            commit('SET_SELECTED_ENROLMENT', enrolment)
        }, selectUser({commit}, user) {
            commit('SET_SELECTED_USER', user)
        }, setFilterPhase({commit}, phase) {
            commit('SET_FILTER_PHASE', phase)
        }, setFilterSubject({commit}, subject) {
            commit('SET_FILTER_SUBJECT', subject)
        }, setFilterStage({commit}, stage) {
            commit('SET_FILTER_STAGE', stage)
        }, setFilterStageTerm({commit}, term) {
            commit('SET_FILTER_STAGE_TERM', term)
        }, setFilterSubjectTerm({commit}, term) {
            commit('SET_FILTER_SUBJECT_TERM', term)
        }, setFilterExtramuralTerm({commit}, term) {
            commit('SET_FILTER_EXTRAMURAL_TERM', term)
        }, setFilterEnrolmentTerm({commit}, term) {
            commit('SET_FILTER_ENROLMENT_TERM', term)
        },
        selectCampus({commit}, id) {
            commit('SET_SELECTED_CAMPUS', id)
            commit('SET_SELECTED_STAGE', null)
            commit('SET_SELECTED_STAGE_ID', null)
            commit('SET_SELECTED_PHASE', null)
            commit('SET_SELECTED_ENROLMENT', null)
            commit('SET_SELECTED_USER', null)
        }
    },

    mutations: {
        CLEAR_APPLICATION_DATA(state) {
            state.application.general = {
                year: new Date().getFullYear(),
                month: null,
                phase_id: null,
                stage_id: null,
                campus_id: null,
                programme_id: null,
                programme_delivery_type: null,
                programme_payment_type: null,
                has_device: 0,
                purchase_device: 0,
                payment_responsibility: null,
                saved: false,
                message: null
            }
            state.application.services={
                    package_id:null,
                    optionalServices:[],
                package_services:[],
                noteString:null

            }
            state.application.student = {
                saved: false,
                id: null,
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    no_email: 0,
                    roles: ['learner'],
                },
                profile: {
                    date_of_birth: null,
                    identification_type_id: null,
                    id_number: null,
                    gender_id: null,
                    dominant_hand: null,
                    ethnicity_id: null,
                    language_id: null,
                    religion_id: null,
                    grade_id: null,
                    lunch_option: null,
                    country_of_issue_id: null,
                    day_length: null,
                    school_attended: {name: null},
                    consent: [],
                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null
                    },
                    siblings: [{
                        age: null, gender: null, current_school: null, name: null
                    }], medical_information: {
                        additional_information: null,
                        doctor_name: null,
                        doctor_contact: null,
                        medical_aid_number: null,
                        medical_aid_provider: null,
                        allergies: null,
                        has_medical_aid: true

                    },
                    emergency_contact: {
                        first_name: null, last_name: null, contact_number: null, relationship: null
                    },
                }
            }
            state.application.guardian1= {
                saved: false,
                    user: {
                    first_name: null,
                        last_name: null,
                        email: null,
                        roles: ['guardian'],
                        title: null,
                        primary_contact_number: "",
                        secondary_contact_number: "",
                        cellphone_number: "",
                },
                profile: {
                    identification_type_id: null,
                    id_number: null,
                    country_of_issue_id: null,

                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null

                    }, alternative_address: {
                        line_1: null,
                            line_2: null,
                            city: null,
                            province: null,
                            postal_code: null,
                            suburb: null
                            ,
                            country: null,
                    }, employment_information: {
                        type_of_work: null,
                            contact_number: null,
                            employer: null
                    },
                }
            },
            state.application.guardian2= {
                saved: false,
                    user: {
                    first_name: null,
                        last_name: null,
                        email: null,
                        roles: ['guardian'],
                        title: null,
                        primary_contact_number: "",
                        secondary_contact_number: "",
                        cellphone_number: "",
                },
                profile: {
                    identification_type_id: null,
                    id_number: null,
                    country_of_issue_id: null,

                    physical_address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        province: null,
                        postal_code: null,
                        country: null,
                        suburb: null

                    }, alternative_address: {
                        line_1: null,
                            line_2: null,
                            city: null,
                            province: null,
                            postal_code: null,
                            country: null,
                            suburb: null

                    }, employment_information: {
                        type_of_work: null,
                            contact_number: null,
                            employer: null
                    },
                }
            },
            state.application.siblings = {
                saved: false
            }
            state.application.emermedical = {
                saved: false
            }

            state.application.payment_responsibility = {
                saved: false,
                type: null
            }
            state.application.other_info = {
                saved: false,
            }

        },
        SET_EDIT_STAGE(state, stage) {
            state.edit_stage = stage
        },
        SET_SELECTED_STAGE(state, stage) {
            state.selected_stage = stage

        }, SET_SELECTED_PHASE(state, phase) {
            state.selected_phase = phase

        }, SET_SELECTED_ENROLMENT(state, enrolment) {
            state.selected_enrolment = enrolment

        }, SET_SELECTED_USER(state, user) {
            state.selected_user = user

        }, SET_FILTER_PHASE(state, phase) {
            state.filter_phase = phase

        }, SET_FILTER_SUBJECT(state, subject) {
            state.filter_subject = subject

        }, SET_FILTER_STAGE(state, stage) {
            state.filter_stage = stage

        }, SET_FILTER_STAGE_TERM(state, term) {
            state.filter_stage_term = term

        }, SET_FILTER_SUBJECT_TERM(state, term) {
            state.filter_subject_term = term

        }, SET_FILTER_ENROLMENT_TERM(state, term) {
            state.filter_enrolment_term = term

        }, SET_SELECTED_STAGE_ID(state, id) {
            state.selected_stage_id = id

        }, SET_SELECTED_CAMPUS(state, id) {
            state.selected_campus_id = id
        }
    }
};
