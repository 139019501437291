import {TransformModel} from "@/models/TransformModel";

/**
 * A staff members HR profile
 * @property {number} id
 * @property {number} user_id



 */
export default class StaffProfile extends TransformModel {
    static entity = 'staff-profiles'
    static primaryKey = 'user_id'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            department_id: this.attr(null),
            employment_type: this.attr(null),
            employment_status: this.attr(null),
            designation: this.attr(null),
            annual_accrual_rate: this.attr(0),
            annual_carryover_months: this.attr(0),
            rolled_over_annual_leave: this.attr(0),
            accrued_annual_leave: this.attr(0),
            available_annual_leave: this.attr(0),
            annual_leave_taken_count: this.attr(null),
            rolled_over_annual_leave_expiry_date: this.attr(false),
            hours_per_week: this.attr(0),
            extension_code: this.attr(null),
            leave_days_per_year: this.attr(() => {
                return {
                    annual: null,
                    sick: null,
                    unpaid: null,
                    family_responsibility: null,
                    maternity: null,
                    covid: null,
                    study: null,
                    chronic: null,
                    discretional: null,
                    absenteeism: null
                }
            }),
            outstanding_leave_days: this.attr(() => {
                return {
                    annual: null,
                    sick: null,
                    unpaid: null,
                    family_responsibility: null,
                    maternity: null,
                    covid: null,
                    study: null,
                    chronic: null,
                    discretional: null,
                    absenteeism: null
                }
            }),
            manager_id: this.attr(null),
            probation_start_date: this.attr(null),
            probation_end_date: this.attr(null),
            employment_start_date: this.attr(null),
            employment_end_date: this.attr(null)

        }
    }


    /**
     * Returns a profile by the user_id
     * @function
     * @param {number} user_id The id of the user
     * @returns {Promise<Response>}
     */

    static FetchById(user_id) {
        return this.api().get(`/users/${user_id}/staff-profile`, {

                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new profile
     * @function
     * @param {Object} profile - The user object
     * @param {number} user_id
     * @returns {Promise<Response>} - The newly created user
     */

    static Store(profile, user_id) {
        return this.api().post(`/users/${user_id}/staff-profile`, profile, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Update a profile
     * @function
     * @param {Object} profile - The user object
     * @returns {Promise<Response>} - The newly created user
     */

    static Update(profile) {
        return this.api().post(`/users/${profile.user_id}/staff-profile`, profile, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }


}
