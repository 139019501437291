export const AssessmentModule = {
    namespaced: true,

    state: {
        selected_term: null,
        absent_reasons: [{id: 1, name: 'Sick'}, {id: 2, name: 'Family'}, {id: 3, name: 'Suspended'}, {
            id: 4,
            name: 'Late Enrolment'
        }]
    },
    getters: {},
    actions: {

        set_term({commit}, term) {
            commit('SET_TERM', term)
        },

    },

    mutations: {
        SET_TERM(state, term) {
            state.selected_term = term
        },

    }
};
