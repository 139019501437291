import {
    TransformModel
} from '@/models/TransformModel'

/**
 * A type of task
 * @property {number} id
 * @property {string} name
 */
export default class TaskType extends TransformModel {
    static entity = 'types'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
        }
    }


    /**
     * Returns all task types
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {string} [query.search]
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 999}, query = {}) {
        return this.api().get(`/task-types`, {
                params: {
                    ...{
                        page: page, limit: limit,
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(type => {
                        return {...type, ...type.attributes}
                    })
                },
            }
        )
    }


}
