<template>
  <b-menu accordion class="documentation-menu pt-4" style="min-height: unset">
    <b-menu-list class="">
      <template #label>
        <a @click.prevent="$store.state.documentation.docs_menu_expanded=!$store.state.documentation.docs_menu_expanded"
           class="level">
          <div class="level-right">
            <div class="level-item">
              Index
            </div>
          </div>
          <div class="level-left">
            <div class="level-item">
              <b-icon class="is-pulled-right"
                      :icon="docIndexIcon"></b-icon>
            </div>
          </div>
        </a>


      </template>
      <template v-if="$store.state.documentation.docs_menu_expanded">
        <transition name="fade">
          <div>
            <component v-bind:is="item.children.length!==0?'DocsFolderMenuItem':'DocsDocumentMenuItem'"
                       v-for="item in docs" :key="item.id + 'length'+item.children.length"
                       @activated="activateItem(item.id,item.children.length!==0)"

                       :item="item"></component>
          </div>
        </transition>
      </template>

    </b-menu-list>


  </b-menu>
</template>

<script>

import DocsFolderMenuItem from "@/components/siteDocs/DocsFolderMenuItem";
import DocsDocumentMenuItem from "@/components/siteDocs/DocsDocumentMenuItem";

export default {
  name: "DocsIndex",
  components: {DocsFolderMenuItem, DocsDocumentMenuItem},
  data() {
    return {
      isMenuNavBarActive: false,
      learner: Object,
    };
  },
  computed: {
    docIndexIcon() {
      if (this.$store.getters['size/viewType'] === 'mobile') {
        return this.$store.state.documentation.docs_menu_expanded ? 'menu-up' : 'menu-down'
      }
      return this.$store.state.documentation.docs_menu_expanded ? this.$tc('icons.chevron-left') : this.$tc('icons.chevron-right')

    }
  },
  methods: {
    activateItem(id, isFolder) {
      if (isFolder) {
        this.$store.dispatch('documentation/expandDocFolder', id)
      }
      if (!isFolder) {
        this.$store.state.documentation.active_doc_document = id
      }
    }
  },
  props: {
    docs: {
      type: Array,
      default() {
        return []
      }
    }
  },

};
</script>
