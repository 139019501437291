import {TransformModel} from '@/models/TransformModel'


export default class UserRecordType extends TransformModel {
    static entity = 'user-record-types'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            groupName: this.attr(null)
        }
    }


}
