import {addMonths, format, isSameMonth, isValid, lastDayOfMonth, subMonths} from "date-fns";
import exportedVars from '@/assets/scss/_theme-default.scss'

export const CalendarModule = {
    namespaced: true,

    state: {

        start_date_filter: new Date(new Date().getFullYear(), new Date().getMonth()),
        end_date_filter: lastDayOfMonth(addMonths(new Date(), 1)),
        eventTypeFilter: 'All',
        focus_date_filter: new Date(),
        modalOpen: false,
        eventsHold: null,
        expandedPanels: [],
        mobileCalendarExpanded: true,
        campus_filter: null

    },
    getters: {
        colours: () => {
            return exportedVars
        },
        allowTodayClick: state => {
            return !isSameMonth(new Date(), state.focus_date_filter)
        },

        formattedStartDate: state => {
            if (isValid(new Date(state.start_date_filter))) {
                return format((subMonths(new Date(state.start_date_filter), 1)), 'yyyy-MM-dd HH:mm')

            }
            return null
        }, formattedEndDate: state => {
            if (isValid(new Date(state.end_date_filter))) {
                return format(state.end_date_filter, 'yyyy-MM-dd HH:mm')

            }
            return null
        },

    },
    actions: {
        toggleMobileExpand({state}, payload =null) {
            if (payload!==null){
                state.mobileCalendarExpanded = payload
            } else {
                state.mobileCalendarExpanded =  !state.mobileCalendarExpanded
            }
        }, toggleExpand({state}, name) {
            if (state.expandedPanels.includes(name)) {
                state.expandedPanels = state.expandedPanels.filter(panel => {
                    return panel !== name
                })
                return
            }
            state.expandedPanels.push(name)
        },
        set_focus_date({state}, date) {
            let dateObject = new Date(date)
            state.start_date_filter = new Date(dateObject.getFullYear(), dateObject.getMonth())
            state.end_date_filter = lastDayOfMonth(addMonths(dateObject, 1))
            state.focus_date_filter = dateObject

        },

    },


};
