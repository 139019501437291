<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="observer">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Folder</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Name">
            <b-input
                v-model="folder.name"
                placeholder="Folder name"
                required>
            </b-input>
          </b-field>
          <b-field label="View Permissions">
            <ValidationProvider
                rules="required|min-items:1"
                v-slot="{ errors }"
            >
              <b-dropdown
                  append-to-body
                  expanded
                  v-model="folder.permissions_ids"
                  multiple
                  aria-role="list"
              >

                <b-button class="select" expanded slot="trigger"
                >
                  Select
                </b-button>

                <b-dropdown-item
                    :disabled="!$store.getters['entities/user-permissions/find'](`${edit?'edit':'create'} staff folders`)"
                    :value="'view staff folders'"
                    aria-role="listitem"
                    required
                >
                  <span>Staff</span>
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!$store.getters['entities/user-permissions/find'](`${edit?'edit':'create'} hr folders`)"

                    :value="'view hr folders'"
                    aria-role="listitem"
                    required
                >
                  <span>HR</span>
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!$store.getters['entities/user-permissions/find'](`${edit?'edit':'create'} finance folders`)"

                    :value="'view finance folders'"
                    aria-role="listitem"
                    required
                >
                  <span>Finance</span>
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!$store.getters['entities/user-permissions/find'](`${edit?'edit':'create'} guardian folders`)"
                    :value="'view guardian folders'"
                    aria-role="listitem"
                    required
                >
                  <span>Guardian</span>
                </b-dropdown-item>
              </b-dropdown>
              <span
                  class="help is-danger"
              >
                  {{ errors[0] }}
                </span>
            </ValidationProvider>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <button :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" class="button" type="is-primary">Save
          </button>
        </footer>
      </div>
    </ValidationObserver>
  </form>
</template>

<script>
import Folder from "@/models/Folder";
import Permission from "@/models/Permission";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});


extend("min-items", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});
export default {
  name: "FolderForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      folder: {
        name: this.name,
        ordinality: 0,
        permissions_ids: [],
        parent_id: this.parentId

      },
      loadingData: false

    }
  },
  computed: {},
  mounted() {

    if (this.edit) {
      this.folder = JSON.parse(JSON.stringify(this.folderObject))
    }
  },
  methods: {
    submit() {
      let submitObject = JSON.parse(JSON.stringify(this.folder))
      submitObject.permissions_ids = submitObject.permissions_ids.map(permissionName => {
        return Permission.query().where('name', permissionName).first().id
      })
      if (this.edit) {
        Folder.Update({
          name: submitObject.name,
          ordinality: submitObject.ordinality,
          id: submitObject.id,
          permissions: submitObject.permissions_ids,
          parent_id: submitObject.parent_id
        }, true).then(() => {
          this.$buefy.snackbar.open(`Folder updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Folder.Store({
          name: submitObject.name,
          ordinality: submitObject.ordinality,
          permissions: submitObject.permissions_ids,
          parent_id: submitObject.parent_id
        }).then(() => {
          this.$buefy.snackbar.open(`Folder created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  },
  props: {
    folderObject: {
      type: Object,
      default() {
        return null
      }
    },
    parentId: {
      type: Number,
      default() {
        return null
      }
    },
    name: {
      type: String, default() {
        return '';
      }
    },

    edit: {
      type: Boolean, default() {
        return false
      }
    },


  }
}
</script>

