import {TransformModel} from "@/models/TransformModel";
import Campus from "@/models/Campus";
import EventType from "@/models/EventType";
import Media from "@/models/Media";
import Store from '@/store'
import Department from "@/models/Department";


/**
 * An event
 * @property {number} id
 * @property {number} campus_id - The id of the campus this announcement belongs to
 * @property {number} event_type_id - The id of the event_type this event has
 * @property {string} start_date_time
 * @property {string} end_date_time
 * @property {string} name
 * @property {string} description
 * @property {string} created_at
 * @property {boolean} is_internal
 */
export default class CalendarEvent extends TransformModel {
    static entity = 'calendar_events'

    static fields() {
        return {
            id: this.attr(null),
            start_date_time: this.attr(null),
            end_date_time: this.attr(null),
            campuses: this.hasManyBy(Campus, 'campus_ids'),
            campus_ids: this.attr(null),
            campus_id: this.attr(null),
            created_at: this.attr(null),
            name: this.attr(null),
            title: this.attr(null),
            description: this.attr(null),
            event_type: this.belongsTo(EventType, 'event_type_id'),
            event_type_id: this.attr(null),
            is_internal: this.attr(false),
            media: this.hasManyBy(Media, 'media_ids'),
            media_ids: this.attr([]),
            all_day: this.attr(0),
            allDay: this.attr(false),
            start: this.attr(null),
            end: this.attr(null),
            color: this.attr('#26657B'),
            departments: this.belongsTo(Department, 'filters.department_ids'),
            filters: this.attr({
                    department_ids: []
                }
            )
        }
    }


    /**
     * Returns all events
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?string} [query.eventStart]
     * @param {?string} [query.eventEnd]
     * @param {?number} [query.campus]
     * @param {?boolean} [query.internal]
     * @param {?string} [query.name]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/events`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(event => {
                        if (relationships.includes('media')) {
                            if (Object.prototype.hasOwnProperty.call(event.attributes, 'media')) {
                                event.attributes.media.map(item => {
                                    Object.assign(item, item.attributes)
                                    item.mediable_type = 'events'
                                    item.mediable_id = event.id
                                })
                            }

                        }

                        event.attributes.title = event.attributes.name
                        event.attributes.start = event.attributes.start_date_time
                        event.attributes.end = event.attributes.end_date_time
                        event.attributes.allDay = event.attributes.all_day === 1
                        event.attributes.color = Store.getters['calendar/colours']['event_'+event.attributes.event_type_id+'_color']
                        return {...event, ...event.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns an event by its id
     * @function
     * @param {number} id The id of the event
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/events/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('media')) {
                        if (relationships.includes('media')) {
                            if (Object.prototype.hasOwnProperty.call(data.attributes, 'media')) {
                                data.attributes.media.map(item => {
                                    Object.assign(item, item.attributes)
                                    item.mediable_type = 'events'
                                    item.mediable_id = data.id
                                })
                            }

                        }

                    }
                    if (relationships.includes('campuses')) {
                        if (Object.prototype.hasOwnProperty.call(data.attributes, 'campuses')) {
                            data.attributes.campuses.map(item => {
                                Object.assign(item, item.attributes)

                            })
                        }

                    }
                    data.attributes.title = data.attributes.name
                    data.attributes.start = data.attributes.start_date_time
                    data.attributes.end = data.attributes.end_date_time
                    data.attributes.allDay = data.attributes.all_day === 1
                    data.attributes.color = Store.getters['calendar/colours']['event_' + data.attributes.event_type_id + '_color']

                    return {...data, ...data.attributes}

                },
            }
        )
    }

    /**
     * Store a new event
     * @function
     * @param {Object} event - The event object
     * @returns {Promise<Response>} - The newly created event
     */

    static Store(event) {
        return this.api().post(`/events?with[]=campuses`, event, {
            dataTransformer: ({data: {data}}) => {
                data.attributes.title = data.attributes.name
                data.attributes.start = data.attributes.start_date_time
                data.attributes.end = data.attributes.end_date_time
                data.attributes.allDay = data.attributes.all_day === 1
                data.attributes.color = Store.getters['calendar/colours']['event_' + data.attributes.event_type_id + '_color']

                return {...data, ...data.attributes}

            },
            }
        )
    }

    /**
     * Update an existing event
     * @function
     * @param {Object} event - The event object
     * @param {boolean} saved - Whether to persist the response
     * @returns {Promise<Response>} - The newly created application
     */

    static Update(event, saved = true) {
        return this.api().put(`/events/${event.id}?with[]=campuses`, event, {
                dataTransformer: ({data: {data}}) => {
                    data.attributes.title = data.attributes.name
                    data.attributes.start = data.attributes.start_date_time
                    data.attributes.end = data.attributes.end_date_time
                    data.attributes.allDay = data.attributes.all_day === 1
                    data.attributes.color = Store.getters['calendar/colours']['event_' + data.attributes.event_type_id + '_color']

                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing event
     * @function
     * @param {number} event_id - The id of the event
     * @returns {Promise<Response>} - The status of the delete
     */

    static Delete(event_id) {
        return this.api().delete(`/events/${event_id}`, {
                delete: event_id
            }
        )
    }


}
