import {TransformModel} from "@/models/TransformModel";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import Subject from "@/models/Subject";
import User from "@/models/User";


export default class Campus extends TransformModel {
    static entity = 'campuses'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            application_emails: this.attr(null),
            head_user: this.belongsTo(User, 'head_user_id'),
            head_user_id: this.attr(null),
            ordinality: this.attr(0),
            is_open_for_enrolment: this.attr(0),
            phases: this.hasMany(Phase, 'campus_id'),
            stages: this.hasMany(Stage, 'campus_id'),
            subjects: this.hasMany(Subject, 'campus_id'),
            physical_address: this.attr(() => ({
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                postal_code: '',
                country: ''
            })),
        }
    }

    static mutators() {
        return {
            physical_address(value) {
                if (value == null) {
                    return {

                        line_1: '',
                        line_2: '',
                        city: '',
                        province: '',
                        postal_code: '',
                        country: ''
                    }
                }
                return value
            },
            ordinality(value) {
                if (value == null) {
                    return 0
                }
                return value
            }
        }

    }

    /**
     * Returns all campuses
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the campuses
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/campuses`, {
            persistBy: 'insertOrUpdate',
            persistOptions: {
                update: [Object.prototype.hasOwnProperty.call(query, 'is_master') ? 'campuses' : '']
            },
            params: {
                ...{
                    page: page, limit: limit, with: relationships
                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(campus => {
                    if (relationships.includes('phases')) {
                        let {attributes: {phases}} = campus
                        phases.map(phase => {
                                if (relationships.includes('phases.stages')) {
                                    let {attributes: {stages}} = phase
                                    stages.map(stage => {
                                        Object.assign(stage, stage.attributes)
                                    })
                                }
                                Object.assign(phase, phase.attributes)
                            })
                        }
                        return {...campus, ...campus.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a campus by its id
     * @function
     * @param {number} id The id of the campus
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} The campus
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/campuses/${id}`, {
                params: {
                    with: relationships,
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('phases')) {
                        let {attributes: {phases}} = data
                        phases.map(phase => {
                            if (relationships.includes('phases.stages')) {
                                let {attributes: {stages}} = phase
                                stages.map(stage => {
                                    Object.assign(stage, stage.attributes)
                                })
                            }
                            Object.assign(phase, phase.attributes)
                        })
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new campus
     * @function
     * @param {Object} campus - The campus object
     * @param {string} campus.name - The name of the campus
     * @param {number} campus.ordinality - The order of the campus
     * @param {Object} [campus.physical_address] The address of the campus
     * @param {string} [campus.physical_address.line_1] The street address
     * @param {string} [campus.physical_address.line_2] The apartment number
     * @param {string} [campus.physical_address.city] The town/city
     * @param {string} [campus.physical_address.province] The province/state
     * @param {string} [campus.physical_address.province] The province/state
     * @param {string} [campus.physical_address.postal_code] The post code
     * @param {number} [campus.physical_address.country] The country
     * @returns {Promise<Response>} - The newly created campus
     */

    static Store(campus) {
        return this.api().post(`/campuses`, campus, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a campus
     * @function
     * @param {Object} campus - The campus object
     * @param {string} campus.name - The name of the campus
     * @param {number} campus.id - The id of the campus
     * @param {number} campus.ordinality - The order of the campus
     * @param {Object} [campus.physical_address] The address of the campus
     * @param {string} [campus.physical_address.line_1] The street address
     * @param {string} [campus.physical_address.line_2] The apartment number
     * @param {string} [campus.physical_address.city] The town/city
     * @param {string} [campus.physical_address.province] The province/state
     * @param {string} [campus.physical_address.province] The province/state
     * @param {string} [campus.physical_address.postal_code] The post code
     * @param {number} [campus.physical_address.country] The country
     * @returns {Promise<Response>} - The updated campus
     */

    static Update(campus) {
        return this.api().patch(`/campuses/${campus.id}`, campus, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a campus
     * @function
     * @param {number} id - The id of the campus
     */

    static Delete(id) {
        return this.api().delete(`/campuses/${id}`, {
            delete: id
            }
        )
    }

}
