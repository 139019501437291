import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import ProgressType from "@/models/ProgressType";
import ILPObjective from "@/models/ILPObjective";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPStudentProgress extends TransformModel {
    static entity = 'ILPStudentProgresses'

    static fields() {
        return {
            id: this.uid(),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            progress: this.attr(null),
            progress_type: this.belongsTo(ProgressType, 'progress'),
            is_dirty: this.attr(false),
            objective_parent_id: this.attr(null),
            objective: this.belongsTo(ILPObjective, 'objective_parent_id')
        }
    }

    static mutators() {
        return {
            progress(value) {
                if (value) {
                    return value
                }

                return null

            }
        }
    }

}
