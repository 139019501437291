import {TransformModel} from "@/models/TransformModel";
// import Unit from "@/models/Unit";
// import UnitObjective from "@/models/UnitObjective";


/**
 * An objective
 * @property {number} id
 * @property {string} name - The html content of the learning objective
 * @property {string} reference - The ref code of the objective
 */
export default class Objective extends TransformModel {
    static entity = 'objectives'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            reference: this.attr(''),
            recommended_activities: this.attr(null),
            // units: this.belongsToMany(Unit, UnitObjective, 'unit_id', 'objective_id'),
        }
    }

    /**
     * Returns all Objectives
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} query
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the CourseScopeTopics
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/objectives`, {
            params: {
                ...{
                    page: page, limit: limit, with: relationships

                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(object => {
                    return {...object, ...object.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns an Objective
     * @function
     * @param {number} id - The id of the Objective
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/objectives/${id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new objective
     * @function
     * @param {Object} objective - The objective object
     * @returns {Promise<Response>} - The newly created objective
     */

    static Store(objective) {
        return this.api().post(`/objectives`, objective, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a objective
     * @function
     * @param {Object} objective - The objective object
     * @returns {Promise<Response>} - The updated objective
     */

    static Update(objective) {
        return this.api().patch(`/objectives/${objective.id}`, objective, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete an objective
     * @function
     * @param {number} id - The id of the objective
     */

    static Delete(id) {
        return this.api().delete(`/objectives/${id}`, {
                delete: id
            }
        )
    }


}
