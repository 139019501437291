import Router from '@/router/index';
import Store from '@/store/index'

export default Router.beforeEach((to, from, next) => {
    Store.state.menu.activeDropdown = to.name.split('.')[0]
    Store.state.menu.openDropdown = to.name.split('.')[0]

    next()

});
