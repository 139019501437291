<template>
  <b-menu-item :active="isActive"
               class=" has-background-white"

               @click="$store.state.documentation.active_doc_document = item.id;$store.state.documentation.active_doc_document_path = item.fullPath">
    <template #label>
      <div class="">
        <!-- Left side -->
        <div class=" item-container" style="max-width: unset;">

          <div class=" item-name-container">
            <b-icon :icon="$tc('icons.document')" class="has-text-grey-darker is-pulled-left mr-1"></b-icon>
            <!--          </div>-->
            <!--          <div class="level-item item-name-container">-->
            <span class="has-text-black">{{ trimmedName }}</span>
          </div>
        </div>
        <!-- Right side -->

      </div>


    </template>

  </b-menu-item>

</template>

<script>

export default {
  name: 'DocsDocumentMenuItem',
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  watch: {
    isActive(newVal) {
      if (newVal === true) {
        this.$emit('activated')
      }
    }
  },
  mounted() {
    if (this.isActive === true) {
      console.log('ccc')
      this.$emit('activated')
    }
  },
  methods: {},
  computed: {
    trimmedName() {
      return this.item.name.slice(0, -3)
    },
    isActive() {
      return this.$store.state.documentation.active_doc_document_path === this.item.fullPath
    },

  }
}
</script>
