import {TransformModel} from "@/models/TransformModel";
import Objective from '@/models/Objective'
import UnitObjective from "@/models/UnitObjective";

/**
 * A unit
 * @property {number} id
 * @property {string} name - The name of the unit
 * @property {Array.<Object>} objectives - The objectives this unit contains
 */
export default class Unit extends TransformModel {
    static entity = 'units'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            course_scope_topic_id: this.attr(null),
            order: this.attr(null),
            objectives: this.belongsToMany(Objective, UnitObjective, 'unit_id', 'objective_id'),
        }
    }

    /**
     * Returns all Units
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.campus_id] - search by campus_id
     * @param {number} [query.stage_id] - search by stage_id
     * @param {number} [query.phase_id] - search by phase_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the CourseScopeTopicUnits
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/course-scope-topic-units`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(unit => {
                        return {...unit, ...unit.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a Unit
     * @function
     * @param {number} id - The id of the Unit
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Unit
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/course-scope-topic-units/${id}`, {

                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new unit
     * @function
     * @param {Object} unit - The unit object
     * @returns {Promise<Response>} - The newly created unit
     */

    static Store(unit) {
        return this.api().post(`/course-scope-topic-units`, unit, {

                dataTransformer: ({data: {data}}) => {


                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a unit
     * @function
     * @param {Object} unit - The unit object
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} - The updated unit
     */

    static Update(unit, relationships = []) {
        return this.api().patch(`/course-scope-topic-units/${unit.id}`, unit, {

                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('objectives')) {

                        data.attributes.objectives.map(objective => {
                            Object.assign(objective, objective.attributes)
                        })
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a unit
     * @function
     * @param {number} id - The id of the unit
     */

    static Delete(id) {
        return this.api().delete(`/course-scope-topic-units/${id}`, {
                delete: id
            }
        )
    }


}
