import VueCookies from 'vue-cookies'



export const AuthModule = {
    namespaced: true,

    state: {
        type: 'staff',
        authenticated: false,
        canSwitch: false,
        accessToken: VueCookies.get('access_token') || null,
        needs_to_accept: false,
        terms_updated: false,
        canAccessGuardianHome: false, //TODO Reset to default
    },

    actions: {
        updateAuthenticated({commit}, status) {
            commit('SET_AUTHENTICATED', status)
        }, updateGuardianHomeAccess({commit}, status) {
            commit('SET_GUARDIAN_HOME_ACCESS', status)
        }, updateType({commit}, status) {
            commit('SET_TYPE', status)
        }, updateSwitch({commit}, bool) {
            commit('SET_CAN_SWITCH', bool)
        },
    },

    mutations: {
        SET_AUTHENTICATED(state, status) {
            state.authenticated = status
        }, SET_GUARDIAN_HOME_ACCESS(state, status) {
            state.canAccessGuardianHome = status
        }, SET_TYPE(state, status) {
            state.type = status
        }, SET_CAN_SWITCH(state, bool) {
            state.canSwitch = bool
        }
    }
};
