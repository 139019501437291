<template>
  <div class="document-view">
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-4">
      <b-field v-if="!!this.$store.state.documentation.editing_document && this.document!==null" grouped>
        <b-input v-model="document.name"></b-input>
        <!--            <b-input v-model="document.slug"></b-input>-->
        <b-select v-model="document.status_id" placeholder="Status">
          <option :value="1">Draft</option>
          <option :value="2">Published</option>
        </b-select>
      </b-field>
      <div v-else>
        <p class="is-size-3">{{ document !== null ? document.name : 'No document selected' }}</p>
        <template v-if="document!== null">
                  <p>
                    <span
                        v-if="loaded && document.creator && showCreator">Created by {{
                        document.creator.first_name
                      }} {{
                        document.creator.last_name
                      }}
                    </span>
                    <span v-if="document.published_at">{{ formatDate(document.published_at) }}</span>
                  </p>
        </template>
      </div>


      <b-dropdown v-if="document!==null" :position="'is-bottom-left'" append-to-body aria-role="list"
                  @click.native.stop>

        <div slot="trigger" class="">
          <b-icon :icon="$tc('icons.more-vertical')"></b-icon>
        </div>


        <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('delete documents')"
                         aria-role="listitem"
                         @click="startDeleteDocument">
          <div class="media">
            <b-icon :icon="$tc('icons.delete')" class="media-left"></b-icon>
            <div class="media-content">
              <h3>Delete</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('edit documents')"
                         aria-role="listitem" @click="startEditDocument">
          <div class="media">
            <b-icon :icon="$tc('icons.document-edit')" class="media-left"></b-icon>
            <div class="media-content">
              <h3>Edit</h3>
            </div>
          </div>
        </b-dropdown-item>


      </b-dropdown>


    </div>


    <div ref="document_view" class="document-content-container" @mouseenter="psUpdate">
      <div v-if="document!==null && !this.$store.state.documentation.editing_document" class="ck-content content"
           v-html="document.content"></div>
      <ckeditor
          v-if="document!==null && !!this.$store.state.documentation.editing_document && loaded"
          v-model="document.content"
          :config="editorConfig"
          :editor="editor"
          class="content ck-content"
      ></ckeditor>
      <div v-if="document===null">
        No active document
      </div>
      <MediaPanel
          v-if="document!==null"
          :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
          :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
          :has-tag="false"
          :has-upload="!!this.$store.state.documentation.editing_document"
          :model="'documents'"
          :model_id="document.id"
          :submit_button="false"
          :submit_flip="submitMedia"
          :title="'Media'"
          @submitted="submitMedia=false"
      ></MediaPanel>
    </div>
    <hr class="my-1">
    <div v-if="document!==null && !!this.$store.state.documentation.editing_document && loaded"
         class="document-buttons">
      <b-field grouped position="is-centered">
        <b-field position="is-centered">
          <b-button type="is-primary" @click="saveDocument">Save</b-button>
        </b-field>
        <b-field>
          <b-button type="is-primary" @click="$store.state.documentation.editing_document=false">Cancel</b-button>
        </b-field>
      </b-field>
    </div>
    <!--    <div v-if="document!==null && !this.$store.state.documentation.editing_document && loaded"-->
    <!--         class="document-buttons">-->
    <!--      <b-field position="is-centered" grouped>-->
    <!--        <b-field position="is-centered">-->
    <!--          <b-button :disabled="!hasPrevious" @click="goPrevious" :icon-left="$tc('icons.chevron-left')" type="is-primary">-->
    <!--            Previous-->
    <!--          </b-button>-->
    <!--        </b-field>-->
    <!--        <b-field>-->
    <!--          <b-button :disabled="!hasNext" @click="goNext" :icon-right="$tc('icons.chevron-right')" type="is-primary"-->
    <!--          >Next-->
    <!--          </b-button>-->
    <!--        </b-field>-->
    <!--      </b-field>-->
    <!--    </div>-->
  </div>
</template>

<script>

import Document from "@/models/Document";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/documentEditorConfig";
import MediaPanel from "@/components/media/MediaPanel";
import PerfectScrollbar from "perfect-scrollbar";
import {format} from "date-fns";

export default {
  name: 'DocumentView',
  components: {
    MediaPanel
  },
  data() {
    return {
      loaded: false,
      submitMedia: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The document content"},
      },
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  mounted() {
    if (this.$store.state.documentation.active_document !== null) {
      Document.FetchById(this.$store.state.documentation.active_document, ['creator']).then(() => {
        this.loaded = true
        this.psInit()
      })
    } else {
      this.loaded = true
      this.psInit()
    }

  },
  methods: {
    formatDate(date) {
      if (date !== null && typeof date !== 'undefined') {
        date = new Date(date)
        return format(date, 'yyyy-MM-dd')
      }
      return null
    },
    goPrevious() {
      this.$store.state.documentation.editing_document = false
      this.$store.state.documentation.active_document = Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
        return value < this.document.ordinality
      }).orderBy('ordinality', 'desc').get()[0].id
    }, goNext() {
      this.$store.state.documentation.active_document = Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
        this.$store.state.documentation.editing_document = false
        return value > this.document.ordinality
      }).orderBy('ordinality', 'asc').get()[0].id
    },
    saveDocument() {
      this.$store.dispatch('loader/show')
      Document.Update(this.document, true).then(() => {
        this.$store.dispatch('loader/hide')
        this.submitMedia = true
        this.$store.state.documentation.editing_document = false
        this.setQuery({documentSlug: this.document.slug})
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')

      });
    },
    psInit() {
      this.ps = new PerfectScrollbar(this.$refs.document_view, {suppressScrollX: true})
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update()
      }
    },
    startDeleteDocument() {

      this.$buefy.dialog.confirm({
        title: `Deleting document`,
        confirmText: `Delete document`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this document?`,
        onConfirm: () =>
            Document.Delete(this.document.id)
                .then(() => {
                  this.$buefy.snackbar.open(`document deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },

    startEditDocument() {
      this.$store.dispatch('documentation/startEditing', this.document.id)
    }
  },
  computed: {
    showCreator() {
      return this.$store.state.auth.type === 'staff'
    },
    hasNext() {
      if (this.document !== null) {
        return Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
          return value > this.document.ordinality
        }).exists()
      }
      return false
    }, hasPrevious() {
      if (this.document !== null) {

        return Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
          return value < this.document.ordinality
        }).exists()
      }
      return false
    },
    document() {

      if (this.$store.state.documentation.active_document !== null) {
        return Document.query().where('id', this.$store.state.documentation.active_document).with('creator').first()
      }
      return null
    }
  },


}
</script>
