
import {Model} from "@vuex-orm/core";
import Permission from "@/models/Permission";
import PermissionRole from "@/models/PermissionRole";

/**
 * A role
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class Role extends Model {
    static entity = 'roles'

    static fields() {
        return {
            type: this.attr(null),
            id: this.attr(null),
            name: this.attr(''),
            permissions: this.belongsToMany(Permission, PermissionRole, 'role_id', 'permission_id')
        }
    }

    /**
     * Returns all roles
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, relationships = []) {
        return this.api().get(`/roles`, {
            params: {
                ...{
                    page: page, limit: limit, with: relationships
                }
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(role => {
                    if (relationships.includes('permissions')) {
                        role.attributes.permissions.map(permission => {
                            Object.assign(permission, permission.attributes)
                        })
                    }
                    return {...role, ...role.attributes}
                })
            },
        })
    }

    /**
     * Returns a role
     * @function
     * @param {number} id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/roles/${id}`, {
            params: {
                ...{
                    with: relationships
                }
            },
            dataTransformer: ({data: {data}}) => {
                if (relationships.includes('permissions')) {
                    data.attributes.permissions.map(permission => {
                        Object.assign(permission, permission.attributes)
                    })
                }
                return {...data, ...data.attributes}
            },
        })
    }

    /**
     * Store a new role
     * @function
     * @param {Object} role - The role object
     * @param {string} role.name - The name of the role
     * @param {array} role.permissions - The permission ids of the role
     * @returns {Promise<Response>} - The newly created role
     */

    static Store(role) {
        return this.api().post(`/roles`, role, {
                dataTransformer: ({data: {data}}) => {
                    data.attributes.permissions.map(permission => {
                        Object.assign(permission, permission.attributes)
                    })
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing role
     * @function
     * @param {Object} role - The role object
     * @param {string} role.name - The name of the role
     * @param {array} role.permissions - The permission ids of the role
     * @param {string} role.id - The id of the role
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created role
     */

    static Update(role, saved) {
        return this.api().patch(`/roles/${role.id}`, role, {
                dataTransformer: ({data: {data}}) => {
                    data.attributes.permissions.map(permission => {
                        Object.assign(permission, permission.attributes)
                    })
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing role
     * @function
     * @param {number} role_id - The id of the role
     * @returns {Promise<Response>}
     */

    static Delete(role_id) {
        return this.api().delete(`/roles/${role_id}`, {
                delete: role_id
            }
        )
    }

}
