import {TransformModel} from "@/models/TransformModel";
import ILPObjective from "@/models/ILPObjective";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPUnit extends TransformModel {
    static entity = 'ILPUnits'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(null),
            objectives: this.hasManyBy(ILPObjective, 'objective_ids'),
            objective_ids: this.attr(null),
            isOpen: this.attr(false)
        }
    }


}
