import Router from '@/router/index';
import Store from '@/store/index';
import {RouterError} from "@/router/errors";


import UserPermission from '../../models/UserPermission';

export default Router.beforeEach((to, from, next) => {
    if (
        to.meta.permissions
    ) {
        if (to.meta.allowSelf){
            if (parseInt(to.params.id)===Store.state.user.id){
                next()
                return;
            }
        }
        if (to.meta.permissions.some(permission => UserPermission.find(permission))) {
            next()
            return
        }
        next(new RouterError('Forbidden', 403, from))
    }
    next()
});
