<template>
  <card-component
      :icon="$tc('icons.media')"
      :title="title"
      un-card
  >
    <div v-if="hasUpload" class="columns is-multiline is-fullwidth">
      <div class="column is-12">
        <div class="columns is-centered">
          <div class="column is-12 has-text-centered">
            <b-upload v-model="dropFiles" :disabled="!canCreate" drag-drop multiple>
              <section class="section">
                <div class="content has-text-centered">
                  <p class="is-flex is-align-items-center">
                    <b-icon
                        :icon="$tc('icons.upload')"
                        size="is-large"
                    ></b-icon>
                  </p>
                  <p>Drop your file here or click to upload</p>
                  <p>Max file size of 10mb</p>
                </div>
              </section>
            </b-upload>
          </div>
        </div>
      </div>
      <div v-if="dropFiles.length>0" class="upload-file-list column  is-12">
        <div class="notification">
          <form @submit.prevent="submitMedia">
            <div v-for="(dropFile,index) of dropFiles" :key="index" class="mb-5">
              <b-field>
                <p class="is-flex is-align-items-center">
                  <b-icon
                      :icon="$tc('icons.upload')"
                      custom-size="default"
                  ></b-icon>
                  <span>{{ dropFile.name }}</span></p>
              </b-field>
              <b-field group-multiline grouped>

                <b-field v-if="hasCaption">
                  <b-input
                      v-model="dropFile.caption"
                      name="caption"
                      placeholder="Describe the upload"
                  />
                </b-field>
                <b-field v-if="hasTag">
                  <b-select
                      v-model="dropFile.tag"
                      placeholder="Select a tag"
                      required
                  >
                    <option
                        v-for="(tag,index) in tags"
                        :key="index"
                        :value="tag"
                    >{{ tag }}
                    </option>
                  </b-select>
                </b-field>
                <b-field>
                  <b-switch v-if="showGuardianVisibility" v-model="dropFile.uploadVisibility"
                            false-value=0
                            true-value=1>
                    Guardian Visibility
                  </b-switch>
                </b-field>
                <b-field>
                  <b-button :icon-right="$tc('icons.close-circle')" type="is-ghost"
                            @click.prevent="deleteDropFile(index)">

                  </b-button>
                </b-field>
              </b-field>

            </div>
            <b-field v-if="submit_button" class="mt-5">
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit"
                            type="is-primary"
                  >Submit upload{{ dropFiles.length > 1 ? 's' : '' }}
                  </b-button
                  >
                </div>
              </b-field>
            </b-field>


          </form>
        </div>
      </div>
    </div>
    <b-field v-if="hasTag" label="Category">
      <b-select v-model="selectedTag" placeholder="All" @input="setPage(1)">
        <option :value="null">All</option>
        <option
            v-for="tag in tags"
            :key="tag"
            :value="tag">
          {{ tag }}
        </option>
      </b-select>
    </b-field>
    <!--    <b-field>-->
    <!--      <b-switch v-if="showGuardianVisibility" v-model="visibility" @input="fetchMedia"-->
    <!--      true-value=1-->
    <!--      false-value=0>-->
    <!--        Guardian Visibility-->
    <!--      </b-switch>-->
    <!--    </b-field>-->
    <PerfectScrollbarWrapper>

      <b-table v-if="hasTable"
               :bordered="false"
               :data="media"
               :hoverable="true"
               :striped="true"
               class="margin-top"
      >
        <template #empty>
          <div class="has-text-centered">No Media</div>
        </template>
        <b-table-column v-slot="props"

                        field="filename"
                        label="Filename"
                        sortable
        >{{ props.row.filename }}
        </b-table-column
        >

        <b-table-column v-if="hasTag" v-slot="props"
                        field="tag"
                        label="Tag"
                        sortable
        >{{ props.row.tag }}
        </b-table-column>
        <b-table-column v-if="hasCaption"
                        v-slot="props"
                        field="caption"
                        label="Caption"
                        sortable
        >{{
            props.row.caption
          }}
        </b-table-column
        >
        <b-table-column
            v-if="showGuardianVisibility"
            v-slot="props"
            field="visibility"
            label="Guardian Visibility"
            sortable
        >
          <b-switch v-model="props.row.visibility"
                    :disabled="!$store.getters['entities/user-permissions/find']('edit media')"
                    :false-value=0
                    :true-value=1
                    @input="updateMedia(props.row)">
            {{ props.row.visibility === 1 ? 'Visible' : 'Not Visible' }}
          </b-switch>
        </b-table-column
        >

        <b-table-column v-slot="props" custom-key="actions" width="170">
          <b-field grouped>
            <b-field>
              <b-button
                  :href="props.row.temporary_url"
                  tag="a"
                  target="_blank"
                  type="is-primary">View
              </b-button>

            </b-field>
            <b-field style="height: fit-content; margin: auto">
              <b-tooltip
                  v-if="canDelete"
                  label="Delete"
                  size="is-small"
                  type="is-danger">
                <a
                    class="card-header-icon has-text-danger px-0 py-0"
                    @click.prevent.stop="startDelete(props.row)">
                  <b-icon :icon="$tc('icons.delete')"/>
                </a>
              </b-tooltip>
            </b-field>
          </b-field>

        </b-table-column>
      </b-table>
    </PerfectScrollbarWrapper>
    <b-pagination
        v-if="media.length>20"
        :current="page"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="mt-4"
        v-on:change="setPage"
    ></b-pagination>
    <b-loading
        :active.sync="updatingMedia"
        :can-cancel="false"
        :is-full-page="false"
    ></b-loading>
  </card-component>

</template>
<script>
import CardComponent from "@/components/layout/CardComponent";
import Media from "@/models/Media";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: 'MediaPanel',

  data() {
    return {
      updatingMedia: false,
      dropFiles: [],
      selectedTag: null,
      visibility: 0,
      mediaDataHold: null,
      meta: Object,
      page: 1,
      limit: 100,
      offset: 0,
      paginationLimit: 15
    }
  },
  watch: {
    submit_flip(val) {
      if (val === true) {
        this.submitMedia()
      }
    },
    selectedTag(){
      this.fetchMedia()
      this.setPage(1)
    }
  },
  props: {
    showGuardianVisibility: {
      type: Boolean,
      default() {
        return false
      }
    },
    submit_flip: {
      type: Boolean,
      default() {
        return false
      }
    },
    submit_button: {
      type: Boolean,
      default() {
        return true
      }
    },
    model: {
      type: String, required: true,
    },
    retrieve_model: {
      type: String, default() {
        return null
      },
    },
    model_id: {
      type: Number, required: true,
    },
    tags: {
      type: Array, default() {
        return [
          'Birth Certificate',
          'Proof of Address',
          'Proof of Banking',
          'ID',
          'Passport',
          'School Report',
          'Academic Assessment',
          'Medical Certificate',
          'PoP (Registration)',
          'PoP (Enrolment)',
          'Entrance Assessment'
        ]
      }
    },

    canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    hasUpload: {
      type: Boolean, default() {
        return true
      }
    }, hasTable: {
      type: Boolean, default() {
        return true
      }
    }, hasCaption: {
      type: Boolean, default() {
        return true
      }
    }, hasTag: {
      type: Boolean, default() {
        return true
      }
    },
    title: {
      type: String, default() {
        return 'Supporting Documents'
      }
    }
  },
  computed: {

    media() {
      return Media.query()
          .where('mediable_type' === this.model)
          .where('mediable_id', this.model_id)
          .where(media=>{
            if (!this.selectedTag) {
              return true
            }
            return this.selectedTag === media.tag
          })
          .offset((this.page - 1) * this.paginationLimit)
          .limit(this.paginationLimit)
          .get()
    }
  },
  mounted() {
    console.log(this.model_id)
    this.fetchMedia().then(() => {
      this.setPage(1)

    })
  },
  methods: {
    setPage(pageNumber) {
      // this.updatingMedia = true;
      // this.mediaDataHold = this.media;
      // Media.deleteAll();
      this.page = pageNumber;


    },

    updateMedia(media) {
      this.updatingMedia = true
      Media.Update({
        id: parseInt(media.id),
        tag: media.tag,
        visibility: media.visibility,
        caption: media.caption
      }).then(() => {
        this.updatingMedia = false
      }).catch(err => {
        this.handleError(err)
        this.updatingMedia = false
      })
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    delete(media) {
      this.updatingMedia = true
      Media.Delete(media.id).then(() => {
        this.$buefy.snackbar.open('Media item deleted!')
        this.updatingMedia = false
      }).catch(
          err => {
            this.updatingMedia = false;

            this.handleError(err)
          }
      )
    },
    startDelete(media) {
      this.$buefy.dialog.confirm({
        title: 'Deleting media',
        confirmText: 'Delete Media',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this media item?',
        onConfirm: () => this.delete(media)
      })
    },
    fetchMedia() {

      this.updatingMedia = true
      return Media.FetchAll({page: 1, limit: this.limit}, {
        modelName: this.retrieve_model ? this.retrieve_model : this.model,
        model: this.model_id,
        tag: this.selectedTag,
      }).then(() => {
        this.updatingMedia = false
      }).catch(
          err => {
            this.updatingMedia = false;
            this.handleError(err)
          }
      )
    },
    submitMedia() {
      this.updatingMedia = true;
      let promises = []
      this.dropFiles.map(file => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("tag", file.tag);
        formData.append("caption", typeof file.caption !== 'undefined' ? file.caption : '');

        let submitRequestGuardianVis = 1 //visible to guardians by default
        if (this.showGuardianVisibility) {
          submitRequestGuardianVis = typeof file.uploadVisibility !== 'undefined' ? file.uploadVisibility : 0
        }

        formData.append("visibility", submitRequestGuardianVis)
        promises.push(Media.Store({model: this.model, model_id: this.model_id}, formData))
      })
      Promise.all(promises)
          .then(() => {
            this.dropFiles = []
            this.$emit('submitted')
            this.updatingMedia = false;

            this.fetchMedia()
          }).catch(
          err => {
            this.updatingMedia = false;

            this.handleError(err)
          }
      )
    }
  }


  ,
  components: {
    CardComponent,
    PerfectScrollbarWrapper

  }
}
</script>
