import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";


/**
 * A reportSubjectComment
 * @property {number} id
 * @property {number} report_subject_id
 * @property {number} user_id
 * @property {number} creator_id
 * @property {string} comment
 * @property {Array<string>} json_comments
 */
export default class ReportSubjectComment extends TransformModel {
    static entity = 'reportSubjectComments'

    static fields() {
        return {
            id: this.attr(null),
            report_subject_id:this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'), 
            creator_id: this.attr(null),
            creator: this.belongsTo(User, 'creator_id'),
            comment:this.attr(null),
            json_comments:this.attr(()=>{}),

        }
    }
    static mutators() {
        return {
            json_comments(value) {
                if (value === []) {
                    return {

                    }
                }
                return value
            },

        }

    }
    /**
     * Returns all reportSubjectComments
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.report_subject_id] - Query terms for the request
     * @param {number} [report_id] - the parent report_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the comments
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], report_id) {
        return this.api().get(`/reports/${report_id}/subject-comments`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(subject => {

                        if (relationships.includes('users')) {
                            subject.attributes.users.map(user => {
                                Object.assign(user, user.attributes)
                            })
                        }

                        return {...subject, ...subject.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a reportSubjectComment
     * @function
     * @param {number} report_id - The id of the report
     * @param {number} subject_comment_id - The id of the subjectComment
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the comment
     */

    static FetchById(report_id, subject_comment_id, relationships = []) {
        return this.api().get(`/reports/${report_id}/subject-comments/${subject_comment_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('user')) {
                            Object.assign(data.attributes.user, data.attributes.user.attributes)
                    }

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new reportSubjectComment
     * @function
     * @param {Object} reportSubjectComment - The reportSubjectComment object
     * @returns {Promise<Response>} - The newly created comment
     */

    static Store(reportSubjectComment) {
        return this.api().post(`/reports/${reportSubjectComment.report_id}/subject-comments`, reportSubjectComment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an reportSubjectComment
     * @function
     * @param {Object} reportSubjectComment - The reportSubjectComment object
     * @returns {Promise<Response>} - The updated report
     */

    static Update(reportSubjectComment) {
        return this.api().patch(`/reports/${reportSubjectComment.report_id}/subject-comments/${reportSubjectComment.id}`, reportSubjectComment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a reportSubjectComment
     * @function
     * @param {Object} reportSubjectComment - The reportSubjectComment
     */

    static Delete(reportSubjectComment) {
        return this.api().delete(`/reports/${reportSubjectComment.report_id}/subject-comments/${reportSubjectComment.id}`, {
                delete: reportSubjectComment.id
            }
        )
    }


}
