import axios from 'axios';
import Store from '@/store'

let ACCESS_TOKEN = Store.state.auth.accessToken
/**
 *  The client which is an axios instance
 * @param baseUrl
 * @param additionalHeaders
 * @returns {AxiosInstance}
 */
let client = (baseUrl = null, additionalHeaders = {}) => {
    let accessToken = ACCESS_TOKEN;
    if (ACCESS_TOKEN == null) {
        accessToken = '';
    }

    let defaultHeaders = {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (accessToken.length > 0) {
        defaultHeaders['Authorization'] = 'Bearer ' + accessToken;
    }

    let axiosOptions = {
        baseURL: baseUrl,
        headers: {
            ...defaultHeaders,
            ...additionalHeaders
        }
    };

    return axios.create(axiosOptions);
};

/**
 * Class that creates APIs
 */
class ApiClientFactory {
    /**
     * Set the baseUrl
     * @param {string} baseUrl
     */
    constructor(baseUrl = process.env.VUE_APP_BASE_URL) {

        this.baseUrl = baseUrl;
    }

    /**
     * Perform a get request
     * @param {string} url
     * @param {Object} [requestData={}]
     * @param {Object} [headers={}]
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(url, requestData = {}, headers = {}) {
        return client(this.baseUrl, headers).get(url, requestData);
    }

    /**
     * Perform a post request
     * @param {string} url
     * @param {Object} [requestData={}]
     * @param {Object} [headers={}]
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(url, requestData = {}, headers = {}) {
        return client(this.baseUrl, headers).post(url, requestData);
    }

    /**
     * Perform a put request
     * @param {string} url
     * @param {Object} [requestData={}] The Request dadta
     * @param {Object} [headers={}]
     * @returns {Promise<AxiosResponse<any>>}
     */
    put(url, requestData = {}, headers = {}) {
        return client(this.baseUrl, headers).put(url, requestData);
    }

    /**
     * Perform a delete request
     * @param {string} url
     * @param {Object} [requestData={}]
     * @param {Object} [headers={}]
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(url, requestData = {}, headers = {}) {
        return client(this.baseUrl, headers).delete(url, requestData);
    }
}

export {
    ApiClientFactory
};
