<template>
  <b-menu-item :active="isActive" :expanded.sync="isExpanded"
               class="is-active has-background-white"
               @click="$store.state.documentation.active_doc_folder = item.id">
    <template #label="props">
      <div class=" ">
        <!-- Left side -->
        <div class="item-container" style="max-width: unset;">

          <div class=" item-name-container">
            <b-icon :icon="props.expanded ? 'folder-open' : 'folder'"
                    class="has-text-black is-pulled-left mr-1"></b-icon>
            <!--          </div>-->
            <!--          <div class="level-item item-name-container">-->
            <span class="has-text-black">{{ item.name }}</span>
          </div>
        </div>
        <!-- Right side -->
      </div>


    </template>

    <div>
      <component style="display: block" v-bind:is="item.children.length!==0?'DocsFolderMenuItem':'DocsDocumentMenuItem'"
                 v-for="item in orderedItems" :key="item.id"
                 class="pl-1"
                 @activated="activateItem(item.id,item.children.length!==0)"
                 :item="item"></component>
    </div>

  </b-menu-item>

</template>

<script>

import DocsDocumentMenuItem from "@/components/siteDocs/DocsDocumentMenuItem";

export default {
  name: 'DocsFolderMenuItem',
  data() {
    return {
      loading: false
    }
  },
  components: {DocsDocumentMenuItem},
  methods: {
    activateItem(id, isFolder) {
      if (isFolder) {
        this.$store.dispatch('documentation/expandDocFolder', id)
      }
      if (!isFolder) {
        this.$store.state.documentation.active_doc_document = id
      }
      this.$emit('activated')
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {

    isExpanded: {
      get() {
        return this.$store.state.documentation.expanded_doc_folders.includes(this.item.id)
      },
      set(newVal) {
        if (newVal === true) {
          this.$store.dispatch('documentation/expandDocFolder', this.item.id)
        } else {
          this.$store.dispatch('documentation/collapseDocFolder', this.item.id)

        }
      }
    },
    isActive() {
      return this.$store.state.documentation.active_doc_folder === this.item.id
    },

    orderedItems() {
      if (this.item.children) {
        return this.item.children
      }
      return []
    },
  },
  mounted() {

  }
}
</script>
