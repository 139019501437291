import {TransformModel} from "@/models/TransformModel";
import Role from "@/models/Role";
import RoleUser from "@/models/RoleUser";
import Extramural from "@/models/Extramural";
import Campus from "@/models/Campus";
import Enrolment from "@/models/Enrolment";
import Profile from "@/models/Profile";
import Attendance from "@/models/Attendance";
import Service from "@/models/Service";
import SportsHouse from "@/models/SportsHouse";
import EnrolmentStatus from "./EnrolmentStatus";

/**
 * A user
 * @property {number} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} title
 * @property {string} email
 * @property {string} password - Creation only
 * @property {Array.<Object>} roles
 * @property {Array.<Object>} campuses
 * @property {Array.<Object>} extramurals
 * @property {Array.<Object>} guardians
 * @property {Array.<Object>} wards
 */
export default class User extends TransformModel {
    static entity = 'users'

    static fields() {
        return {
            id: this.attr(null),
            first_name: this.attr(null),
            profile: this.hasOne(Profile, 'user_id'),
            avatar: this.attr('https://via.placeholder.com/300x300.png?text=Placeholder'),
            last_name: this.attr(null),
            title: this.attr(null),
            email: this.attr(null),
            attendances: this.hasMany(Attendance, 'user_id'),
            campus_ids: this.attr(() => []),
            campuses: this.hasManyBy(Campus, 'campus_ids'),
            extramurals: this.hasMany(Extramural, 'user_id'),
            roles: this.belongsToMany(Role, RoleUser, 'user_id', 'role_id'),
            guardian_ids: this.attr(() => []),
            guardians: this.hasManyBy(User, 'guardian_ids'),
            ward_ids: this.attr(() => []),
            wards: this.hasManyBy(User, 'ward_ids'),
            enrolments: this.hasMany(Enrolment, 'user_id'),
            primary_contact_number: this.attr(""),
            secondary_contact_number: this.attr(""),
            cellphone_number: this.attr(""),
            service_ids: this.attr(() => []),
            services: this.hasManyBy(Service, 'service_ids'),
            enrolment_status_id: this.attr(null),
            enrolment_status: this.belongsTo(EnrolmentStatus, 'enrolment_status_id'),
            sports_house_id: this.attr(null),
            sports_house: this.belongsTo(SportsHouse, 'sports_house_id'),
            accepted_terms_at: this.attr(null),
            involvement_id: this.attr(null),
            report_is_blacklist: this.attr(null),
            deleted_at: this.attr(null)
        }
    }

    static mutators() {
        return {
            avatar(value) {
                if (value == null) {
                    return 'https://via.placeholder.com/300x300.png?text=Placeholder'
                }
                return value
            },
            primary_contact_number(value) {
                if (value == null) {
                    return ""
                }
                return value
            }, secondary_contact_number(value) {
                if (value == null) {
                    return ""
                }
                return value
            }, cellphone_number(value) {
                if (value == null) {
                    return ""
                }
                return value
            },
        }

    }

    /**
     * Returns all users
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.phase_id]
     * @param {string} [query.user_role]
     * @param {string} [query.without_role]
     * @param {Array<string>} [query.without_roles]
     * @param {number} [query.user_permission]
     * @param {string} [query.search]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {Boolean} save
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], save = true) {
        return this.api().get(`/users`, {
                save: save,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(user => {

                        if (relationships.includes('guardians')) {
                            user.attributes.guardians.map(guardian => {
                                Object.assign(guardian, guardian.attributes)
                            })
                        }
                        if (relationships.includes('wards')) {
                            user.attributes.wards.map(ward => {
                                Object.assign(ward, ward.attributes)
                            })
                        }
                        return {...user, ...user.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all users
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.attendance_type]
     * @param {string} [query.date]
     * @param {number} [query.related_entity_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAttendanceUsers({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/attendances/${query.attendance_type}/${query.related_entity_id}/users`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships, ...(query !== {} ? query : {})
                    }
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(user => {

                        if (relationships.includes('attendances')) {
                            user.attributes.attendances.map(attendances => {
                                Object.assign(attendances, attendances.attributes)
                            })
                        }
                        if (Object.prototype.hasOwnProperty.call(user.attributes, 'enrolments')) {
                            user.attributes.enrolments.map(enrolment => {
                                Object.assign(enrolment, enrolment.attributes)
                            })
                        }
                        return {...user, ...user.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all for a report_subject
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.class_group_id]
     * @param {number} [report_id]
     * @param {number} [report_subject_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchReportSubjectUsers({
                                       page = 1,
                                       limit = 15
                                   }, query = {}, relationships = [], report_id, report_subject_id) {
        return this.api().get(`/reports/${report_id}/subjects/${report_subject_id}/users`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships, ...(query !== {} ? query : {})
                    }
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(user => {


                        return {...user, ...user.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all for a report_extramural
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {number} [report_id]
     * @param {number} [report_extramural_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchReportExtramuralUsers({page = 1, limit = 15}, relationships = [], report_id, report_extramural_id) {
        return this.api().get(`/reports/${report_id}/extramurals/${report_extramural_id}/users`, {
            params: {
                ...{
                    page: page, limit: limit, with: relationships,
                }
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(user => {


                    return {...user, ...user.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a user by its id
     * @function
     * @param {number} id The id of the user
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/users/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {

                    if (relationships.includes('guardians')) {
                        data.attributes.guardians.map(guardian => {
                            Object.assign(guardian, guardian.attributes)
                        })
                    }

                    if (relationships.includes('sports_house')) {
                        if (data.attributes.sports_house.length > 0) {
                            data.attributes.sports_house = data.attributes.sports_house[0]
                            Object.assign(data.attributes.sports_house, data.attributes.sports_house.attributes)
                        } else {
                            data.attributes.sports_house = null
                        }
                    }
                    if (relationships.includes('wards')) {
                        data.attributes.wards.map(ward => {
                            Object.assign(ward, ward.attributes)
                        })
                    }

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new user
     * @function
     * @param {Object} user - The user object
     * @param {string} user.name - The name of the user
     * @param {number} user.campus_id - The id of the campus the user is associated with
     * @param {number} user.phase_id - The id of the phase the user is associated with
     * @returns {Promise<Response>} - The newly created user
     */

    static Store(user) {
        return this.api().post(`/users`, user, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Accept terms
     * @function
     * @param {number} user_id - The id of the user
     * @returns {Promise<Response>} - The newly created user
     */

    static Accept(user_id) {
        return this.api().post(`/users/${user_id}/accept-terms`, {}, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Update an existing user
     * @function
     * @param {Object} user - The user object
     * @param {string} user.name - The name of the user
     * @param {number} user.campus_id - The id of the campus the user is associated with
     * @param {number} user.phase_id - The id of the phase the user is associated with
     * @param {string} user.id - The id of the user
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created user
     */

    static Update(user, saved) {
        return this.api().patch(`/users/${user.id}`, user, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing user
     * @function
     * @param {number} user_id - The id of the user
     * @returns {Promise<Response>} - The newly created user
     */

    static Delete(user_id) {
        return this.api().delete(`/users/${user_id}`, {
                delete: user_id
            }
        )
    }

    /**
     * Change a user's password
     * @function
     * @param {number} user_id
     * @param {string} password
     * @param {string} password_confirmation
     * */
    static changePassword(user_id, password, password_confirmation) {
        return this.api().put(`/users/${user_id}/password`, {
            password: password,
            password_confirmation: password_confirmation
        }, {
            save: false
        })
    }

    /**
     * Attach profile picture
     * @function
     * @param {number} user_id - The id of the user
     * @param {File} image
     * @returns {Promise<Response>} - The newly created user
     */

    static Attach(user_id, image) {
        return this.api().post(`/users/${user_id}/attach-avatar`, image
        )
    }

    /**
     * Get learners associated with a lessonPlan
     * @function
     * @param {number} lesson_plan_id - The id of the lessonPlan
     */
    static FetchLearners(lesson_plan_id) {
        return this.api().get(`/lesson-plans/${lesson_plan_id}/users`, {
            dataTransformer: ({data: {data}}) => {
                if (data) {
                    return data.map(user => {


                        return {...user, ...user.attributes}
                    })
                }
                return []
            },
        })
    }

    /**
     * Get learners associated with a lessonPlan
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.class_group_id]
     * @param {number} [lesson_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * */
    static FetchLearnersForLesson({page = 1, limit = 15}, relationships = [], lesson_id, query) {
        return this.api().get(`/lessons/${lesson_id}/users`, {
            params: {
                ...{
                    page: page, limit: limit, with: relationships, ...(query !== {} ? query : {})
                }
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(user => {


                    return {...user, ...user.attributes}
                })
            },
        })
    }

    /**
     * Get learners associated with a report
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.home_class_id] - id of the homeclass
     * @param {number} [query.class_group_id] - id of the classgroup
     * @param {number} report_id - The id of the report
     */
    static FetchByReportId({page = 1, limit = 15}, query = {}, report_id = null) {
        return this.api().get(`/reports/${report_id}/users`, {
            params: {
                ...{
                    page: page, limit: limit
                }, ...(query !== {} ? query : {})
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(user => {


                    return {...user, ...user.attributes}
                })
            },
        })
    }

}
