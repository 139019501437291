import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import {format} from "date-fns";

/**
 * A leave request
 * @property {number} id
 * @property {number} user_id
 * @property {Object} user - The user who is requesting leave
 * @property {number} approver_id
 * @property {Object} approver - The manager generally. The one who must approve the leave. handled by the API
 * @property {number} approved_by_user_id
 * @property {Object} approved_by_user - The user who approved the leave
 * @property {number} type_id -  Options: 1 => 'annual', 2 => 'sick', 3 => 'unpaid', 4 => 'family_responsibility', 5 => 'maternity', 6 => 'covid', 7 => 'study', 8 => 'chronic', 9 => 'discretional', 10 => 'absenteeism
 * @property {number} status_id -  Options: 1 = pending, 2 = approved, 3 = declined
 * @property {Array<Object>} dates -  the dates of the leave {date:date,type:'full-day' or 'half-day'}
 * @property {boolean} is_absent -  if the leave is for logging absenteeism
 * @property {number} length -  number of days requested
 * @property {string} note - The notes on the leave


 */
export default class LeaveRequest extends TransformModel {
    static entity = 'leaveRequests'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            approver_id: this.attr(null),
            approver: this.belongsTo(User, 'approver_id'),
            approved_by_user_id: this.attr(null),
            approved_by_user: this.belongsTo(User, 'approved_by_user_id'),
            type_id: this.attr(null),
            status_id: this.attr(null),
            dates: this.attr(() => []),
            is_absent: this.attr(false),
            length: this.attr(null),
            note: this.attr(null),
            created_at:this.attr(null),
            is_archived:this.attr(false),
            archived_at:this.attr(null)
        }
    }

    /**
     * Returns all leave requests
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.aprrover]
     * @param {number} [query.approvedby]
     * @param {number} [query.user]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/leaves`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(leave_request => {

                        if (relationships.includes('user')) {
                            if (leave_request.attributes.user !== null) {
                                Object.assign(leave_request.attributes.user, leave_request.attributes.user.attributes)
                            }
                        }
                        if (relationships.includes('approver')) {
                            if (leave_request.attributes.approver !== null) {
                                Object.assign(leave_request.attributes.approver, leave_request.attributes.approver.attributes)
                            }
                        }
                        if (relationships.includes('approvedby')) {
                            if (leave_request.attributes.approvedby !== null) {
                                Object.assign(leave_request.attributes.approvedby, leave_request.attributes.approvedby.attributes)
                            }
                        }
                        leave_request.attributes.dates.sort((a, b) => new Date(a.date) - new Date(b.date))
leave_request.attributes.created_at = format(new Date(leave_request.attributes.created_at),'yyyy-MM-dd')

                        return {...leave_request, ...leave_request.attributes}
                    })
                },
            }
        )
    }
    /**
     * Returns a leave request
     * @function
     * @param {number} id - Which request to retrieve
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/leaves/${id}`, {
                params: {
                    ...{
                     with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {

                        if (relationships.includes('user')) {
                            if (data.attributes.user !== null) {
                                Object.assign(data.attributes.user, data.attributes.user.attributes)
                            }
                        }
                        if (relationships.includes('approver')) {
                            if (data.attributes.approver !== null) {
                                Object.assign(data.attributes.approver, data.attributes.approver.attributes)
                            }
                        }
                        if (relationships.includes('approvedby')) {
                            if (data.attributes.approvedby !== null) {
                                Object.assign(data.attributes.approvedby, data.attributes.approvedby.attributes)
                            }
                        }
                    data.attributes.dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                    data.attributes.created_at = format(new Date(data.attributes.created_at),'yyyy-MM-dd')

                        return {...data, ...data.attributes}
                 
                },
            }
        )
    }

    /**
     * Store a new leave request
     * @function
     * @param {Object} leaveRequest - The leave_request object
     * @returns {Promise<Response>} - The newly created request
     */

    static Store(leaveRequest) {
        return this.api().post(`/leaves`, leaveRequest, {
                dataTransformer: ({data: {data}}) => {
                    data.attributes.dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                    data.attributes.created_at = format(new Date(data.attributes.created_at),'yyyy-MM-dd')

                    return {...data, ...data.attributes}

                },
            }
        )
    }

    /**
     * Update an existing leave request
     * @function
     * @param {Object} leave_request - The leave_request object
     * @param {boolean} saved - Whether to persist the response
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static Update(leave_request, saved) {
        return this.api().patch(`/leaves/${leave_request.id}`, leave_request, {
                dataTransformer: ({data: {data}}) => {
                    console.log(data)
                    data.attributes.dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                    data.attributes.created_at = format(new Date(data.attributes.created_at),'yyyy-MM-dd')

                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing leave_request
     * @function
     * @param {number} leave_request_id - The id of the leave_request
     */

    static Delete(leave_request_id) {
        return this.api().delete(`/leaves/${leave_request_id}`, {
                delete: leave_request_id
            }
        )
    }




}
