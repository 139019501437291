<template>
  <div :class="{'modal-card':inModal}" class="documentation-window">
    <header class="modal-card-head ">

      <span class="modal-card-title has-text-weight-semibold is-size-3 is-flex is-align-items-center is-justify-content-space-between">Info Hub



            <b-dropdown :position="'is-bottom-left'" append-to-body aria-role="list">

        <div slot="trigger" class="">
          <b-icon :icon="$tc('icons.more-vertical')"></b-icon>
        </div>

        <b-dropdown-item
            aria-role="listitem"
            @click="$store.state.documentation.show_drafts=!$store.state.documentation.show_drafts">
          <div class="media">
            <b-icon
                :icon="$tc(`icons.document-edit`)"
                class="media-left"></b-icon>
            <div class="media-content">
              <h3>{{ $store.state.documentation.show_drafts ? 'Hide' : 'Show' }} drafts</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
            aria-role="listitem"
            @click="$store.state.documentation.show_published=!$store.state.documentation.show_published">
          <div class="media">
            <b-icon
                :icon="$tc(`icons.document-edit`)"
                class="media-left"></b-icon>
            <div class="media-content">
              <h3>{{ $store.state.documentation.show_published ? 'Hide' : 'Show' }} published</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item v-if="canCreate"
                         @click="startCreateFolder">
          <div class="media">
            <b-icon :icon="$tc('icons.folder-add')" class="media-left"></b-icon>
            <div class="media-content">
              <h3>Create a folder</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>

      </span>


    </header>
    <PerfectScrollbarWrapper class="has-background-white">

      <DocumentBrowserMenu></DocumentBrowserMenu>
      <hr>
      <DocumentView class="has-background-white px-3"></DocumentView>
    </PerfectScrollbarWrapper>
    <footer class="modal-card-foot">

      <b-field v-if="document!==null && !this.$store.state.documentation.editing_document" class="is-fullwidth"
               grouped position="is-centered">
        <p class="control">
          <b-button :disabled="!hasPrevious" :icon-left="$tc('icons.chevron-left')" type="is-primary"
                    @click="goPrevious">
            Previous
          </b-button>
        </p>

        <p class="control">
          <b-button :disabled="!hasNext" :icon-right="$tc('icons.chevron-right')" type="is-primary" @click="goNext"
          >Next
          </b-button>
        </p>
      </b-field>

    </footer>
  </div>
</template>

<script>
import DocumentBrowserMenu from "@/components/documents/DocumentBrowserMenu";
import DocumentView from "@/components/documents/DocumentView";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import FolderForm from "@/components/documents/FolderForm";
import Permission from "@/models/Permission";
import Document from "@/models/Document";

export default {
  name: 'DocumentationWindow',
  components: {
    PerfectScrollbarWrapper,
    DocumentBrowserMenu, DocumentView
  },
  props: {
    inModal: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    goPrevious() {
      this.$store.state.documentation.editing_document = false
      this.$store.state.documentation.active_document = Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
        return value < this.document.ordinality
      }).orderBy('ordinality', 'desc').get()[0].id
    }, goNext() {
      this.$store.state.documentation.active_document = Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
        this.$store.state.documentation.editing_document = false
        return value > this.document.ordinality
      }).orderBy('ordinality', 'asc').get()[0].id
    },
    startCreateFolder() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
        },
        component: FolderForm,
        hasModalCard: false,
        trapFocus: true
      })

    }
  },
  computed: {
    hasNext() {
      if (this.document !== null) {
        return Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
          return value > this.document.ordinality
        }).exists()
      }
      return false
    }, hasPrevious() {
      if (this.document !== null) {

        return Document.query().where('folder_id', this.document.folder_id).where('ordinality', value => {
          return value < this.document.ordinality
        }).exists()
      }
      return false
    },
    document() {

      if (this.$store.state.documentation.active_document !== null) {
        return Document.query().where('id', this.$store.state.documentation.active_document).with('creator').first()
      }
      return null
    },
    types() {
      let folderPerms = Permission.query().where(permission => permission.name.includes('folders')).get()
      return folderPerms.map(perm => {
        return perm?.name.substr(perm?.name.indexOf(' ') + 1)
      })
    },
    canCreate() {
      return this.types.some(type => {
        return !!this.$store.getters['entities/user-permissions/find']('create ' + type)
      })

    },
  },
  data() {
    return {
      isActive: true,
      loading: false,
    }
  },
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()
    }
  }

}
</script>
