import {Model} from "@vuex-orm/core";


/**
 * A lookup
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {number} lookup_type_id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class Lookup extends Model {
    static entity = 'lookups'

    static fields() {
        return {
            type: this.attr(null),
            id: this.attr(null),
            lookup_type_id: this.attr(null),
            name: this.attr(''),
            order:this.attr(null)
        }
    }

    /**
     * Returns all lookups
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 9999}) {
        return this.api().get(`/lookups`, {
            params: {
                ...{
                    page: page, limit: limit
                }
            },
            dataTransformer: ({data: {data}}) => {
                let lookups = []
                data.map(lookup_type => {
                    return lookup_type.map(lookup => {
                        lookups.push(lookup)
                    })
                })
                return lookups
            },
        })
    }


    /**
     * Store a new lookup
     * @function
     * @param {Object} lookup - The lookup object
     * @param {string} lookup.name - The name of the lookup
     * @param {number} lookup.lookup_type_id - The type id of the lookup
     * @returns {Promise<Response>} - The newly created lookup
     */

    static Store(lookup) {
        return this.api().post(`/lookups`, lookup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing lookup
     * @function
     * @param {Object} lookup - The lookup object
     * @param {string} lookup.name - The name of the lookup
     * @param {string} lookup.id - The id of the lookup
     * @param {string} lookup.lookup_type_id - The type id of the lookup
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created lookup
     */

    static Update(lookup, saved) {
        return this.api().patch(`/lookups/${lookup.id}`, lookup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing lookup
     * @function
     * @param {number} lookup_id - The id of the lookup
     * @returns {Promise<Response>}
     */

    static Delete(lookup_id) {
        return this.api().delete(`/lookups/${lookup_id}`, {
                delete: lookup_id
            }
        )
    }

}
