import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import ILPTopic from "@/models/ILPTopic";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILP extends TransformModel {
    static entity = 'ILPs'

    static fields() {
        return {
            students: this.hasManyBy(User, 'student_ids'),
            student_ids: this.attr(null),
            topics: this.hasManyBy(ILPTopic, 'topic_ids'),
            topic_ids: this.attr(null)
        }
    }

    /**
     * Returns ILP for a subject
     * @function
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.class_group_id]
     * @param {number} [query.subject_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAllBySubject(query = {}, relationships = []) {
        return this.api().get(`/ilp/for-subject`, {
                persistBy: 'create',
                params: {
                    ...{
                        with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data}) => {
                    data.topics = data.topics.filter(topic=>{
                        return topic.units.length>0
                    })
                    data.topics.map(topic => {
                        topic.units = topic.units.filter(unit => {
                            return unit.objectives.length > 0
                        })
                    })
                    return data
                },
            }
        )
    }
    /**
     * Returns ILP for a learner
     * @function
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.subject_id]
     * @param {number} [user_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAllByUser(query = {}, user_id,relationships=[]) {
        return this.api().get(`/ilp/for-learner/${user_id}`, {
                persistBy: 'create',
                params: {
                    ...{
                        with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data}) => {
                    data.topics = data.topics.filter(topic=>{
                        return topic.units.length>0
                    })
                    data.topics.map(topic => {
                        topic.units = topic.units.filter(unit => {
                            return unit.objectives.length > 0
                        })
                    })
                    return data
                },
            }
        )
    }

    /**
     * Returns ILP for a Lesson
     * @function
     * @param {?Object} [query={}] - Query terms for the request
     * @param {number} [query.lesson_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAllByLesson(query = {}, relationships = []) {
        return this.api().get(`/ilp/for-lesson`, {
                persistBy: 'create',
                params: {
                    ...{
                        with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data}) => {
                    data.topics = data.topics.filter(topic=>{
                        return topic.units.length>0
                    })
                    data.topics.map(topic => {
                        topic.units = topic.units.filter(unit => {
                            return unit.objectives.length > 0
                        })
                    })
                    return data
                },
            }
        )
    }


    /**
     * Store progress
     * @function
     * @param {Array<Object>} progress - The progress array
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static SaveProgress(progress) {
        return this.api().post(`/objective-progress`, {progress: progress}, {
                save: false,

            }
        )
    }

}
