import {TransformModel} from "@/models/TransformModel";


/**
 * An application stage
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class ApplicationStage extends TransformModel {
    static entity = 'application-stages'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            order: this.attr(null)

        }
    }

    /**
     * Returns all application stages
     * @function
     * @returns {Promise<Response>}
     */

    static FetchAll() {
        return this.api().get(`/application-stages`, {
                dataTransformer: ({data: {data}}) => {
                    return data.map(stage => {
                        return {...stage, ...stage.attributes}
                    })
                },
            }
        )
    }


}
