import {TransformModel} from "@/models/TransformModel";
import CourseScope from "@/models/CourseScope";
import Objective from "@/models/Objective";
import Campus from "@/models/Campus";
import Topic from "@/models/Topic";
import TeacherReflection from "@/models/TeacherReflection";
import ClassGroup from "@/models/ClassGroup";
import Subject from "@/models/Subject";


/**
 * A Lesson Plan
 * @property {number} id
 * @property {number} course_scope_id - The id of the coursescope associated with this lesson plan
 * @property {number} course_scope_id - The id of the class associated with this lesson plan
 * @property {Object} courseScope - The courseScope this lesson plan belongs to
 * @property {number} campus_id - The id of the campus this lesson plan belongs to
 * @property {Object} campus - the campus this lesson plan belongs to
 * @property {string} starter_activity - The starter activity of the lesson
 * @property {string} engagement - The engagement of the lesson
 * @property {string} checkpoint - The checkpoint of the lesson
 * @property {string} resources - The resources string of the lesson
 * @property {Array.<number>} objective_ids - the ids of the objectives in this lesson plan
 * @property {Array.<Object>} objectives - The the objectives in this lesson plan
 */
export default class LessonPlan extends TransformModel {
    static entity = 'lessonPlans'

    static fields() {
        return {
            id: this.attr(null),
            course_scope_id: this.attr(null),
            class_group_id: this.attr(null),
            class_group: this.belongsTo(ClassGroup,'class_group_id'),
            subject_id: this.attr(null),
            subject: this.belongsTo(Subject,'subject_id'),
            course_scope: this.belongsTo(CourseScope, 'course_scope_id'),
            course_scope_topic_id: this.attr(null),
            topic: this.belongsTo(Topic, 'course_scope_topic_id'),
            campus_id: this.attr(null),
            campus: this.belongsTo(Campus, 'campus_id'),
            objective_ids: this.attr(() => []),
            objectives: this.hasManyBy(Objective, 'objective_ids'),
            starter_activity: this.attr(null),
            learner_reflections_count: this.attr(null),
            teacher_reflection_count: this.attr(null),
            teacher_reflections: this.hasMany(TeacherReflection, 'lesson_plan_id'),
            checkpoint: this.attr(null),
            engagement: this.attr(null),
            resources: this.attr(null),
            needs_learner_reflections: this.attr(0),
            is_global: this.attr(0),
            time_estimate: this.attr(null),
            name: this.attr(null),

        }
    }

    /**
     * Returns all Lesson Plans the user has access to
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} query
     * @param {number} [query.campus_id]
     * @param {number} [query.course_scope_id]
     * @param {string} [query.lesson_date]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {Array.<string>} [count=[]] - Relationships to count
     * @returns {Promise<Response>} All the Lesson Plans
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], count = []) {
        return this.api().get(`/lesson-plans`, {
            params: {
                ...{
                    page: page, limit: limit, with: relationships, with_count: count

                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(object => {
                    if (relationships.includes('course_scope')) {
                        Object.assign(object.attributes.course_scope, object.attributes.course_scope.attributes)
                    }
                    if (relationships.includes('teacher_reflection') && object.attributes.teacher_reflection_count > 0) {
                        let {attributes: {teacher_reflections}} = object
                        teacher_reflections.map(teacher_reflection => {

                            Object.assign(teacher_reflection, teacher_reflection.attributes)
                        })
                    }
                    if (relationships.includes('campus')) {
                        Object.assign(object.attributes.campus, object.attributes.campus.attributes)
                    }
                    if (relationships.includes('objectives')) {
                        object.attributes.objectives.map(objective => {
                            Object.assign(objective, objective.attributes)
                        })
                    }
                    if (relationships.includes('course_scope_topic')) {
                        Object.assign(object.attributes.course_scope_topic, object.attributes.course_scope_topic.attributes)

                        object.attributes['topic'] = object.attributes['course_scope_topic']
                    }
                    return {...object, ...object.attributes}
                })
                },
            }
        )
    }

    /**
     * Returns an Objective
     * @function
     * @param {number} id - The id of the Objective
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/lesson-plans/${id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new objective
     * @function
     * @param {Object} objective - The objective object
     * @returns {Promise<Response>} - The newly created objective
     */

    static Store(lessonPlan, relationships = []) {
        return this.api().post(`/lesson-plans`, lessonPlan, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('objectives')) {
                        data.attributes.objectives.map(objective => {
                            Object.assign(objective, objective.attributes)
                        })
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a objective
     * @function
     * @param {Object} objective - The objective object
     * @returns {Promise<Response>} - The updated objective
     */

    static Update(lessonPlan, relationships = []) {
        return this.api().patch(`/lesson-plans/${lessonPlan.id}`, lessonPlan, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('objectives')) {
                        data.attributes.objectives.map(objective => {
                            Object.assign(objective, objective.attributes)
                        })
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }


    /**
     * Download lesson plan
     * @function
     * @param {number} id - The id of the Lesson Plan
     */
    static Download(id) {
        return this.api().get(`/lesson-plans/${id}/download`, {
            responseType: "arraybuffer",

            save: false
        })
    }

    /**
     * Request Learner Reflections
     * @function
     * @param {number} lesson_plan_id - the id of the lesson_plan
     * @param {Array<number>} learner_ids - The ids of the requested learners
     */
    static Request(lesson_plan_id, learner_ids) {
        return this.api().post(`/lesson-plans/${lesson_plan_id}/request-learner-reflection`, {learner_ids: learner_ids}, {

            save: false
        })
    }

    /**
     * Share a Lesson Plan
     * @function
     * @param {number} lesson_plan_id - the id of the lesson_plan
     * @param {Array<number>} ids - The ids of the users
     */

    static Share(lesson_plan_id, ids) {
        return this.api().post(`/lesson-plans/${lesson_plan_id}/share-with-users`, {user_ids: ids}, {

            save: false
        })
    }

    /**
     * Delete an objective
     * @function
     * @param {number} id - The id of the objective
     */

    static Delete(id) {
        return this.api().delete(`/lesson-plans/${id}`, {
                delete: id
            }
        )
    }


}
