import {Model} from "@vuex-orm/core";


/**
 * A collection of dashboard data
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class Dashboard extends Model {
    static entity = 'dashboard'

    static fields() {
        return {
            enrolments_by_status_count: this.attr(() => ([])),
            enrolments_by_campus_by_status_count: this.attr(() =>
                ([])
            ), applications_by_status_count: this.attr(() => ([])),
            applications_by_campus_by_status_count: this.attr(() =>
                ([])
            ),
            learner_count: this.attr(null),
            staff_count: this.attr(''),

        }
    }

    static mutators() {
        return {
            enrolments_by_status_count(value) {
                if (Array.isArray(value)) {
                    return null
                }
                return value
            }, enrolments_by_campus_by_status_count(value) {
                if (Array.isArray(value)) {
                    return null
                }
                return value
            }, applications_by_status_count(value) {
                if (Array.isArray(value)) {
                    return null
                }
                return value
            }, applications_by_campus_by_status_count(value) {
                if (Array.isArray(value)) {
                    return null
                }
                return value
            }
        }
    }

    /**
     * Returns the dashboard
     * @function
     * @param {Object} query
     * @param {number} query.year
     * @param {number} query.enrolment_type
     * @returns {Promise<Response>}
     */

    static Fetch(query) {
        return this.api().get(`/dashboard`, {
            persistBy: "create",
            params: {
                ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data}) => {
                return data
            },
        })
    }


}
