export const UserModule = {
    namespaced: true,

    state: {
        first_name: '',
        last_name: '',
        username: '',
        avatar: '',
        id: null,
        accepted_terms_at: null,

    },

    actions: {
        /**
         *
         * @param [commit]
         * @param {Object} payload
         */
        updateUser({commit}, payload) {
            let {data: {attributes}} = payload
            if (attributes.first_name) {
                commit('SET_FIRST_NAME', attributes.first_name)
            }
            if (attributes.last_name) {
                commit('SET_LAST_NAME', attributes.last_name)
            }
            if (attributes.avatar) {
                commit('SET_AVATAR', attributes.avatar)
            } else {
                commit('SET_AVATAR', 'https://via.placeholder.com/300x300.png?text=Placeholder')
            }
            if (payload.data.id) {
                commit('SET_ID', payload.data.id)
            }
            if (attributes.username) {
                commit('SET_USERNAME', attributes.username)
            }
            if (attributes.accepted_terms_at) {
                commit('SET_ACCEPTED_TERMS_AT', attributes.accepted_terms_at)
            }
        }
    },

    mutations: {
        SET_FIRST_NAME(state, payload) {
            state.first_name = payload
        }, SET_LAST_NAME(state, payload) {
            state.last_name = payload
        }, SET_AVATAR(state, payload) {
            state.avatar = payload
        }, SET_ID(state, payload) {
            state.id = payload
        }, SET_USERNAME(state, payload) {
            state.username = payload
        }, SET_ACCEPTED_TERMS_AT(state, payload) {
            state.accepted_terms_at = payload
        }

    }
};
