<template>
  <component
      :is="require(`@/components/icons/sis-icons/${svgName}.vue`).default"
      v-if="isSisIcon"
      :class="iconClasses"


      :size="this.size"></component>
  <component
      :is="require(`@/components/icons/sis-icons/placeholder-outline.vue`).default"
      v-else
      :size="this.size"></component>
</template>
<script>
export default {
  props: {
    icon: {
      type: Array,
      default() {
        return ["outline", "placeholder"]
      },
    },
    size: {
      type: String,
      default() {
        return "is-small"
      }
    }
  },
  computed: {
    iconClasses() {
      let classes = [this.svgName]
      if (!this.isFilled) {
        classes.push('is-outline-icon')
      }
      return classes
    },
    iconType: function () {
      return this.icon[0]
    },
    iconName: function () {
      return this.icon[1]

    },
    isFilled: function () {
      return this.iconType === 'filled'
    },
    isSisIcon: function () {
      // console.log("svgName", this.svgName)
      if (this.sisIconNames.includes(this.svgName)) {
        return true
      }
      console.log("couldn't find", this.svgName)
      return false
    },
    formattedName: function () {
      let iconName = `${this.iconName.replace(/-./g, x => x.toUpperCase()[1]).replace(/\b\w/g, c => c.toUpperCase())}`
      return iconName
    },
    svgName: function () {
      let fileName = this.formattedName.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter;
      }).join('')

      fileName += this.isFilled ? '-fill' : '-outline'

      // console.log(`fileName ${fileName}`)
      return fileName
    }
  },
  data() {
    return {
      sisIconNames: [
        "add-circle-outline",
        "add-outline",
        "add-square-outline",
        "alert-circle-outline",
        "announcements-outline",
        "applications-fill",
        "applications-outline",
        "arrow-down-outline",
        "arrow-left-1-outline",
        "arrow-left-2-outline",
        "arrow-right-1-outline",
        "arrow-right-2-outline",
        "arrow-up-outline",
        "backburger-outline",
        "bank-fill",
        "bank-outline",
        "book-fill",
        "book-outline",
        "bookmark-fill",
        "bookmark-outline",
        "box-fill",
        "box-outline",
        "calendar-outline",
        "call-outline",
        "cancel-outline",
        "chevron-down-outline",
        "chevron-left-outline",
        "chevron-right-outline",
        "chevron-up-outline",
        "circle-fill",
        "circle-outline",
        "clipboard-tick-fill",
        "clipboard-tick-outline",
        "clock-fill",
        "clock-outline",
        "close-circle-outline",
        "delete-outline",
        "docs-outline",
        "document-download-outline",
        "document-text-fill",
        "document-text-outline",
        "document-upload-outline",
        "documentation-outline",
        "double-chevron-left-outline",
        "double-chevron-right-outline",
        "element-fill",
        "element-outline",
        "email-outline",
        "export-fill",
        "export-outline",
        "eye-outline",
        "eye-off-outline",
        "filter-fill",
        "filter-outline",
        "folder-open-outline",
        "folder-outline",
        "forwardburger-outline",
        "global-fill",
        "global-outline",
        "hierarchy-fill",
        "hierarchy-outline",
        "home-fill",
        "home-outline",
        "hospital-outline",
        "house-fill",
        "house-outline",
        "houses-outline",
        "import-outline",
        "info-hub-outline",
        "list-fill",
        "list-outline",
        "location-outline",
        "logout-outline",
        "mail-outline",
        "maximize-outline",
        "menu-down-outline",
        "menu-outline",
        "menu-up-outline",
        "minimize-outline",
        "minus-outline",
        "more-circle-outline",
        "more-outline",
        "more-vertical-outline",
        "move-outline",
        "note-fill",
        "note-outline",
        "note-outline",
        "notification-outline",
        "phone-outline",
        "placeholder-fill",
        "placeholder-outline",
        "plus-outline",
        "profile-user-fill",
        "profile-user-outline",
        "selectable-outline",
        "selected-outline",
        "setting-4-fill",
        "setting-4-outline",
        "setting-fill",
        "setting-outline",
        "share-outline",
        "sort-outline",
        "tag-right-outline",
        "task-square-fill",
        "task-square-outline",
        "teacher-fill",
        "teacher-outline",
        "tick-circle-fill",
        "tick-circle-outline",
        "tick-outline",
        "ticket-star-fill",
        "ticket-star-outline",
        "trash-outline",
        "user-add-fill",
        "user-add-outline",
        "user-fill",
        "user-minus-fill",
        "user-minus-outline",
        "user-octagon-fill",
        "user-octagon-outline",
        "user-outline",
        "user-tick-fill",
        "user-tick-outline",
        "download-outline",
        "table-outline",
        "disk-outline",
        "folder-add-outline",
        "folder-cross-outline",
        "folder-edit-outline",
        "document-edit-outline",
        ]
    }
  }
}
</script>
