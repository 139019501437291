import Router from '@/router/index';

export default {
    methods:
        {
            setQuery(query) {
                let obj = Object.assign({}, this.$route.query);

                Object.keys(query).forEach(key => {
                    let value = query[key];
                    if (value) {
                        obj[key] = value
                    } else {
                        delete obj[key]
                    }
                })
                Router.replace({
                    ...Router.currentRoute,
                    query: obj
                }).catch(() => {
                })
            },

            removeQuery(queryNameArray) {
                let obj = {}
                queryNameArray.forEach(key => {
                    obj[key] = null
                })
                this.setQuery(obj)
            },
        }
}
