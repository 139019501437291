export const ServiceModule = {
    namespaced: true,

    state: {

        billing_periods: ['hourly', 'daily', 'weekly', 'monthly', 'termly', 'annually'],
        notice_periods: [30, 60],
        notice_period_types: [{id: 0, name: 'None'}, {id: 1, name: 'Days'}, {id: 2, name: 'Calendar Month'}]
    },

};
