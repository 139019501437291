export const IncidentModule = {
    namespaced: true,

    state: {

        categories: [{
            name: 'Staff',
            items: [
                {name: 'Misconduct', id: 1},
                {name: 'Onsite Injury', id: 3},
                {name: 'Poor Performance', id: 2},
            ]
        }, {
            name: 'Students', items: [
                {name: 'Formal Procedures', id: 5},
                {name: 'Informal Procedures', id: 4},
            ]
        }
        ],
        ungroupedCategories: [
            {
                name: 'Formal Procedures', id: 5
            },
            {
                name: 'Informal Procedures', id: 4
            }, {
                name: 'Misconduct', id: 1
            }
            , {
                name: 'Onsite Injury', id: 3
            }, {
                name: 'Poor Performance', id: 2
            }
        ],
        charges: [
            {
                name: 'Staff', items: [
                    {
                        name: 'Breaching company rules and policies', id: 3
                    }, {
                        name: 'Criminal/Serious Misconduct', id: 10
                    }, {
                        name: 'Discrimination', id: 14
                    }, {
                        name: 'Dishonesty', id: 7
                    }, {
                        name: 'Health and Safety Violation', id: 11
                    }, {
                        name: 'Infliction/threatened infliction of bodily harm', id: 8
                    }, {
                        name: 'Insubordination', id: 4
                    }, {
                        name: 'Negligence', id: 9
                    }, {
                        name: 'Not Meeting KPIs', id: 15
                    }, {
                        name: 'Offences related to driving', id: 5
                    }, {
                        name: 'Offences related to employer property', id: 6
                    }, {
                        name: 'Poor work performance', id: 12
                    }, {
                        name: 'Sexual Harassment', id: 13
                    }, {
                        name: 'Timekeeping', id: 1
                    }, {
                        name: 'Work Attendance', id: 2
                    }]
            },
            {
                name: 'Students', items: [
                    {
                        name: 'Less Serious Infringements', id: 18
                    }, {
                        name: 'Serious Infringement', id: 17
                    }, {
                        name: 'Very Serious Misconduct', id: 16
                    },
                ]
            }
        ],
        ungroupedCharges: [{
            name: 'Breaching company rules and policies', id: 3
        }, {
            name: 'Criminal/Serious Misconduct', id: 10
        }, {
            name: 'Discrimination', id: 14
        }, {
            name: 'Dishonesty', id: 7
        }, {
            name: 'Health and Safety Violation', id: 11
        }, {
            name: 'Infliction/threatened infliction of bodily harm', id: 8
        }, {
            name: 'Insubordination', id: 4
        }, {
            name: 'Less Serious Infringements', id: 18
        }, {
            name: 'Negligence', id: 9
        }, {
            name: 'Not Meeting KPIs', id: 15
        }, {
            name: 'Offences related to driving', id: 5
        }, {
            name: 'Offences related to employer property', id: 6
        }, {
            name: 'Poor work performance', id: 12
        }, {
            name: 'Serious Infringement', id: 17
        }, {
            name: 'Sexual Harassment', id: 13
        },
            {
                name: 'Timekeeping', id: 1
            }, {
                name: 'Very Serious Misconduct', id: 16
            }, {
                name: 'Work Attendance', id: 2
            },

        ],
        actions: [
            {name: 'No Action Taken', id: 1},
            {name: 'Verbal Warning', id: 2},
            {name: 'First Written Warning', id: 3},
            {name: 'Second Written Warning', id: 4},
            {name: 'Final Written Warning', id: 5}, {
                name: 'Suspension Notice', id: 6
            },
            {name: 'Notice Of Disciplinary Enquiry', id: 7}, {name: 'Sanction Notice', id: 8}
        ],
        statuses: [
            {name: 'Open', id: 1},
            {name: 'In Progress', id: 2},
            {name: 'Review', id: 3},
            {name: 'Closed', id: 4}
        ], involvements: [
            {name: 'Attendee', id: 3},
            {name: 'Defendant', id: 2},
            {name: 'Plaintiff', id: 1},
        ],

    }
};
