import {TransformModel} from "@/models/TransformModel";
import Term from "@/models/Term";
import Subject from "@/models/Subject";
import User from "@/models/User";
import ClassGroup from "@/models/ClassGroup";


/**
 * A assessment
 * @property {number} id
 * @property {string} name - The name of the assessment
 * @property {number} term_id - The id of the term
 * @property {number} class_group_id - The id of the class
 * @property {number} subject_id - The id of the assessment if
 * @property {string} assessment_date - The date of the assessment
 * @property {number} type_id - The type of the assessment 1=task 2=project 3=formal 4=exam
 * @property {number} total_marks - the total marks available in the assessment
 * @property {array<Object>} terms - the children terms


 */
export default class Assessment extends TransformModel {
    static entity = 'assessments'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            term_id: this.attr(null),
            class_group_id: this.attr(null),
            class_group: this.belongsTo(ClassGroup,'class_group_id'),
            term: this.belongsTo(Term, 'term_id'),
            assessment_date: this.attr(null),
            total_marks: this.attr(null),
            type_id: this.attr(null),
            assessment_type: this.attr('N/A'),
            subject_id: this.attr(null),
            subject: this.belongsTo(Subject, 'subject_id'),
            campus_id: this.attr(null),
            markgroup_id: this.attr(null),
            is_global:this.attr(null),
            teacher_id:this.attr(null),
            teacher:this.belongsTo(User,'teacher_id'),
            weighting:this.attr(null),
            weighting_valid:this.attr(false)
        }
    }

    /**
     * Returns all Assessments
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {Object} [query={}] - Query terms for the request
     * @param {number} [query.type_id] - search by type_id
     * @param {number} [query.term_id] - search by term_id
     * @param {number} [query.subject_id] - search by subject_id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {?string} [persistBy]
     * @returns {Promise<Response>} All the CourseScopeTopics
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [],persistBy = 'insertOrUpdate') {
        return this.api().get(`/assessments`, {
            persistBy: persistBy,

            params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(assessment => {
                        let response = 'N/A'
                        switch (assessment.attributes.type_id) {
                            case 1:
                                response = 'Task';
                                break;
                            case 2:
                                response = 'Project';
                                break;
                            case 3:
                                response = 'Formal';
                                break;
                            case 4:
                                response = 'Exam';
                                break
                        }
                        assessment.attributes.assessment_type = response
                        if (relationships.includes('teacher')) {
                            if (Object.prototype.hasOwnProperty.call(assessment.attributes, 'teacher'))
                                if (assessment.attributes.teacher !== null) {
                                    Object.assign(assessment.attributes.teacher, assessment.attributes.teacher.attributes)
                                }
                        }
                        return {...assessment, ...assessment.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns an Assessment
     * @function
     * @param {number} assessment_id - The id of the assessment
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(assessment_id, relationships = []) {
        return this.api().get(`/assessments/${assessment_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {

                    if (relationships.includes('teacher')) {
                        if (Object.prototype.hasOwnProperty.call(data.attributes, 'teacher'))
                            if (data.attributes.teacher !== null) {
                                Object.assign(data.attributes.teacher, data.attributes.teacher.attributes)
                            }
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new assessment
     * @function
     * @param {Object} assessment - The assessment object
     * @returns {Promise<Response>} - The newly created assessment
     */

    static Store(assessment) {
        return this.api().post(`/assessments`, assessment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an assessment
     * @function
     * @param {Object} assessment - The assessment object
     * @returns {Promise<Response>} - The updated assessment
     */

    static Update(assessment) {
        return this.api().patch(`/assessments/${assessment.id}`, assessment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete an assessment
     * @function
     * @param {number} id - The id of the assessment
     */

    static Delete(id) {
        return this.api().delete(`/assessments/${id}`, {
                delete: id
            }
        )
    }


}
