export const LoaderModule = {
    namespaced: true,

    state: {
        loading: false,
        overlay: false
    },

    actions: {

        show({
                 commit
             }) {
            commit('SHOW_LOADER');
        },    showOverlay({
                 commit
             }) {
            commit('SHOW_OVERLAY');
        },
        hide({
                 commit
             }) {
            commit('HIDE_LOADER');
        }, hideOverlay({
                           commit
                       }) {
            commit('HIDE_OVERLAY');
        }
    },

    mutations: {

        SHOW_LOADER(state) {
            state.loading = true;
        },
        HIDE_LOADER(state) {
            state.loading = false
        }, SHOW_OVERLAY(state) {
            state.overlay = true;
        },
        HIDE_OVERLAY(state) {
            state.overlay = false
        }
    }
};
