<template>
  <ul :class="{'menu-list':!isSubmenuList,'submenu-list':isSubmenuList}" class="is-relative">
    <aside-menu-item
        :has-fade-in="hasFadeIn"
        :has-fade-out="hasFadeOut"
        v-for="(item,index) in menu"
        :key="index"
        :isSub="isSubmenuList"
        :item="item"
        @menu-click="menuClick"
    />
  </ul>
</template>

<script>
import AsideMenuItem from "@/components/layout/AsideMenuItem";

export default {
  name: "AsideMenuList",
  components: {
    AsideMenuItem
  },
  props: {
    hasFadeIn: {
      type: Boolean,
      default: false
    }, hasFadeOut: {
      type: Boolean,
      default: false
    }, isSubmenuList: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    menuClick(item) {
      this.$emit("menu-click", item);
    }
  }
};
</script>

<style scoped>
</style>
