import {TransformModel} from "@/models/TransformModel";
import Campus from "@/models/Campus";
import User from "@/models/User";
import Media from "@/models/Media";
import AnnouncementModel from "@/models/AnnouncementModel";
import Department from "@/models/Department";

/**
 * An announcement
 * @property {number} id
 * @property {number} campus_id - The id of the campus this announcement belongs to
 * @property {number} audience_id - 1 = staff, 2 = guardian, 3 = all
 * @property {Array<Object>} models -
 * @property {string} title
 * @property {string} audience
 * @property {string} content
 * @property {string} start_date
 * @property {string} end_date
 * @property {boolean} is_pinned - whether to pin the announcement to the top of the list
 * @property {string} payment_responsibility
 * @property {string} age_human_readable
 */
export default class Announcement extends TransformModel {
    static entity = 'announcements'

    static fields() {
        return {
            id: this.attr(null),
            title: this.attr(null),
            content: this.attr(null),
            start_date: this.attr(null),
            end_date: this.attr(null),
            campus: this.belongsTo(Campus, 'campus_id'),
            campus_id: this.attr(null),
            audience_id: this.attr(null),
            is_pinned: this.attr(0),
            audience: this.attr(null),
            models: this.hasMany(AnnouncementModel, 'announcement_id'),
            creator_id: this.attr(null),
            creator: this.belongsTo(User, 'creator_id'),
            created_at: this.attr(null),
            media: this.hasManyBy(Media, 'media_ids'),
            media_ids: this.attr([]),
            departments: this.belongsTo(Department, 'filters.department_ids'),
            filters: this.attr({
                    department_ids: []
                }
            )
        }
    }

    static mutators() {
        return {
            filters(value) {
                if (value == null) {
                    return {
                        department_ids: null
                    }
                }
                return value
            },


        }

    }


    /**
     * Returns all announcements for the user
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {number} user_id - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?string} [query.date]
     * @param {number} [query.user_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAllByUser({page = 1, limit = 15}, query = {}, relationships = [], user_id) {
        return this.api().get(`/users/${user_id}/announcements`, {
            persistBy: 'insertOrUpdate',
            params: {
                ...{
                    page: page, limit: limit, with: relationships
                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(announcement => {
                    if (relationships.includes('media')) {
                        if (relationships.includes('media')) {
                            if (Object.prototype.hasOwnProperty.call(announcement.attributes, 'media')) {
                                announcement.attributes.media.map(item => {
                                    Object.assign(item, item.attributes)
                                    item.mediable_type = 'announcements'
                                    item.mediable_id = announcement.id
                                })
                            }

                        }

                        }
                        if (Object.prototype.hasOwnProperty.call(announcement.attributes, 'models')) {
                            announcement.attributes.models.map(item => {
                                Object.assign(item, item.attributes)

                                if (item.model_type !== 'Global') {
                                    item.model_type = item.model_type.slice(item.model_type.lastIndexOf('\\') + 1)
                                }

                            })
                            if (announcement.attributes.models.length === 0) {
                                announcement.attributes.models = [{
                                    model_id: null,
                                    model_type: 'Global',
                                    announcement_id: announcement.id
                                }]
                            }
                        }

                        if (relationships.includes('campus')) {
                            if (announcement.attributes.campus !== null) {
                                Object.assign(announcement.attributes.campus, announcement.attributes.campus.attributes)
                            }
                        }
                        if (relationships.includes('creator')) {
                            if (announcement.attributes.creator !== null) {
                                Object.assign(announcement.attributes.creator, announcement.attributes.creator.attributes)
                            }
                        }

                        return {...announcement, ...announcement.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all announcements
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?string} [query.date]
     * @param {number} [query.creator]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/announcements`, {
            persistBy: 'insertOrUpdate',
            params: {
                ...{
                    page: page, limit: limit, with: relationships
                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(announcement => {
                    if (relationships.includes('media')) {
                        if (relationships.includes('media')) {
                            if (Object.prototype.hasOwnProperty.call(announcement.attributes, 'media')) {
                                announcement.attributes.media.map(item => {
                                    Object.assign(item, item.attributes)
                                    item.mediable_type = 'announcements'
                                    item.mediable_id = announcement.id
                                })
                            }

                        }

                        }
                        if (Object.prototype.hasOwnProperty.call(announcement.attributes, 'models')) {
                            announcement.attributes.models.map(item => {
                                Object.assign(item, item.attributes)

                                if (item.model_type !== 'Global') {
                                    item.model_type = item.model_type.slice(item.model_type.lastIndexOf('\\') + 1)
                                }

                            })
                            if (announcement.attributes.models.length === 0) {
                                announcement.attributes.models = [{
                                    model_id: null,
                                    model_type: 'Global',
                                    announcement_id: announcement.id
                                }]
                            }
                        }
                        if (relationships.includes('campus')) {
                            if (announcement.attributes.campus !== null) {
                                Object.assign(announcement.attributes.campus, announcement.attributes.campus.attributes)
                            }
                        }
                        if (relationships.includes('creator')) {
                            if (announcement.attributes.creator !== null) {
                                Object.assign(announcement.attributes.creator, announcement.attributes.creator.attributes)
                            }
                        }

                        return {...announcement, ...announcement.attributes}
                    })
                },
            }
        )
    }



    /**
     * Store a new announcement
     * @function
     * @param {Object} announcement - The announcement object
     * @returns {Promise<Response>} - The newly created announcement
     */

    static Store(announcement) {
        return this.api().post(`/announcements`, announcement, {
                dataTransformer: ({data: {data}}) => {
                    if (Object.prototype.hasOwnProperty.call(data.attributes, 'models')) {
                        data.attributes.models.map(item => {
                            Object.assign(item, item.attributes)

                            if (item.model_type !== 'Global') {
                                item.model_type = item.model_type.slice(item.model_type.lastIndexOf('\\') + 1)
                            }

                        })
                        if (data.attributes.models.length === 0) {
                            data.attributes.models = [{
                                model_id: null,
                                model_type: 'Global',
                                announcement_id: data.id
                            }]
                        }
                    }
                    console.log(data)
                    return {...data, ...data.attributes}

                },
            }
        )
    }

    /**
     * Update an existing announcement
     * @function
     * @param {Object} announcement - The announcement object
     * @param {boolean} saved - Whether to persist the response
     * @returns {Promise<Response>} - The newly created application
     */

    static Update(announcement, saved = true) {
        return this.api().patch(`/announcements/${announcement.id}`, announcement, {

            dataTransformer: ({data: {data}}) => {
                    if (Object.prototype.hasOwnProperty.call(data.attributes, 'models')) {
                        data.attributes.models.map(item => {
                            Object.assign(item, item.attributes)
                            if (item.model_type === null) {
                                item.model_type = 'Global'
                            }
                            if (item.model_type === "") {
                                item.model_type = 'Global'
                            }
                            if (item.model_type !== 'Global') {
                                item.model_type = item.model_type.slice(item.model_type.lastIndexOf('\\') + 1)
                            }

                        })
                    }
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing announcement
     * @function
     * @param {number} announcement_id - The id of the announcement
     * @returns {Promise<Response>} - The status of the delete
     */

    static Delete(announcement_id) {
        return this.api().delete(`/announcements/${announcement_id}`, {
                delete: announcement_id
            }
        )
    }


}
