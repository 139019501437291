export const SizeModule = {
    namespaced: true,

    state: {
        height: window.innerHeight,
        width: window.innerWidth
    },
    getters: {
        viewType: state => {
            if (state.width > 0 && state.width < 769) {
                return 'mobile'
            }
            if (state.width > 768 && state.width < 1024) {
                return 'tablet'
            }
            if (state.width > 1023 && state.width < 1216) {
                return 'desktop'
            }
            if (state.width > 1215 && state.width < 1408) {
                return 'widescreen'
            }
            if (state.width > 1407) {
                return 'fullhd'
            }
        },
        isMobile: state => {
            return state.width < 1024
        }
    },
    actions: {
        setHeight({commit}, payload) {
            commit('SET_HEIGHT', payload)
        }, setWidth({commit}, payload) {
            commit('SET_WIDTH', payload)
        },
    }, mutations: {
        SET_HEIGHT(state, payload) {
            state.height = payload
        }, SET_WIDTH(state, payload) {
            state.width = payload
        },
    }


};
