import {TransformModel} from "@/models/TransformModel";
import Folder from "@/models/Folder";
import Media from "@/models/Media";
import User from "@/models/User";

/**
 * A document
 * @property {number} id
 * @property {string} name - The name of the document
 * @property {string} content - The name of the document
 * @property {number} folder_id - The id of the folder this document belongs to
 * @property {number} status_id - The id of the status this document has  Options: 1 = draft, 2 = published. Defaults to 1
 * @property {number} ordinality - The order of this document with its siblings
 */
export default class Document extends TransformModel {
    static entity = 'documents'

    static fields() {
        return {
            type: this.attr('documents'),
            id: this.attr(null),
            name: this.attr(''),
            component: this.attr('DocumentMenuItem'),
            content: this.attr(''),
            folder_id: this.attr(null),
            parent: this.belongsTo(Folder, 'folder_id'),
            ordinality: this.attr(0),
            status_id: this.attr(1),
            media: this.hasManyBy(Media, 'media_ids'),
            media_ids: this.attr([]),
            slug: this.attr(''),
            creator_id: this.attr(null),
            creator: this.belongsTo(User, 'creator_id'),
            created_at: this.attr(null),
            published_at: this.attr(null)

        }
    }
    /**
     * Returns all documents
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {string} mode - how to persist the response
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], mode = 'insertOrUpdate') {
        return this.api().get(`/documents`, {
                persistBy: mode,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(document => {
                        if (relationships.includes('media')) {
                            if (Object.prototype.hasOwnProperty.call(document.attributes, 'media')) {
                                document.attributes.media.map(item => {
                                    Object.assign(item, item.attributes)
                                })
                            }

                        }

                        if (relationships.includes('creator')) {
                            if (Object.prototype.hasOwnProperty.call(document.attributes, 'creator')) {
                                Object.assign(document.attributes.creator, document.attributes.creator.attributes)

                            }

                        }
                        return {...document, ...document.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a document by its id
     * @function
     * @param {number} id The id of the document
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/documents/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('media')) {
                        data.attributes.media.map(item => {
                            Object.assign(item, item.attributes)
                        })
                    }
                    if (relationships.includes('creator')) {
                        if (Object.prototype.hasOwnProperty.call(data.attributes, 'creator')) {
                            Object.assign(data.attributes.creator, data.attributes.creator.attributes)

                        }

                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new document
     * @function
     * @param {Object} document - The document object
     * @param {string} document.name - The name of the document
     * @param {string} document.content - The name of the document
     * @param {number} document.folder_id - The id of the document's parent
     * @param {number} document.status_id=1 - The status_id of the folder
     * @returns {Promise<Response>} - The newly created document
     */

    static Store(document) {
        return this.api().post(`/documents`, document, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing document
     * @function
     * @param {Object} document - The document object
     * @param {string} document.name - The name of the document
     * @param {string} document.content - The name of the document
     * @param {number} document.folder_id - The id of the document's parent
     * @param {number} document.id - The id of the document
     * @param {number} document.status_id=1 - The status_id of the folder
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly updated document
     */

    static Update(document, saved = true) {
        return this.api().patch(`/documents/${document.id}`, document, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing document
     * @function
     * @param {number} document_id - The id of the document
     */

    static Delete(document_id) {
        return this.api().delete(`/documents/${document_id}`, {
                delete: document_id
            }
        )
    }


}
