import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store/index';
Vue.use(Router)


export const AUTH_ROUTES = [
    'login', 'forgotPassword', 'register', 'learner-reflection'
]
export default new Router({
    mode: 'history',
    base: process.env.VUE_APP_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Home',
                docPath: "./Home/Calendar - Events.md"

            },
            component: () => import('@/views/dashboard/Staff'),

        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: {
                title: 'Dashboard',
            docPath: "./Dashboard.md"
            },
            component: () => import('@/views/dashboard/Admin'),

        }, {
            path: '/announcements',
            name: 'operations.announcements.index',
            meta: {
                title: 'Announcements',
                docPath: "./Announcements.md"

            },
            component: () => import('@/views/announcements/Index'),

        },
        {
            path: '/exports/',
            name: 'operations.exports.index',
            // redirect: {
            //     name: 'exports.enrolments'
            // },
            meta: {
                permissions: ['view exports'],
                allow: false,
                docPath: "./Operations/Exports.md",

                title: 'Exports Index'
            },
            component: () =>
                import(
                    '@/views/exports/Index.vue'
                    ),
            children: [

                {
                    name: 'operations.exports.covid-assessments',
                    path: 'covid-assessments',
                    meta: {
                        permissions: ['view covid assessments export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Covid Assessments Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/CovidAssessments.vue'),
                },
                {
                    name: 'operations.exports.homeclass-attendances',
                    path: 'attendances-homeclasses',
                    meta: {
                        permissions: ['view homeclass attendances export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'HomeClass Attendances Export'
                        ,
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/HomeclassAttendances.vue'),
                },{
                    name: 'operations.exports.service-attendances',
                    path: 'attendances-services',
                    meta: {
                        permissions: ['view service attendances export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Services Attendances Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/ServicesAttendances.vue'),
                },
                {
                    name: 'operations.exports.packages',
                    path: 'packages',
                    meta: {
                        permissions: ['view packages export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Packages Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Packages.vue'),
                },     {
                    name: 'operations.exports.reports',
                    path: 'reports',
                    meta: {
                        permissions: ['view reports export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Reports Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Reports.vue'),
                }, {
                    name: 'operations.exports.marksheets',
                    path: 'marksheets',
                    meta: {
                        permissions: ['view marksheets export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Marksheets Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Marksheets.vue'),
                },
                {
                    name: 'operations.exports.enrolments',
                    path: 'enrolments',
                    meta: {
                        permissions: ['view enrolments export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Enrolments Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Enrolments.vue'),
                },{
                    name: 'operations.exports.applications',
                    path: 'applications',
                    meta: {
                        permissions: ['view applications export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Applications Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Applications.vue'),
                },{
                    name: 'operations.exports.sports-houses',
                    path: 'sports-houses',
                    meta: {
                        permissions: ['view sportshouses export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Sports Houses Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/SportsHouses.vue'),
                },
                {
                    name: 'operations.exports.users',
                    path: 'users',
                    meta: {
                        permissions: ['view users export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Users Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Users.vue'),
                },                {
                    name: 'operations.exports.staff',
                    path: 'staff',
                    meta: {
                        permissions: ['view staff export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Staff Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Staff.vue'),
                },
                {
                    name: 'operations.exports.leave',
                    path: 'leave',
                    meta: {
                        permissions: ['view leave requests export'],
                        allow: false,
                        highlight: 'operations.exports.index',
                        title: 'Leave Export',
                        docPath: "./Operations/Exports.md",

                    },
                    component: () => import('@/views/exports/Leave.vue'),
                },


            ]
        },

       {
            path: '/leave',
            name: 'human-resources.leave.index',
            meta: {
                title: 'Leave Manager',
                docPath: "./Human Resources/Leave Requests.md",
                permissions: ['manage leave requests'],
            },

            component: () => import('@/views/leave/Index')
        },
       {
            path: '/incidents',
            name: 'human-resources.incidents.index',
            meta: {
                title: 'Incident Manager',
                docPath: "./Human Resources/Incident Manager.md",
                permissions: ['administrate incidents'],
            },

            component: () => import('@/views/incidents/Index')
        },
        {
            path: '/objectives',
            name: 'academics.objectives.index',
            meta: {
                title: 'Objectives Manager',
                docPath: "./Operations/Objective Manager.md",

                permissions: ['view objectives'],
            },

            component: () => import('@/views/objectives/Index.vue')
        }, {
            path: '/objectives/edit/:id',
            name: 'academics.objectives.edit',
            props: true,
            meta: {
                title: 'Edit Objective',
                docPath: "./Operations/Objective Manager.md",

                permissions: ['view objectives'],
            },

            component: () => import('@/views/objectives/Edit.vue')
        },
        {
            path: '/lesson-plans',
            name: 'academics.lesson-plans.index',
            meta: {
                title: 'Lesson Plans Index',
                docPath: "./Operations/My Subjects.md",
                permissions: ['view lesson-plans'],
            },

            component: () => import('@/views/lessonPlans/Index.vue')
        },    {
            path: '/lessons',
            name: 'academics.lessons.index',
            meta: {
                title: 'Lesson Planner',

                permissions: ['view lesson-plans'],
            },

            component: () => import('@/views/lessons/Index.vue')
        },
        {
            path: '/progress-tracker',
            name: 'academics.progress-tracker.index',
            meta: {
                title: 'Progress Tracker Index',

                permissions: ['view lesson-plans'],
            },

            component: () => import('@/views/progressTracker/Index.vue')
        },
        {
            path: '/reports',
            name: 'academics.reports.index',
            meta: {
                title: 'Reports Index',
                docPath: "./Reports/Reports.md",

                permissions: ['view reports'],
            },

            component: () => import('@/views/reports/Index.vue')
        },
        {
            path: '/courses',
            name: 'academics.courses.index',
            meta: {
                title: 'Course Index',
                docPath: "./Operations/Courses.md",

                permissions: ['view course-scopes'],
            },

            component: () => import('@/views/courses/Index.vue')
        }, {
            path: '/courses/edit/:id',
            props: true,
            name: 'academics.courses.edit',
            meta: {
                title: 'Edit Course',
                docPath: "./Operations/Courses.md",

                permissions: ['edit course-scopes'],
            },

            component: () => import('@/views/courses/Edit.vue')
        }, {
            path: '/my-classes',
            name: 'classes.index',
            meta: {
                title: 'My HomeClasses',
                docPath: "./Operations/My HomeClasses.md",

                permissions: ['view homeclass'],
            },

            component: () => import('@/views/classLists/Index.vue')
        }, {
            path: '/staff',
            name: 'users.staff',
            meta: {
                title: 'Staff Index',
                docPath: "./Users.md",

                permissions: ['view users'],
            },

            component: () => import('@/views/users/staff/Index.vue')
        }, {
            path: '/staff/edit/:id',
            name: 'users.staff.edit',
            props: true,
            meta: {
                title: 'Staff',
                allowSelf:true,
                docPath: "./Users.md",
                highlight: 'users.staff',

                permissions: ['view users'],
            },

            component: () => import('@/views/users/staff/Edit.vue')
        }, {
            path: '/guardians',
            name: 'users.guardians',
            meta: {
                title: 'Guardians',
                docPath: "./Users.md",

                permissions: ['view guardians'],
            },

            component: () => import('@/views/users/guardians/Index')
        }, {
            path: '/guardians/edit/:id',
            name: 'users.guardians.edit',
            props: true,
            meta: {
                title: 'Guardian',
                docPath: "./Users.md",
                highlight: 'users.guardians',

                permissions: ['view guardians'],
            },

            component: () => import('@/views/users/guardians/Edit')
        }, {
            path: '/learners',
            name: 'users.learners',
            meta: {
                title: 'Students',
                docPath: "./Users.md",


                permissions: ['view learners'],
            },

            component: () => import('@/views/users/learners/Index')
        }, {
            path: '/learners/edit/:id',
            name: 'users.learners.edit',
            props: true,
            meta: {
                title: 'Student',
                docPath: "./Users.md",
                highlight: 'users.learners',

                permissions: ['view learners'],
            },

            component: () => import('@/views/users/learners/Edit')
        },
        // {
        //     path: '/campuses',
        //     name: 'campuses.index',
        //     props: true,
        //     meta: {
        //         permissions: ['create users'],
        //     },
        //
        //     component: () => import('@/views/campuses/Index.vue')
        // },
        {
            path: '/campuses',
            name: 'campuses.dashboard',
            props: true,
            meta: {
                title: 'Campus Administration',
                docPath: "./Campus Administration/Campus Information.md",

                permissions: ['edit campuses', 'edit phases', 'edit stages', 'edit subjects', 'edit extramurals'],
            },

            component: () => import('@/views/campuses/Dashboard.vue')
        }, {
            path: '/enrolments',
            name: 'students.enrolments.dashboard',
            meta: {
                title: 'Enrolments',
                permissions: ['view enrolments'],
                docPath: "./Operations/Enrolments.md"
            },

            component: () => import('@/views/enrolments/Dashboard.vue')
        }, {
            path: '/attendances',
            name: 'attendances.index',
            meta: {
                title: 'Attendances',
                docPath: "./Operations/Attendances.md",
                permissions: ['view attendances'],
            },

            component: () => import('@/views/attendances/Index.vue')
        }, {
            path: '/applications',
            name: 'students.applications.index',
            meta: {
                title: 'Applications',
                docPath: "./Operations/Applications.md",

                permissions: ['view applications'],
            },

            component: () => import('@/views/applications/Index.vue')
        }, {
            path: '/applications/edit/:id',
            name: 'students.applications.edit',
            props: true,
            meta: {
                title: 'Application',
                docPath: "./Operations/Applications.md",

                permissions: ['edit applications'],
            },

            component: () => import('@/views/applications/Edit.vue')
        }, {
            path: '/roles',
            name: 'operations.roles.index',
            meta: {
                title: 'Roles',
                docPath: "./Operations/Roles.md",
                permissions: ['view roles'],
            },
            component: () => import('@/views/roles/Index')
        }, {
            path: '/activities',
            name: 'operations.activities.index',
            meta: {
                title: 'Activities',
                docPath: "./Operations/Activities.md",

                permissions: ['view tasks'],
            },
            component: () => import('@/views/activities/Index')
        }, {
            meta: {
                title: 'Apply',
                allow: true,

            },
            path: '/apply',
            name: 'apply',
            component: () => import( /* webpackChunkName: "full-page" */ '@/views/apply/PublicApply')
        },
        // {
        //     path: '/staff/create',
        //     name: 'staff.create',
        //     meta: {
        //         permissions: ['create staff'],
        //     },
        //     component: () => import('@/views/staff/Create.vue')
        // },

        {
            path: '/g/',
            name: 'guardian-home',

            meta: {role: 'guardian'},
            component: () => import( /* webpackChunkName: "full-page" */ '@/views/layouts/PassThrough.vue'),
            children: [

                {
                meta: {
                    title: 'Home',
                    docPath: "./Home.md"

                },
                path: '',
                component: () => import('@/views/dashboard/Staff'),
                beforeEnter: (to, from, next) => {
                    if (
                        Store.state.auth.canAccessGuardianHome === true
                    ) {
                        return next()
                    }
                    Store.dispatch('loader/hide')
                    next('/g/my-profile')
                },
                name: 'guardian-home',
            }, {
                meta: {
                    title: 'My Profile',
                    docPath: "./My Profile.md"

                },
                path: 'my-profile',
                name: 'guardian-profile',
                component: () => import('@/views/g/MyProfile')
            },
            //     {
            //     meta: {
            //         title: 'My Wards',
            //         docPath: "./My Students.md"
            //
            //     },
            //     path: 'my-wards',
            //     name: 'guardian-wards',
            //     component: () => import('@/views/g/wards/Index.vue')
            // },

                {
                meta: {
                    title: 'My Wards',
                    docPath: "./My Students.md"

                },
                props: true,
                path: 'my-wards/',
                name: 'guardian-wards.view',
                component: () => import('@/views/g/wards/Edit.vue')
            },
                {
                meta: {
                    title: 'Progress',
                    docPath: "./My Students.md"
                },
                path: 'progress',
                name: 'guardian-progress',
                component: () => import('@/views/g/progress/Index.vue')
            },
                {
                meta: {
                    title: 'Apply',
                    docPath: "./My Applications.md"
                },
                path: 'applications/create',
                name: 'guardian-application.create',
                component: () => import('@/views/g/applications/Apply')
            }, {
                meta: {
                    title: 'Applications',
                    docPath: "./My Applications.md"
                },
                path: 'applications',
                name: 'guardian-applications.index',
                component: () => import('@/views/g/applications/Index')
            },]
        },
        {
            path: '/full-page',

            component: () => import( /* webpackChunkName: "full-page" */ '@/views/layouts/FullPage.vue'),
            children: [
                {
                    meta: {
                        title: 'Learner Reflection',
                        allow: true,

                    },
                    path: '/learner-reflection',
                    name: 'learner-reflection',
                    component: () => import( /* webpackChunkName: "full-page" */ '@/views/learnerReflections/Index.vue')
                }, {
                    meta: {
                        title: 'Login',
                        allow: true,
                    },
                    path: '/login',
                    name: 'login',
                    component: () => import( /* webpackChunkName: "full-page" */ '@/views/auth/Login.vue')
                },
                {
                    meta: {
                        title: 'Forgot Password',
                        allow: true,

                    },
                    path: '/forgot-password',
                    name: 'forgot-password',
                    component: () => import( /* webpackChunkName: "full-page" */ '@/views/auth/ForgotPassword')
                }, {
                    meta: {
                        title: 'New Password',
                        allow: true,

                    },
                    path: '/password-reset/:token',
                    props: true,
                    name: 'new-password',
                    component: () => import( /* webpackChunkName: "full-page" */ '@/views/auth/NewPassword')
                },
            ]
        },
        // {
        //     path: '*',
        //     name: '404',
        //     meta: {},
        //     component: () => import('@/views/error/404'),
        // }
    ]
})


