import each from 'lodash/each'


export const MenuModule = {
    namespaced: true,

    state: {
        /* Staff */
        // userName: null,
        // userEmail: null,
        // userAvatar: null,
        // userId: null,
        /* NavBar */
        isNavBarVisible: true,

        /* Aside Menu */
        isAsideVisible: true,
        isAsideExpanded: false,
        isAsideMobileExpanded: false,

        /* Aside Right */
        isAsideRightVisible: false,
        asideRightMode: 'updates',

        /* FooterBar */
        isFooterBarVisible: true,
        activeDropdown: null,
        openDropdown: null,
    },

    actions: {
        asideStateToggle({commit}, payload) {
            commit('ASIDE_STATE_TOGGLE', payload)
        }, fullPage({commit}, payload) {
            commit('FULL_PAGE', payload)
        }, asideMobileStateToggle({commit}, payload) {
            commit('ASIDE_MOBILE_STATE_TOGGLE', payload)
        }, asideRightToggle({commit}, payload) {
            commit('ASIDE_RIGHT_TOGGLE', payload)
        },
        activateDropdown({commit}, payload) {
            commit('SET_ACTIVE_DROPDOWN', payload)
        },
        openDropdown({commit}, payload) {
            commit('SET_OPEN_DROPDOWN', payload)
        },
    },


    mutations: {


        SET_ACTIVE_DROPDOWN(state, payload) {
            state.activeDropdown = payload

        },
        SET_OPEN_DROPDOWN(state, payload) {
            state.openDropdown = payload

        },     /* Full Page mode */
        FULL_PAGE(state, payload) {
            state.isNavBarVisible = !payload
            state.isAsideVisible = !payload
            state.isFooterBarVisible = !payload

            each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
                if (payload) {
                    document.documentElement.classList.remove(htmlClass)
                } else {
                    document.documentElement.classList.add(htmlClass)
                }
            })
        },

        /* Aside Desktop */
        ASIDE_STATE_TOGGLE(state, payload = null) {
            const htmlAsideClassName = 'has-aside-expanded'

            let isExpand

            if (payload !== null) {
                isExpand = payload
            } else {
                isExpand = !state.isAsideExpanded
            }

            if (isExpand) {
                document.documentElement.classList.add(htmlAsideClassName)
            } else {
                document.documentElement.classList.remove(htmlAsideClassName)
            }

            state.isAsideExpanded = isExpand
        },

        /* Aside Mobile */
        ASIDE_MOBILE_STATE_TOGGLE(state, payload = null) {
            const htmlClassName = 'has-aside-mobile-expanded'

            let isShow

            if (payload !== null) {
                isShow = payload
            } else {
                isShow = !state.isAsideMobileExpanded
            }

            if (isShow) {
                document.documentElement.classList.add(htmlClassName)
            } else {
                document.documentElement.classList.remove(htmlClassName)
            }

            state.isAsideMobileExpanded = isShow
        },

        /* Aside Right */
        ASIDE_RIGHT_TOGGLE(state, payload = null) {
            // const htmlClassName = 'has-aside-right'

            let isShow

            if (payload !== null) {
                isShow = payload
            } else {
                isShow = !state.isAsideRightVisible
            }

            // if (isShow) {
            //     document.documentElement.classList.add(htmlClassName)
            // } else {
            //     document.documentElement.classList.remove(htmlClassName)
            // }

            state.isAsideRightVisible = isShow
        }


    }
};
