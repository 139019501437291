import {
    TransformModel
} from '@/models/TransformModel'
import User from '@/models/User'

/**
 * A note
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} note
 * @property {number} type_id - the id of the type of note it is
 * @property {Object} note_type - the type of note it is
 * @property {string} note - the description of the note
 * @property {number} user_id - the id of the user who created the note
 * @property {Object} user - the user who created the note
 * @property {string} created_at - When the note was created
 */
export default class Note extends TransformModel {
    static entity = 'notes'

    static fields() {
        return {
            type: this.attr('notes'),
            id: this.attr(null),
            note: this.attr(''),
            user_id: this.attr(''),
            user: this.belongsTo(User, 'user_id'),
            created_at: this.attr(''),
            updated_at: this.attr(''),
            model: this.attr(null),
            model_id: this.attr(null)
        }
    }

    /**
     * Returns all notes
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {string} [query.search] - fuzzy search
     * @param {string} [query.note] - fuzzy search
     * @param {string} [query.model] - Singular type of model
     * @param {number} [query.user] - search by creator id
     * @param {boolean} [query.isComplete] - search by complete status
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @param {string} model.type - the singular model type
     * @param {number} model.id - The model id
     * @param {number} task_id - The task id
     * @returns {Promise<Response>}
     */

    static FetchAllByModel({page = 1, limit = 15}, query = {}, relationships = [], {type = '', id = 0}) {
        return this.api().get(`/${type}/${id}/notes`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(note => {

                        if (relationships.includes('user')) {
                            if (Object.prototype.hasOwnProperty.call(note.attributes, 'user')) {
                                Object.assign(note.attributes.user, note.attributes.user.attributes)
                            }
                        }
                        return {...note, ...note.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all notes
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {string} [query.search] - fuzzy search
     * @param {string} [query.note] - fuzzy search
     * @param {string} [query.model] - Singular type of model
     * @param {number} [query.user] - search by creator id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/notes`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(note => {

                        // if (relationships.includes('user')) {
                        //     Object.assign(note.attributes.user, note.attributes.user.attributes)
                        // }
                        return {...note, ...note.attributes}
                    })
                },
            }
        )
    }


    /**
     * Returns a specific note
     * @function
     * @param {Object} model
     * @param {string} model.type - the singular model type
     * @param {number} model.id - The model id
     * @param {number} note_id - The note id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(note_id, relationships = [], {type = '', id = 0}) {
        return this.api().get(`/${type}/${id}/notes/${note_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(note.attributes.user, note.attributes.user.attributes)
                    // }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new note
     * @function
     * @param {Object} note - The note object
     * @param {Object} model - the associated entity
     * @param {string} model.type - the associated entity type
     * @param {number} model.id - the associated entity id
     * @returns {Promise<Response>} - The newly created user
     */

    static Store(note, {type = '', id = 0}) {
        return this.api().post(`/${type}/${id}/notes`, note, {
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(note.attributes.user, note.attributes.user.attributes)
                    // }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a note
     * @function
     * @param {Object} note - The note object
     * @param {Object} model - the associated entity
     * @param {string} model.type - the associated entity type
     * @param {number} model.id - the associated entity id
     * @returns {Promise<Response>} - The newly created user
     */

    static Update(note, {type = '', id = 0}) {
        return this.api().put(`/${type}/${id}/notes/${note.id}`, note, {
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(note.attributes.user, note.attributes.user.attributes)
                    // }
                    return {...data, ...data.attributes}
                },
            }
        )
    }


    /**
     * Delete a note
     * @function
     * @param {number} note_id - The id of the note
     * @param {Object} model - the associated entity
     * @param {string} model.type - the associated entity type
     * @param {number} model.id - the associated entity id
     * @returns {Promise<Response>}
     */

    static Delete(note_id, {type = '', id = 0}) {
        return this.api().delete(`/${type}/${id}/notes/${note_id}`, {

                delete: note_id

            }
        )
    }


}
