import {TransformModel} from "@/models/TransformModel";

/**
 * A user's profile
 * @property {number} id
 * @property {number} user_id
 * @property {number} gender_id
 * @property {number} ethnicity_id
 * @property {Object} physical_address
 * @property {Object} alternative_address
 * @property {Object} postal_address
 * @property {string} observation
 * @property {string} date_of_birth
 * @property {number} identification_type_id
 * @property {number} id_number
 * @property {number} language_id
 * @property {number} grade
 * @property {string} cemis_number
 * @property {string} dominant_hand
 * @property {string} occupation


 */
export default class Profile extends TransformModel {
    static entity = 'profiles'
    static primaryKey = 'user_id'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            gender_id: this.attr(''),
            ethnicity_id: this.attr(''),
            observation: this.attr(''),
            number_of_dependants:this.attr(0),
            tax_number:this.attr(null),
            physical_address: this.attr(() => ({
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                postal_code: '',
                country: '',
                suburb: ''
            })),
            alternative_address: this.attr(() => ({
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                postal_code: '',
                country: '',
                suburb: ''
            })),
            postal_address: this.attr(() => ({
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                postal_code: '',
                country: '',
                suburb: ''
            })),
            date_of_birth: this.attr(''),
            identification_type_id: this.attr(''),
            id_number: this.attr(''),
            country_of_issue_id: this.attr(null),
            language_id: this.attr(''),
            grade_id: this.attr(null),
            cemis_number: this.attr(null),
            dominant_hand: this.attr(null),
            consent: this.attr(() => [{type: 'picture', is_given: false}]),
            day_length: this.attr(null),
            lunch_option: this.attr(null),
            school_attended: this.attr(() => ({
                name: ''
            })),
            occupation: this.attr(null),
            religion_id: this.attr(null),
            medical_information: this.attr(() => ({
                doctor_name: '',
                medical_aid_provider: '',
                medical_aid_number: '',
                doctor_contact: '',
                additional_information: '',
                allergies: '',
                has_medical_aid: true
            })), employment_information: this.attr(() => ({
                employer: '',
                contact_number: '',
                type_of_work: '',

            })), emergency_contact: this.attr(() => ({
                first_name: '',
                last_name: '',
                contact_number: '',
                relationship: '',

            })), siblings: this.attr(() => ([{
                name: null,
                age: null,
                gender: null,
                current_school: null,

            }])),
        }
    }

    static mutators() {
        return {
            school_attended(value) {
                if (value == null) {
                    return {
                        name: null,

                    }
                }
                return value
            },
            siblings(value) {
                if (value == null) {
                    return [{
                        name: null,
                        age: null,
                        gender: null,
                        current_school: null,
                    }]
                }
                return value
            },
            observation(value) {
                return value ? value : ''
            },
            physical_address(value) {
                if (value == null) {
                    return {
                        line_1: '',
                        line_2: '',
                        city: '',
                        province: '',
                        postal_code: '',
                        country: ''
                    }
                }
                return value
            },
            employment_information(value) {
                if (value == null) {
                    return {
                        employer: '',
                        contact_number: '',
                        type_of_work: '',
                    }
                }
                return value
            },
            alternative_address(value) {
                if (value == null) {
                    return {
                        line_1: '',
                        line_2: '',
                        city: '',
                        province: '',
                        postal_code: '',
                        country: ''
                    }
                }
                return value
            },
            postal_address(value) {
                if (value == null) {
                    return {
                        line_1: '',
                        line_2: '',
                        city: '',
                        province: '',
                        postal_code: '',
                        country: ''
                    }
                }
                return value
            }, medical_information(value) {
                if (value == null) {
                    return {
                        doctor_name: '',
                        medical_aid_provider: '',
                        medical_aid_number: '',
                        doctor_contact: '',
                        additional_information: '',
                        has_medical_aid: true


                    }
                }
                return value
            }, emergency_contact(value) {
                if (value == null) {
                    return {
                        first_name: '',
                        last_name: '',
                        contact_number: '',
                        relationship: '',
                    }
                }
                return value
            },

        }

    }

    /**
     * Returns a profile by the user_id
     * @function
     * @param {number} user_id The id of the user
     * @returns {Promise<Response>}
     */

    static FetchById(user_id) {
        return this.api().get(`/users/${user_id}/profile`, {

                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new profile
     * @function
     * @param {Object} profile - The user object
     * @param {number} user_id
     * @returns {Promise<Response>} - The newly created user
     */

    static Store(profile, user_id) {
        return this.api().post(`/users/${user_id}/profile`, profile, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Update a profile
     * @function
     * @param {Object} profile - The user object
     * @returns {Promise<Response>} - The newly created user
     */

    static Update(profile) {
        return this.api().post(`/users/${profile.user_id}/profile`, profile, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                }
            }
        )
    }

    /**
     * Delete an existing user
     * @function
     * @param {number} user_id - The id of the user
     * @returns {Promise<Response>} - The newly created user
     */

    static Delete(user_id) {
        return this.api().delete(`/users/${user_id}`, {
                delete: user_id
            }
        )
    }

    /**
     * Attach profile picture
     * @function
     * @param {number} user_id - The id of the user
     * @param {File} image
     * @returns {Promise<Response>} - The newly created user
     */

    static Attach(user_id, image) {
        return this.api().post(`/users/${user_id}/attach-avatar`, image
        )
    }


}
