import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";

/**
 * A extramural
 * @property {number} id
 * @property {string} name - The name of the extramural
 * @property {number} campus_id - The id of the campus this extramural belongs to
 * @property {number} stage_id - The id of the stage this extramural belongs to
 * @property {number} ordinality - The order to display this extramural
 */
export default class Extramural extends TransformModel {
    static entity = 'extramurals'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            campus_id: this.attr(null),
            year:this.attr(null),
            stage_id: this.attr(null),
            ordinality: this.attr(0),
            teachers_ids: this.attr(() => []),
            teachers: this.hasManyBy(User, 'teachers_ids'),
            has_attendances: this.attr(0),
            description:this.attr(null)

        }
    }

    /**
     * Returns all extramurals
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.stage_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], mode = 'insertOrUpdate') {
        return this.api().get(`/extramurals`, {
            persistBy: mode,
            params: {
                ...{
                    page: page, limit: limit, with: relationships
                }, ...(query !== {} ? query : {}),
            },
            dataTransformer: ({data: {data}}) => {
                return data.map(extramural => {
                    if (relationships.includes('teachers')) {
                        extramural.attributes.teachers.map(teacher => {
                            Object.assign(teacher, teacher.attributes)
                            })
                        }
                        return {...extramural, ...extramural.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a extramural by its id
     * @function
     * @param {number} id The id of the extramural
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/extramurals/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('teachers')) {
                        data.attributes.teachers.map(teacher => {
                            Object.assign(teacher, teacher.attributes)
                        })
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new extramural
     * @function
     * @param {Object} extramural - The extramural object
     * @param {string} extramural.name - The name of the extramural
     * @param {number} extramural.campus_id - The id of the campus the extramural is associated with
     * @param {number} extramural.stage_id - The id of the stage the extramural is associated with
     * @returns {Promise<Response>} - The newly created extramural
     */

    static Store(extramural) {
        return this.api().post(`/extramurals`, extramural, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing extramural
     * @function
     * @param {Object} extramural - The extramural object
     * @param {string} extramural.name - The name of the extramural
     * @param {number} extramural.campus_id - The id of the campus the extramural is associated with
     * @param {number} extramural.stage_id - The id of the stage the extramural is associated with
     * @param {string} extramural.id - The id of the extramural
     * @param {boolean} saved - Whether or not to persist the response
     * @returns {Promise<Response>} - The newly created extramural
     */

    static Update(extramural, saved) {
        return this.api().patch(`/extramurals/${extramural.id}`, extramural, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing extramural
     * @function
     * @param {number} extramural_id - The id of the extramural
     * @returns {Promise<Response>} - The newly created extramural
     */

    static Delete(extramural_id) {
        return this.api().delete(`/extramurals/${extramural_id}`, {
                delete: extramural_id
            }
        )
    }


}
