import Router from '@/router/index';
import Store from '@/store/index';


// let AUTH_ROUTES = [
//     'login', 'forgotPassword', 'register'
// ]

let showLoaderGuard = Router.beforeEach((to, from, next) => {
    Store.dispatch('loader/show').then()
    next()
});
// eslint-disable-next-line no-unused-vars
let hideLoaderGuard = Router.afterEach(() => {
    Store.dispatch('loader/hide').then()
});

export {showLoaderGuard, hideLoaderGuard}