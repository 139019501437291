import Router from '@/router/index';
import Store from '@/store/index';
import {isAfter} from 'date-fns'


export default Router.beforeEach((to, from, next) => {
    if (!to.meta.allow) {
        if (
            Store.state.user.accepted_terms_at === null
        ) {
            Store.state.auth.needs_to_accept = true
            Store.state.auth.terms_updated = false
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
            return

        } else if (!isAfter(new Date(Store.state.user.accepted_terms_at), new Date(`June 14, 2021, 13:10:19 (UTC+02:00)`))) {
            Store.state.auth.needs_to_accept = true
            Store.state.auth.terms_updated = true
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
            return
        }
        Store.state.auth.needs_to_accept = false
        Store.state.auth.terms_updated = false
        next()
    }
    next()
});
