import {
    Model
} from '@vuex-orm/core'

export default class SubjectUser extends Model {
    static entity = 'subjectUser'

    static primaryKey = ['subject_id', 'user_id']

    static fields() {
        return {
            subject_id: this.attr(null),
            user_id: this.attr(null)
        }
    }
}
