import {TransformModel} from "@/models/TransformModel";


/**
 * A reportSubjectGradeThreshold
 * @property {number} id
 * @property {number} report_id
 * @property {number} subject_id
 * @property {number} lowest
 * @property {number} highest
 * @property {string} symbol
 */
export default class ReportSubjectGradeThreshold extends TransformModel {
    static entity = 'reportSubjectGradeThresholds'

    static fields() {
        return {
            id: this.attr(null),
            report_id:this.attr(null),
            subject_id:this.attr(null),
            lowest:this.attr(null),
            highest:this.attr(null),
            symbol:this.attr(null)

        }
    }

    /**
     * Returns all thresholds
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {number} report_id
     *@param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.subject_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} All the Reports
     */

    static FetchAll({page = 1, limit = 15},relationships = [],report_id,query={}) {
        return this.api().get(`/reports/${report_id}/subject-grade-thresholds`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(threshold => {

                        return {...threshold, ...threshold.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a threshold
     * @function
     * @param {number} report_id - The id of the report
     * @param {number} subject_grade_threshold_id - The id of the threshold
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>} the Topic
     */

    static FetchById(report_id,subject_grade_threshold_id, relationships = []) {
        return this.api().get(`/reports/${report_id}/subject-grade-thresholds/${subject_grade_threshold_id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {


                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new threshold
     * @function
     * @param {Object} threshold - The report threshold
     * @returns {Promise<Response>} - The newly created threshold
     */

    static Store(threshold) {
        return this.api().post(`/reports/${threshold.report_id}/subject-grade-thresholds`, threshold, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update a threshold
     * @function
     * @param {Object} threshold - The threshold object
     * @returns {Promise<Response>} - The updated report
     */

    static Update(threshold) {
        return this.api().patch(`/reports/${threshold.report_id}/subject-grade-thresholds/${threshold.id}`, threshold, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a threshold
     * @function
     * @param {Object} threshold
     */

    static Delete(threshold) {
        return this.api().delete(`/reports/${threshold.report_id}/subject-grade-thresholds/${threshold.id}`, {
                delete: threshold.id
            }
        )
    }


}
