import Router from '@/router/index';
// import {RouterError} from "@/router/errors";
import Store from '@/store/index';


export default Router.beforeEach((to, from, next) => {

    if (
        to.matched.some(m => Object.prototype.hasOwnProperty.call(m.meta, 'role'))
    ) {
        if (
            to.matched.some(m => m.meta.role === Store.state.auth.type)
        ) {
            next()
            return
        }

        switch (Store.state.auth.type) {
            case 'learner':
                next('/l')
                break
            case 'guardian':
                next('/g')
                break
            default:
                next('/')
        }
        return
    }
    if (!to.meta.allow) {
        switch (Store.state.auth.type) {
            case 'learner':
                next('/l/')
                break
            case 'guardian':
                next('/g/')
                break
            default:
                next()
        }
    }
    next()
});
