<template>
  <nav
      v-show="isNavBarVisible"
      id="navbar-main"
      aria-label="main navigation" class="navbar is-fixed-top is-absolute"
      role="navigation"
  >
    <div class="navbar-brand ">
      <!--      <a-->
      <!--          :title="toggleTooltip"-->
      <!--          class="navbar-item is-hidden-touch"-->
      <!--          @click.prevent="menuToggle"-->
      <!--      >-->
      <!--        <b-icon :icon="menuToggleIcon"/>-->
      <!--      </a>-->
      <div
          class="navbar-item is-hidden-tablet px-0 py-0"

      >
        <a aria-expanded="false" aria-label="menu" class="navbar-burger  is-hidden-tablet"
           data-target="sideMenuButton" role="button"
           @click.prevent="menuToggleMobile">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <a
          :class="{'is-active':isMenuNavBarActive}"
          aria-label="menu"
          class="navbar-item mr-2 is-hidden-desktop"
          @click="menuNavBarToggle"
      >
        <b-icon :icon="$tc('icons.more-vertical')"/>
      </a>
    </div>

    <div
        :class="{ 'is-active': isMenuNavBarActive,'above-overlay': overlay}"
        class="navbar-menu fadeIn animated faster"
    >
      <div class="navbar-end">
        <!-- <router-link
            v-if="hasPermission('administrate announcements') || hasPermission('create announcements')"
            :to="'/announcements'"
            :title="'Announcements'"
            exact-active-class="is-active"
            class="navbar-item has-icon"
        >
          <div class="level">
            <div class="level-left no-overflow">
              <div class="level-item no-overflow">
                <b-icon
                    v-if="$tc('icons.announcements')"
                    :icon="$tc('icons.announcements')"
                    custom-size="default"
                />
                <span class="menu-item-label">Announcements</span>
              </div>
            </div>
          </div>

        </router-link> -->

        <nav-bar-menu class="has-user-avatar">

          <div class="is-user-name mr-2">
            <span>Welcome, {{ first_name }}</span>
          </div>
          <div class="is-user-avatar">
            <img :alt="first_name + ' ' + last_name" :src="avatar"/>
          </div>
          <div slot="dropdown" class="navbar-dropdown" :class="{'is-boxed':!$store.getters['size/isMobile']}">
            <a class="navbar-item is-flex is-align-content-center" @click="openProfile">
              <b-icon :icon="$tc('icons.user')" custom-size="default"></b-icon>
              <span>My Profile</span>
            </a>
            <a v-if="$store.state.auth.canSwitch" class="navbar-item is-flex is-align-content-center" @click="toggleView()">
              <b-icon :icon="$store.state.auth.type==='guardian'?$tc('icons.staff'):$tc('icons.guardians')"
                      custom-size="default"></b-icon>
              <span>{{ $store.state.auth.type === 'guardian' ? 'Staff' : 'Guardian' }} View</span></a>
            <!--             <a class="navbar-item">-->
            <!--               <b-icon icon="settings" custom-size="default"></b-icon>-->
            <!--               <span>Settings</span>-->
            <!--             </a>-->
            <!--             <a class="navbar-item">-->
            <!--               <b-icon icon="email" custom-size="default"></b-icon>-->
            <!--               <span>Messages</span>-->
            <!--             </a>-->
            <!--             <hr class="navbar-divider"/>-->
            <!--             <a class="navbar-item">-->
            <!--               <b-icon icon="logout" custom-size="default"></b-icon>-->
            <!--               <span>Log Out</span>-->
            <!--             </a>-->
          </div>
        </nav-bar-menu>
        <nav-bar-menu :hide-open-on-mobile="true">
          <b-icon :icon="$tc('icons.more-vertical')"></b-icon>
          <div slot="dropdown" class="navbar-dropdown end-navbar-dropdown" :class="{'is-boxed':!$store.getters['size/isMobile']}">
            <a  class="navbar-item is-flex is-align-content-center"
               title="Documentation"
               @click="openDocumentation(null)">
              <b-icon :icon="$tc('icons.documentation')" custom-size="default"/>
              <span>Info Hub</span>
            </a>
            <a class="navbar-item is-flex is-align-content-center" title="Log out" @click="logout">
              <b-icon custom-size="default" icon="logout"/>
              <span>Log out</span>
            </a>
            <a
                :class="{'is-active':isAsideRightVisible}"
                class="navbar-item is-flex is-align-content-center"
                title="Documentation"
                @click.prevent="docsToggle"
            >
              <b-icon :icon="docsMenuToggleIcon" custom-size="default"/>
              <span>Docs</span>
            </a></div>
        </nav-bar-menu>
        <!-- <nav-bar-menu>
          <b-icon icon="menu" custom-size="default" />
          <span>Sample Menu</span>
          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item">
              <b-icon icon="application" custom-size="default" />
              <span>Some item</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="book" custom-size="default" />
              <span>Another item</span>
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item">
              <b-icon icon="card-text-outline" custom-size="default" />
              <span>With divider</span>
            </a>
          </div>
        </nav-bar-menu>-->
        <!-- <a
          class="navbar-item"
          :class="{'is-active':isAsideRightVisible}"
          title="Updates"
          @click.prevent="docsToggle"
        >
          <b-icon icon="bell" custom-size="default" />
          <span>Updates</span>
        </a>-->

      </div>
    </div>
    <b-loading ref="pageOverlay" :active="overlay ||(isAsideMobileExpanded&&$store.getters['size/isMobile'])||isAsideRightVisible"
               :can-cancel="false"
               :is-full-page="true" class="page-overlay"
               @click="hitOverlay"><p @click="hitOverlay"></p></b-loading>

  </nav>
</template>

<script>
import NavBarMenu from "@/components/layout/NavBarMenu";
import {mapState} from "vuex";
import Campus from "@/models/Campus";
import {ApplyClient} from "@/clients/Register";
import DocumentationWindow from "@/components/documents/DocumentationWindow";
// import Learner from "@/models/Learner";

export default {
  name: "NavBar",
  components: {
    NavBarMenu,
  },
  data() {
    return {
      isMenuNavBarActive: false,
      learner: Object,
      docs: {}
    };
  },
  computed: {

    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : this.$tc('icons.more-vertical');
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? this.$tc('icons.minimize') : this.$tc('icons.maximize');
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? this.$tc("icons.backburger") : this.$tc("icons.forwardburger");
    }, docsMenuToggleIcon() {
      return this.isAsideRightVisible ? this.$tc("icons.forwardburger") : this.$tc("icons.backburger");
    },
    toggleTooltip() {
      return this.isAsideExpanded ? "Collapse" : "Expand";
    },
    ...mapState("menu", [
      "isNavBarVisible",
      "isAsideExpanded",
      "isAsideMobileExpanded",
      "isAsideRightVisible",
    ]),
    ...mapState("user", ["last_name", "first_name", "avatar", "id"]),
    ...mapState("auth", ["type"]),
    ...mapState("loader", ["overlay"]),
  },
  methods: {
    hitOverlay() {
    },
    openDocumentation() {
      this.setQuery({openDocumentation: 'yes'})

      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
        },
        component: DocumentationWindow,
        hasModalCard: true,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x'],
        onCancel: () => {
          this.removeQuery(['openDocumentation'])
          this.removeQuery(['documentSlug'])
        },
      });
    },
    toggleView() {
      if (this.$store.state.auth.type === 'guardian') {
        this.$store.dispatch('auth/updateType', 'staff')
        this.$router.push('/')
      } else if (this.$store.state.auth.type === 'staff') {
        this.$store.dispatch('auth/updateType', 'guardian')
        this.$router.push('/g/')
      }
    },
    openProfile() {
      switch (this.type) {
        case 'staff':
          this.$router.push(`/staff/edit/${this.id}`);
          break
        case 'guardian':
          this.$router.push('/g/my-profile')
      }
    },
    hasPermission(permission) {
      return this.$store.getters["entities/user-permissions/find"](permission);
    },
    menuToggle() {
      this.$store.dispatch("menu/asideStateToggle");
    },
    menuToggleMobile() {
      this.$store.dispatch("menu/asideMobileStateToggle");
    },
    forceClose() {
      this.isMenuNavBarActive = false
      this.$store.dispatch('loader/hideOverlay')

      window.removeEventListener("click", this.forceClose);
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
      if (this.isMenuNavBarActive) {
        this.$store.dispatch('loader/showOverlay')
        setTimeout(() => {
          window.addEventListener("click", this.forceClose);

        }, 50)

      } else {
        this.$store.dispatch('loader/hideOverlay')

      }
    },
    docsToggle() {
      this.$store.dispatch("menu/asideRightToggle");
      if (this.$route.meta.docPath) {
        if (this.$store.state.documentation.tab_name) {
          if (this.$route.query.tab === this.$store.state.documentation.tab_name) {
            return
          }
        }
        this.$store.state.documentation.active_doc_document_path = this.$route.meta.docPath
      }
    },
    logout() {
      this.$cookies.remove("access_token");
      this.$store.dispatch("auth/updateAuthenticated", false);
      window.location.href = "/login";
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.forceClose);

  },
  mounted() {
    if (!this.$store.state.isAsideExpanded) {
      this.$store.dispatch("menu/asideStateToggle");
    }

    if (this.$store.state.auth.type === 'staff') {
      Campus.FetchAll({page: 1, limit: 100}).then((response) => {
        if (response.response.data.data.length > 0) {
          let campusId;

          if (this.$store.state.campus.selected_campus_id !== null) {
            campusId = parseInt(this.$store.state.campus.selected_campus_id);
          } else {
            campusId = response.response.data.data[0].id;
          }
          this.$store.dispatch("campus/selectCampus", campusId);
          Campus.FetchById(campusId);
        }
      });
    } else {
      ApplyClient.viewCampuses()
          .then((result) => {
            Campus.insertOrUpdate({
              data: result.data.data.map(campus => {
                let {attributes: {phases}} = campus
                phases.map(phase => {
                  let {attributes: {stages}} = phase
                  stages.map(stage => {
                    Object.assign(stage, stage.attributes)
                  })
                  Object.assign(phase, phase.attributes)
                })
                return {...campus, ...campus.attributes}
              })

            })
            let campusId;

            if (this.$store.state.campus.selected_campus_id !== null) {
              campusId = parseInt(this.$store.state.campus.selected_campus_id);
            } else {
              campusId = Campus.query().first().id
            }
            this.$store.dispatch("campus/selectCampus", campusId);
          })

    }
    setTimeout(() => {
      if (this.$route.query.openDocumentation === 'yes') {
          this.openDocumentation()
      }

    }, 500)
    // Learner.fetchById(parseInt(this.$store.state.user.id))
    //     .then(result => {
    //       this.learner = Learner.find(this.$store.state.user.id);
    //     })
    //     .catch(err => {
    //     });


  },
};
</script>
