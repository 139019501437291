import {
    Model
} from '@vuex-orm/core'

export default class CampusUser extends Model {
    static entity = 'campusUser'

    static primaryKey = ['campus_id', 'user_id']

    static fields() {
        return {
            campus_id: this.attr(null),
            user_id: this.attr(null)
        }
    }
}
