<template>
  <div class="content px-3 docs-display" v-html="require(`../../assets/docs/${docsType}/${trimmedPath}`)"></div>

</template>

<script>

export default {
  name: 'DocsDisplay',
  data() {
    return {}
  },
  props: {
    path: {
      type: String,
      required: true
    } , docsType: {
      type: String,
      default(){
        return 'staff'
      }
    }
  },
  methods: {

  },
  computed: {

    trimmedPath(){
      return this.path.slice(2)
    }
  }
}
</script>
