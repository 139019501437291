<template>
  <b-menu class="documentation-menu">
    <div class="document-menu-list has-background-white">
      <b-menu-list>
        <draggable
            v-model="topLevelFolders"
            :group="{name:'documentation',put:canEdit,pull:canEdit}"
            handle=".handle"
            style="min-height: 30px"
            tag="div"

        >
          <transition-group name="list">
            <FolderMenuItem v-for="folder in topLevelFolders" :key="folder.id" :item="folder"></FolderMenuItem>
          </transition-group>
        </draggable>
      </b-menu-list>
    </div>
  </b-menu>
</template>

<script>
import Folder from "@/models/Folder";
import Document from "@/models/Document";
import FolderMenuItem from "@/components/documents/FolderMenuItem";
import FolderForm from "@/components/documents/FolderForm";
import draggable from "vuedraggable";
import Permission from "@/models/Permission";


export default {
  name: 'DocumentBrowserMenu',
  components: {
    FolderMenuItem, draggable
  },
  data() {
    return {
      isActive: true,
      loading: false,
    }
  },
  methods: {
    bubbleExpand(base_folder_id) {
      Folder.FetchById(base_folder_id).then(({entities: {folders}}) => {
        this.$store.dispatch('documentation/expandFolder', base_folder_id)
        if (folders[0].parent_id !== null) {
          this.bubbleExpand(folders[0].parent_id)
        }
      })
    },


    startCreateFolder() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
        },
        component: FolderForm,
        hasModalCard: false,
        trapFocus: true
      })

    }
  },
  mounted() {
    this.$store.dispatch('loader/show')
    this.loading = true
    Permission.FetchAll({page: 1, limit: 999})
    if (this.$route.query.documentSlug) {
      Document.FetchAll({page: 1, limit: 1}, {slug: this.$route.query.documentSlug}, ['creator'])
          .then(({entities: {documents}}) => {
            if (documents) {
              this.$store.state.documentation.active_document = documents[0].id
              this.bubbleExpand(documents[0].folder_id)
            }
          })
    }
    Folder.FetchAll({page: 1, limit: 999}, {isTopLevel: 1}, ['permissions', 'documents', 'children']).then(() => {
      this.loading = false
      this.$store.dispatch('loader/hide')

    }).catch(err => {
      this.handleError(err)
    })


  },
  computed: {
    types() {
      let folderPerms = Permission.query().where(permission => permission.name.includes('folders')).get()
      return folderPerms.map(perm => {
        return perm.name.substr(perm.name.indexOf(' ') + 1)
      })
    },
    canEdit() {
      return this.types.some(type => {
        return !!this.$store.getters['entities/user-permissions/find']('edit ' + type)
      })

    }, canDelete() {
      return this.types.some(type => {
        return !!this.$store.getters['entities/user-permissions/find']('delete ' + type)
      })

    }, canCreate() {
      return this.types.some(type => {
        return !!this.$store.getters['entities/user-permissions/find']('create ' + type)
      })

    },
    topLevelFolders: {
      get() {
        return Folder.query().where('parent_id', null).with('permissions').orderBy('ordinality').get()

      }, set(evt) {
        let promises = []
        evt.map((currentelement, index) => {

          currentelement.ordinality = index;
          if (currentelement.type === "folders") {
            currentelement.parent_id = null
            Folder.update(currentelement)
            promises.push(Folder.Update({
              name: currentelement.name,
              id: currentelement.id,
              parent_id: null,
              ordinality: currentelement.ordinality
            }))
          }


        });
        if (promises.length > 0) {
          this.loading = true
          Promise.all(promises).then(() => {
            this.loading = false
          })
        }
      },
    }
  }
}
</script>
