import {
    TransformModel
} from '@/models/TransformModel'
import Media from "@/models/Media";

export default class UserReport extends TransformModel {
    static entity = 'user-reports'

    static fields() {
        return {
            id: this.attr(null),
            report_id:this.attr(null),
            user_id:this.attr(null),
            media:this.hasManyBy(Media,'media_ids'),
                media_ids:this.attr(null)

        }
    }



    static FetchAll({page = 1, limit = 15}, user_id) {
        return this.api().get(`/users/${user_id}/reports?with[]=media`, {
                params: {
                    ...{
                        page: page, limit: limit,
                    },
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(report => {
                            report.attributes.media.map(item => {
                                Object.assign(item, item.attributes)
                            })

                        return {...report, ...report.attributes}
                    })
                },
            }
        )
    }




}
