export const LeaveRequestModule = {
    namespaced: true,

    state: {
        day_type:[
            {type:'Half Day',value:'half-day'},
            {value:'full-day',type:'Full Day'}
        ],
        types: [
            {
                id: 1,
                name: 'Annual',
                model_name:'annual'
            },
            {
                id: 2,
                name: 'Sick',
                model_name: 'sick'
            },
            {
                id: 3,
                name: 'Unpaid',
                model_name: 'unpaid'
            }, {
                id: 4,
                name: 'Family Responsibility',
                model_name: 'family_responsibility'
            }, {
                id: 5,
                name: 'Maternity',
                model_name: 'maternity'
            }, {
                id: 6,
                name: 'COVID',
                model_name: 'covid'
            }, {
                id: 7,
                name: 'Study',
                model_name: 'study'
            },
            // {
            //     id: 8,
            //     name: 'Chronic',
            //     model_name: 'chronic'
            // },
            {
                id: 9,
                name: 'Discretional',
                model_name: 'discretional'
            }, {
                id: 10,
                name: 'Absenteeism',
                model_name: 'absenteeism'
            },

        ],
        types_without_absenteeism: [
            {
                id: 1,
                name: 'Annual',
                model_name:'annual'
            },
            {
                id: 2,
                name: 'Sick',
                model_name: 'sick'
            },
            {
                id: 3,
                name: 'Unpaid',
                model_name: 'unpaid'
            }, {
                id: 4,
                name: 'Family Responsibility',
                model_name: 'family_responsibility'
            }, {
                id: 5,
                name: 'Maternity',
                model_name: 'maternity'
            }, {
                id: 6,
                name: 'COVID',
                model_name: 'covid'
            }, {
                id: 7,
                name: 'Study',
                model_name: 'study'
            },
            // {
            //     id: 8,
            //     name: 'Chronic',
            //     model_name: 'chronic'
            // },
            {
                id: 9,
                name: 'Discretional',
                model_name: 'discretional'
            },

        ],
        statuses:[
            {
                id:1,
                name:'Pending'
            },
            {
                id:2,
                name:'Approved'
            },
            {
                id:3,
                name:'Declined'
            }
        ]

    },

};
