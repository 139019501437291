import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";

export let editorConfig = {
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }, {
                model: 'heading1',
                view: {
                    name: 'h2',
                    classes: ['document-editor-heading-1']
                }, title: 'Heading 1', class: 'ck-heading_heading1'
            }, {
                model: 'heading2',
                view: {
                    name: 'h3',
                    classes: ['document-editor-heading-2']
                }, title: 'Heading 2', class: 'ck-heading_heading2'
            }, {
                model: 'heading3',
                view: {
                    name: 'h3',
                    classes: ['document-editor-heading-3']
                }, title: 'Heading 3', class: 'ck-heading_heading3'
            },

        ]
    },
    plugins: [
        EssentialsPlugin,
        Underline,
        Alignment,
        BoldPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        BlockQuote,
        List,
        Table,
        FontSize,
        FontColor,
        FontBackgroundColor,
        FontFamily,
        Heading,
        Autoformat

    ],
    toolbar: [
        "bold",
        "italic",
        "underline",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "fontFamily",
        "|",
        "alignment",
        "link",
        "bulletedList",
        "numberedList",
        "insertTable",
        "blockquote",
        "|",
        "undo",
        "redo",
    ],
}
