import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";


export default class LearnerReflection extends TransformModel {
    static entity = 'learnerReflections'

    static fields() {
        return {
            id: this.attr(null),
            lesson_plan_id: this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            how_comfortable: this.attr(null),
            easiest_concept: this.attr(null),
            hardest_concept: this.attr(null),
        }
    }


    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/learner-reflections`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(reflection => {
                        if (relationships.includes('user')) {
                            Object.assign(reflection.attributes.user, reflection.attributes.user.attributes)
                        }
                        return {...reflection, ...reflection.attributes}
                    })
                },
            }
        )
    }


    static FetchById(id, relationships = []) {
        return this.api().get(`/learner-reflections/${id}`, {
            params: {
                ...{
                    with: relationships
                },
            },
            dataTransformer: ({data: {data}}) => {


                return {...data, ...data.attributes}
            },
            }
        )
    }



}
