import Document from "@/models/Document";

export const DocumentationModule = {
    namespaced: true,

    state: {
        active_folder: null,
        active_document: null,
        active_doc_folder: null,
        active_doc_document: null,
        active_doc_document_path: null,
        editing_document: false,
        expanded_folders: [],
        expanded_doc_folders: [],
        show_drafts: true,
        show_published: true,
        docs_menu_expanded:true,
        tab_name:null
    },

    actions: {
        selectDocument({state}, id) {
            state.active_document = id;
            state.editing_document = false
            Document.FetchById(id, ['creator'])
        },
        expandFolder({state}, id) {
            if (!state.expanded_folders.includes(id)) {
                state.expanded_folders.push(id)
            }
        }, collapseFolder({state}, id) {
            state.expanded_folders = state.expanded_folders.filter(folder => folder !== id)
        }, expandDocFolder({state}, id) {
            if (!state.expanded_doc_folders.includes(id)) {
                state.expanded_doc_folders.push(id)
            }
        }, collapseDocFolder({state}, id) {
            state.expanded_doc_folders = state.expanded_doc_folders.filter(folder => folder !== id)
        },
        startEditing({state}, id) {
            state.active_document = id
            state.editing_document = true
        },
    },


};
